
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import _ from 'lodash';
import moment from 'moment';
import Papa from "papaparse";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react';
import { Alert, Badge, Card, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { BsFillCalendarWeekFill } from 'react-icons/bs';
import { FaFileAlt, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { SlCalender } from "react-icons/sl";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import { ReactTags } from 'react-tag-autocomplete';
import Sample_CSV_format from "../../Assets/Sample_CSV_format.csv";
import { API_URL, ITEMS_PER_PAGE, JOB_STATUS_OPEN, PROPOSED_INTERVIEWER_STATUS_APPROVE, SCHEDULER_STATUS_CANCELLED, SCHEDULER_STATUS_DONE, SCHEDULER_STATUS_PENDING, allowedResumeTypes, capitalizeFirstLetter, commonDateFormat, extractDocText, extractPdfText, getAuthUserFromSessionStorage, getSchedulerStatusLabel, isDateGreaterThanToday, resumeFileTypeErrorMessage } from '../../common/constant';
import PaginationComponent from '../../common/PaginationComponent/PaginationComponent';
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from '../../common/roles';
import HeaderScreen from '../HeaderScreen/HeaderScreen';
import "./ScheduledProfileScreen.css";
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';
import NoRecordFound from '../../common/NoRecordFound/NoRecordFound';

const ScheduledProfileScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [jobDetails, setJobDetails] = useState(null);
    const [candidates, setCandidates] = useState([]);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const [candidateData, setCandidateData] = useState(null);

    const timeOptions = [
        { value: "0:00", text: "12:00 AM" },
        { value: "0:15", text: "12:15 AM" },
        { value: "0:30", text: "12:30 AM" },
        { value: "0:45", text: "12:45 AM" },
        { value: "1:00", text: "1:00 AM" },
        { value: "1:15", text: "1:15 AM" },
        { value: "1:30", text: "1:30 AM" },
        { value: "1:45", text: "1:45 AM" },
        { value: "2:00", text: "2:00 AM" },
        { value: "2:15", text: "2:15 AM" },
        { value: "2:30", text: "2:30 AM" },
        { value: "2:45", text: "2:45 AM" },
        { value: "3:00", text: "3:00 AM" },
        { value: "3:15", text: "3:15 AM" },
        { value: "3:30", text: "3:30 AM" },
        { value: "3:45", text: "3:45 AM" },
        { value: "4:00", text: "4:00 AM" },
        { value: "4:15", text: "4:15 AM" },
        { value: "4:30", text: "4:30 AM" },
        { value: "4:45", text: "4:45 AM" },
        { value: "5:00", text: "5:00 AM" },
        { value: "5:15", text: "5:15 AM" },
        { value: "5:30", text: "5:30 AM" },
        { value: "5:45", text: "5:45 AM" },
        { value: "6:00", text: "6:00 AM" },
        { value: "6:15", text: "6:15 AM" },
        { value: "6:30", text: "6:30 AM" },
        { value: "6:45", text: "6:45 AM" },
        { value: "7:00", text: "7:00 AM" },
        { value: "7:15", text: "7:15 AM" },
        { value: "7:30", text: "7:30 AM" },
        { value: "7:45", text: "7:45 AM" },
        { value: "8:00", text: "8:00 AM" },
        { value: "8:15", text: "8:15 AM" },
        { value: "8:30", text: "8:30 AM" },
        { value: "8:45", text: "8:45 AM" },
        { value: "9:00", text: "9:00 AM" },
        { value: "9:15", text: "9:15 AM" },
        { value: "9:30", text: "9:30 AM" },
        { value: "9:45", text: "9:45 AM" },
        { value: "10:00", text: "10:00 AM" },
        { value: "10:15", text: "10:15 AM" },
        { value: "10:30", text: "10:30 AM" },
        { value: "10:45", text: "10:45 AM" },
        { value: "11:00", text: "11:00 AM" },
        { value: "11:15", text: "11:15 AM" },
        { value: "11:30", text: "11:30 AM" },
        { value: "11:45", text: "11:45 AM" },
        { value: "12:00", text: "12:00 PM" },
        { value: "12:15", text: "12:15 PM" },
        { value: "12:30", text: "12:30 PM" },
        { value: "12:45", text: "12:45 PM" },
        { value: "13:00", text: "1:00 PM" },
        { value: "13:15", text: "1:15 PM" },
        { value: "13:30", text: "1:30 PM" },
        { value: "13:45", text: "1:45 PM" },
        { value: "14:00", text: "2:00 PM" },
        { value: "14:15", text: "2:15 PM" },
        { value: "14:30", text: "2:30 PM" },
        { value: "14:45", text: "2:45 PM" },
        { value: "15:00", text: "3:00 PM" },
        { value: "15:15", text: "3:15 PM" },
        { value: "15:30", text: "3:30 PM" },
        { value: "15:45", text: "3:45 PM" },
        { value: "16:00", text: "4:00 PM" },
        { value: "16:15", text: "4:15 PM" },
        { value: "16:30", text: "4:30 PM" },
        { value: "16:45", text: "4:45 PM" },
        { value: "17:00", text: "5:00 PM" },
        { value: "17:15", text: "5:15 PM" },
        { value: "17:30", text: "5:30 PM" },
        { value: "17:45", text: "5:45 PM" },
        { value: "18:00", text: "6:00 PM" },
        { value: "18:15", text: "6:15 PM" },
        { value: "18:30", text: "6:30 PM" },
        { value: "18:45", text: "6:45 PM" },
        { value: "19:00", text: "7:00 PM" },
        { value: "19:15", text: "7:15 PM" },
        { value: "19:30", text: "7:30 PM" },
        { value: "19:45", text: "7:45 PM" },
        { value: "20:00", text: "8:00 PM" },
        { value: "20:15", text: "8:15 PM" },
        { value: "20:30", text: "8:30 PM" },
        { value: "20:45", text: "8:45 PM" },
        { value: "21:00", text: "9:00 PM" },
        { value: "21:15", text: "9:15 PM" },
        { value: "21:30", text: "9:30 PM" },
        { value: "21:45", text: "9:45 PM" },
        { value: "22:00", text: "10:00 PM" },
        { value: "22:15", text: "10:15 PM" },
        { value: "22:30", text: "10:30 PM" },
        { value: "22:45", text: "10:45 PM" },
        { value: "23:00", text: "11:00 PM" },
        { value: "23:15", text: "11:15 PM" },
        { value: "23:30", text: "11:30 PM" },
        { value: "23:45", text: "11:45 PM" }
    ];

    const [timeSlots, setTimeSlots] = useState(timeOptions)

    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const openHistoryModal = () => setShowHistoryModal(true);
    const closeHistoryModal = () => setShowHistoryModal(false);

    const [availableInterviewers, setAvailableInterviewers] = useState([]);

    const browserResumesFileRef = useRef(null);
    const browserCSVFileRef = useRef(null);

    const [uploadedCandidateCSVFileName, setUploadedCandidateCSVFileName] = useState(null);
    const [uploadedCandidatebulkFileName, setUploadedCandidatebulkFileName] = useState(null);

    const clearFile = (fileInput) => {
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset file input
        }
    }

    const navigate = useNavigate();
    const { id: jobId } = useParams();
    const fileInputRef = useRef(null);
    const toast = useRef(null);

    const currentDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD');

    const [formData, setFormData] = useState({
        date: '',
        time: '',
        interviewer_user_id: '',
        sample_feedback_sheet: '',
        selected_primary_skill: [],
        selected_secondary_skill: [],
        clientHasMeetingLink: false,
        isAdhocInterview: false,
        meetingLink: '',
        instruction: ""
    });
    const [selectedInterviewerApproveStatus, setSelectedInterviewerApproveStatus] = useState(null);

    const handleFileChange = (event) => {
        if (allowedResumeTypes.includes(event.target.files[0].type)) {
            const selectedFile = event.target.files[0];
            uploadResumeFile(selectedFile);
        } else {
            clearFile(fileInputRef)
            toast.current.show({ severity: 'error', summary: 'Error', detail: resumeFileTypeErrorMessage, life: 3000 });
        }
    };

    const uploadResumeFile = (selectedFile) => {
        const data = new FormData();
        data.append('file', selectedFile);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                updateCandidateProfile({
                    resume: response.filepath
                }, "Resume added successfully");
            })
            .catch((error) => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                await getCandidates();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        getSkills();
    }, []);

    const getCandidates = async () => {
        setIsLoading(true)
        let param = {
            search: searchText,
            is_feedback_given: false 
        }
        if (fromDate && toDate) {
            param = {
                ...param,
                startDate: fromDate,
                endDate: toDate
            }
        }
        if (jobId != "" && jobId != undefined && jobId != null) {
            param = {
                ...param,
                jobIds: [jobId]
            }
        }
        await fetch(`${API_URL}api/getCandidatesByJob?page=${pageNumber}&limit=${pageSize}&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(param)
        }
        )
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.jobs) && !_.isNull(response.jobs) && response.jobs.length > 0) {
                        setJobDetails(response.jobs[0])
                    }
                    setCandidates(response.data)
                    setTotalCandidates(response.totalCount)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setJobDetails(null);
                setCandidates([])
                setTotalCandidates(0)
                setIsLoading(false)
            });
        
    }

    const handleButtonClick = (rowData) => {
        setCandidateData(rowData);
        setSelectedItem(rowData);
        setFormData({
            date: '',
            time: '',
            interviewer_user_id: '',
            sample_feedback_sheet: '',
            selected_primary_skill: [],
            selected_secondary_skill: [],
            clientHasMeetingLink: false,
            isAdhocInterview: false,
            meetingLink: '',
            instruction: ''
        });
        setSelectedInterviewerApproveStatus(null)
        openHistoryModal();
    };

    const getStartDate = () => {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        return startDate;
    }

    const endDate = () => {
        let endDate = new Date();
        endDate.setMinutes(endDate.getMinutes() + 5);
        return endDate;
    }

    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(getStartDate());
    const [toDate, setToDate] = useState(endDate());

    useEffect(() => {
        getCandidates();
    }, [searchText, sortConfig]);
 
    const resetFilter = () => {
        setFromDate(getStartDate());
        setToDate(endDate());
        console.log("filter..", new Date())
        getCandidates()
    }

    const scheduleHandleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'date') {
            const now = new Date();
            const currentDate = now.toISOString().split("T")[0];  // Date in YYYY-MM-DD format
            const currentTime = now.getHours() * 60 + now.getMinutes();
            const isToday = currentDate === value;
            if (isToday) {
                const filteredSlots = isToday 
                ? timeOptions.filter(option => {
                    const [hour, minute] = option.value.split(":").map(Number);
                    const optionTime = hour * 60 + minute;
                    return optionTime > currentTime;
                  })
                : timeOptions;
                setTimeSlots(filteredSlots)
            } else {
                setTimeSlots(timeOptions)
            }
            setFormData({
                ...formData,
                time: "",
                [name]: value,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
        if (name === "interviewer_user_id") {
            if (e.target.value) {
                const selectedOptionElement = e.target.options[e.target.selectedIndex];
                const approvestatus = selectedOptionElement.getAttribute('approvestatus');
                setSelectedInterviewerApproveStatus(approvestatus)
            } else {
                setSelectedInterviewerApproveStatus(null)
            }
        }
    }

    useEffect(() => {
        if (formData.date && formData.time) {
            formData.interviewer_user_id = "";
            setAvailableInterviewers([])
            setSelectedInterviewerApproveStatus(null)
            getAvailableProposedInterviewForTheGivenSlot();
        }
    }, [formData.date, formData.time, formData.selected_primary_skill, formData.selected_secondary_skill, formData.isAdhocInterview])

    const getAvailableProposedInterviewForTheGivenSlot = () => {
        fetch(`${API_URL}api/getAvailableProposedInterviewForTheGivenSlot/${jobId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                date: formData.date,
                time: formData.time,
                selected_primary_skill: formData.selected_primary_skill,
                selected_secondary_skill: formData.selected_secondary_skill,
                isAdhocInterview: formData.isAdhocInterview
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setAvailableInterviewers(response.data)
            })
            .catch((error) => {
                setAvailableInterviewers([])
            });
    }

    const handleSubmit = () => {
        const scheduledOn = new Date(`${formData.date} ${formData.time}` + " GMT+0530");
        if (formData.date && formData.time && formData.interviewer_user_id && isDateGreaterThanToday(scheduledOn) && (!formData.clientHasMeetingLink || formData.meetingLink)) {
            formData.jobId = candidateData.job_id;
            formData.candidateId = candidateData.candidate_id;
            updateCandidateProfile({
                scheduled_on: scheduledOn,
                scheduler_status: SCHEDULER_STATUS_DONE,
                interviewer_id: formData.interviewer_user_id,
                scheduler_id: userDetails.id,
                sample_feedback_sheet: formData.sample_feedback_sheet,
                clientHasMeetingLink: formData.clientHasMeetingLink,
                isAdhocInterview: formData.isAdhocInterview,
                meetingLink: formData.meetingLink,
                instruction: formData.instruction

            }, "Interview scheduled successfully")
            setShowHistoryModal(false);
        }
    };

    const uploadFile = (event, name) => {
        setIsLoading(true)
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                setFormData({
                    ...formData,
                    sample_feedback_sheet: response.filepath
                })
                setIsLoading(false)
            })
            .catch((err) => {
                setFormData({
                    ...formData,
                    sample_feedback_sheet: ""
                })
                setIsLoading(false)
            });
    }

    const items = [
        {
            label: 'Remove Profile',
            command: () => {
                deleteCandidateProfile();
                // toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated' });
            }
        },
        {
            label: 'Cancel Schedule',
            command: () => {
                updateCandidateProfile(
                    {
                        scheduler_status: SCHEDULER_STATUS_CANCELLED,
                        scheduled_on: "",
                        scheduler_id: ""
                    },
                    "Interview cancelled successully"
                );
                // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
            }
        }
    ];

    const [selectedItem, setSelectedItem] = useState(null);

    const deleteCandidateProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/deleteCandidateProfile/${selectedItem._id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                setShowRemoveProfileConfirmDialog(false);
                getCandidates();
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setShowRemoveProfileConfirmDialog(false);
            });
    }

    const updateCandidateProfile = (body, message = "") => {
        setIsLoading(true)
        fetch(`${API_URL}api/updateCandidateProfileData/${selectedItem._id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                setFormData({
                    date: '',
                    time: '',
                    interviewer_user_id: '',
                    sample_feedback_sheet: '',
                    selected_primary_skill: [],
                    selected_secondary_skill: [],
                    clientHasMeetingLink: false,
                    isAdhocInterview: false,
                    meetingLink: '',
                    instruction: '',
                })
                setAvailableInterviewers([])
                setSelectedInterviewerApproveStatus(null)
                getCandidates();
                setIsLoading(false)
                toast.current.show({ severity: 'success', summary: 'Success', detail: message ?? response.message, life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const getRalaAdminActions = (rowData) => {
        return (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) ? (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Remove Profile</Tooltip>}
            >
                <div style={{
                    background: 'white',
                    borderRadius: '50%',
                    width: '48px',
                    textAlign: 'center',
                    paddingTop: '12px',
                    height: '48px'
                }}>
                    <i style={{
                        cursor: 'pointer',
                        fontSize: '1.5rem'
                    }} onClick={() => { setSelectedItem(rowData); setShowRemoveProfileConfirmDialog(true); }} className="pi pi-times" ></i>
                </div>
            </OverlayTrigger>
        ) : (
            <SplitButton
                className='split_theme'
                text
                model={items}// Handle the default item click
                label="Action" // Set the default label
                onShow={() => setSelectedItem(rowData)}
            />
        );
    }

    const getActions = (rowData) => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Remove Profile</Tooltip>}
            >
                <div style={{
                    background: 'white',
                    borderRadius: '50%',
                    width: '48px',
                    textAlign: 'center',
                    paddingTop: '12px',
                    height: '48px'
                }}>
                    <i style={{
                        cursor: 'pointer',
                        fontSize: '1.5rem'
                    }} onClick={() => { setSelectedItem(rowData); setShowRemoveProfileConfirmDialog(true); }} className="pi pi-times" ></i>
                </div>
            </OverlayTrigger>
        );
    }

    const [errors, setErrors] = useState([]);
    const [showUploadProfiles, setShowUploadProfiles] = useState(false);
    const [candidateProfileData, setCandidateProfileData] = useState([]);
    const [showRemoveProfileConfirmDialog, setShowRemoveProfileConfirmDialog] = useState(false);
    const resumeUploadRef = useRef(null);

    const uploadProfileModalfooterContent = (
        <div style={{ marginTop: '30px', marginBottom: '40px' }}>
            <Button className="btn btn-primary" onClick={() => uploadProfileApiCall()} >Save</Button> {" "}{" "}
            <Button className="btn btn-outline-primary" onClick={() => setShowUploadProfiles(false)}>Cancel</Button>
        </div>
    );

    const uploadCSV = (event) => {
        if (event.target.files.length > 0 && (event.target.files[0].type === "text/csv" || event.target.files[0].type === "application/vnd.ms-excel")) {
            setUploadedCandidateCSVFileName(event.target.files[0].name)
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.trim(),
                complete: function (results) {
                    if (results.data.length > 25) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Maximum 25 profile can be uploaded at once', life: 3000 });
                    }
                    else if (results.data.length === 0) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'CSV file is empty', life: 3000 });
                    }
                    else {
                        let newInterviewerCandidateDetails = [];
                        _.map(results.data, (candidate, candidateIndex) => {
                            if (!_.isUndefined(candidate['Candidate name']) && !_.isNull(candidate['Candidate name']) && (candidate['Candidate name'] !== '')) {
                                if (candidate["available time"] !== "") {
                                    const dateString = candidate["available time"];
                                    const parts = dateString.split(/[- :]/);
                                    const dateObject = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
                                    if (isNaN(dateObject.getTime())) {
                                        candidate["available time"] = new Date();
                                    } else {
                                        candidate["available time"] = dateObject;
                                    }
                                } else {
                                    candidate["available time"] = new Date();
                                }
                                newInterviewerCandidateDetails.push({
                                    uniqueId: new Date().valueOf() + candidateIndex,
                                    job_id: jobDetails._id,
                                    candidate_name: candidate['Candidate name'],
                                    candidate_mobile_number: candidate["Phone number"],
                                    candidate_email: candidate["E-mail"],
                                    available_on: new Date(candidate["available time"]),
                                    resume: ""
                                });
                            }
                        })
                        if (newInterviewerCandidateDetails.length > 0)
                            setCandidateProfileData(newInterviewerCandidateDetails);
                    }
                    clearFile(browserCSVFileRef);
                },
            });
        } else {
            clearFile(browserCSVFileRef);
            setUploadedCandidateCSVFileName(null);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload only csv file', life: 3000 });
        }
    }

    const uploadFiles = async (event) => {
        const files = event.target.files;
        if (files.length > 15) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Maximum 25 profile can be uploaded at once', life: 3000 });
            clearFile(browserResumesFileRef);
            return;
        }
        if (files.length === 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select at least one profile', life: 3000 });
            clearFile(browserResumesFileRef);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!(allowedResumeTypes.includes(file.type))) {
                setUploadedCandidateCSVFileName(null);
                toast.current.show({ severity: 'error', summary: 'Error', detail: resumeFileTypeErrorMessage, life: 3000 });
                return false;
            }
        }

        setCandidateProfileData([]);
        let newInterviewerCandidateDetails = [];
        setIsLoading(true)

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            try {
                const [name, email, phone] =
                  "application/pdf" === file.type
                    ? await extractPdfText(file)
                    : await extractDocText(file);

                var filePath = await uploadCandidateFile(file);
                
                newInterviewerCandidateDetails.push({
                    uniqueId: new Date().valueOf() + i,
                    job_id: jobDetails._id,
                    candidate_name: name,
                    candidate_mobile_number: phone,
                    candidate_email: email,
                    available_on: new Date(),
                    resume: filePath
                });
                setUploadedCandidatebulkFileName(file.name);
            } catch (error) {
                console.error("Error processing file", error);
            } finally {
                setCandidateProfileData(newInterviewerCandidateDetails);
                setIsLoading(false);
                clearFile(browserResumesFileRef);
            }
        }
    };

    const uploadCandidateFile = async (file) => {
        try {
            const data = new FormData();
            data.append('file', file);
            const response = await fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            });
            const responseData = await response.json();
            return responseData.filepath;
        } catch (error) {
            return "";
        }
    };

    const profileChangeHandler = (index, event) => {
        let newCandidateProfileData = [...candidateProfileData];
        newCandidateProfileData[index][event.target.name] = event.target.value;
        setCandidateProfileData(newCandidateProfileData);
    };

    const changeDateHandler = (index, date) => {
        let newCandidateProfileData = [...candidateProfileData];
        newCandidateProfileData[index].available_on = date;
        setCandidateProfileData(newCandidateProfileData);
    };

    const removeCandidateProfile = (index) => {
        let newCandidateProfileData = [...candidateProfileData];
        newCandidateProfileData.splice(index, 1);
        setCandidateProfileData(newCandidateProfileData);
    }

    const uploadResumeHandler = async (index, event) => {
        if (event && event.files && event.files.length > 0) {
            setIsLoading(true)
            const file = event.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
                .then((response) => response.json())
                .then((response) => {
                    let newCandidateProfileData = [...candidateProfileData];
                    newCandidateProfileData[index].resume = response.filepath;
                    setCandidateProfileData(newCandidateProfileData);
                    if (resumeUploadRef.current) {
                        resumeUploadRef.current.clear();
                    }
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: 'Success', detail: "Resume added successfully", life: 3000 });
                })
                .catch((err) => {
                    setIsLoading(false)
                    console.log("error", err);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                });
        } else {
            if (resumeUploadRef.current) {
                resumeUploadRef.current.clear();
            }
            toast.current.show({ severity: 'error', summary: 'Error', detail: resumeFileTypeErrorMessage, life: 3000 });
        }
    };

    const getProfileErrorMessage = (uniqueId, key) => {
        const error = errors.find(err => err.key === key && err.uniqueId === uniqueId);
        return error ? error.errorMessage : null;
    }

    const validateProfileData = () => {
        const newErrors = [];
        const requiredFields = ['job_id', 'candidate_name', 'candidate_mobile_number', 'candidate_email', 'resume', 'available_on'];
        for (const obj of candidateProfileData) {
            for (const field of requiredFields) {
                const fieldLabel = capitalizeFirstLetter(field.replaceAll("_", " "));
                if (!obj.hasOwnProperty(field) || obj[field] === null || obj[field] === undefined || obj[field] === '') {
                    newErrors.push({
                        uniqueId: obj['uniqueId'],
                        key: field,
                        errorMessage: `${fieldLabel} is required!`
                    })
                } else {
                    if (field === "candidate_mobile_number" && /^\d{10}$/.test(obj[field]) === false) {
                        newErrors.push({
                            uniqueId: obj['uniqueId'],
                            key: field,
                            errorMessage: `Enter valid mobile number!`
                        })
                    }
                    if (field === "candidate_email") {
                        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(obj[field]);
                        if (!isValid) {
                            newErrors.push({
                                uniqueId: obj['uniqueId'],
                                key: field,
                                errorMessage: `Enter valid email address!`
                            })
                        }
                    }
                }
            }
        }

        setErrors(newErrors);

        if (newErrors.length > 0) {
            return false;
        }

        const uniqueEmails = new Set();
        const uniqueMobileNumbers = new Set();
        let repeatedEntries = [];

        candidateProfileData.forEach(entry => {
            if (uniqueEmails.has(entry.candidate_email) || uniqueMobileNumbers.has(entry.candidate_mobile_number)) {
                repeatedEntries.push({
                    ...entry,
                    key: uniqueEmails.has(entry.candidate_email) ? 'candidate_email' : 'candidate_mobile_number',
                    label: uniqueEmails.has(entry.candidate_email) ? 'Candidate email' : 'Candidate mobile number'
                });
            } else {
                uniqueEmails.add(entry.candidate_email);
                uniqueMobileNumbers.add(entry.candidate_mobile_number);
            }
        });

        if (repeatedEntries.length > 0) {
            for (var j = 0; j < repeatedEntries.length; repeatedEntries++) {
                newErrors.push(
                    {
                        uniqueId: repeatedEntries[j]['uniqueId'],
                        key: repeatedEntries[j]['key'],
                        errorMessage: `${repeatedEntries[j]['label']} is duplicate!`
                    }
                )
            }
        }

        setErrors(newErrors);

        if (newErrors.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    const uploadProfileApiCall = () => {
        if (candidateProfileData.length > 0) {
            if (validateProfileData()) {
                setIsLoading(true)
                fetch(`${API_URL}api/addCandidateProfileData`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userDetails.token}`,
                    },
                    body: JSON.stringify({
                        candidateProfileData: candidateProfileData
                    })
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.success) {
                            toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                            setShowUploadProfiles(false);
                            setCandidateProfileData([]);
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                        }
                    })
                    .catch((err) => {
                        console.log("error", err);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    })
                    .finally(() => {
                        setIsLoading(false)
                        resetFilter();
                    });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload records first', life: 3000 });
        }
    }

    const removeProfileFooter = (
        <div style={{ marginTop: '30px', marginBottom: '40px' }}>
            <Button className="btn btn-primary" onClick={() => deleteCandidateProfile()} >Yes</Button> {" "}{" "}
            <Button className="btn btn-outline-primary" onClick={() => setShowRemoveProfileConfirmDialog(false)}>Cancel</Button>
        </div>
    );

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, sePageSize] = useState(ITEMS_PER_PAGE);

    const [isShowResumeDialog, setIsShowResumeDialog] = useState(false)

    const updatePagination = (pageNumber, pageSize) => {
        setPageNumber(pageNumber)
        sePageSize(pageSize)
    }

    const handleClear = (name) => {
        if (name == 'toDate') {
            setToDate(null);
        } else {
            setFromDate(null);
        }
    };

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control"
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    const [skillList, setSkillList] = useState(null);

    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                var skills = [];
                response.data.map((item) => {
                    skills.push(
                        {
                            label: item.name,
                            value: item.name,
                            name: item.name,
                        }
                    );
                })
                setSkillList(skills)
            })
            .catch((error) => {
                setSkillList([])
            });
    }

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer" />;
        }
        return <FaSortDown className="cursor-pointer" />;
    };

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".pdf, .doc, .docx"
                onChange={(event) => {
                    handleFileChange(event);
                }}
            />
            <div className="container-fluid employer-dashboard-container fixed-padding mobile-scrl-head">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 employer-dashboard-title pageTitle_fixed mobile-scrl">
                        <div className="row">
                            <div className="col-12">
                                <p className="employer-dashboard-jobs-posted mb-0">{jobDetails ? jobDetails.job_title : 'JOB TITLE'} / {jobDetails && jobDetails._id ? jobDetails._id.substring(0, 5) + "..." : 'JOB ID'} / {(jobDetails && jobDetails.primary_skill) ? jobDetails.primary_skill.join(", ") : "SKILLS"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-2 mt-3">
                    <div className="col-12 col-md-4 col-lg-2 my-2 my-md-0">
                        <DatePicker className="form-select w-100" maxDate={toDate ? new Date(toDate) : null} onChange={(date) => {
                            setFromDate(date);
                            handleClear('toDate');
                        }} selected={fromDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() =>   handleClear('fromDate')} />}/>
                        <small className="last-one-month text-start text-danger" style={{float:'left', margin:'8px 0'}}>Available for last one month</small>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 my-2 my-md-0">
                        <DatePicker className="form-select w-100" minDate={fromDate ? new Date(fromDate) : null} maxDate={fromDate ? new Date(moment(fromDate).add(30, 'days')) : null} onChange={(date) => setToDate(date)} selected={toDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('toDate')} />} />
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 my-2 my-md-0">
                        <Button className="btn btn-primary w-100" onClick={() => getCandidates()} style={{ width: '80px' }} >Search</Button>
                    </div>
                    <div className="col-12 col-md-4 col-lg-2 text-start text-md-start text-lg-end my-2 my-md-0">
                        {
                            jobDetails && jobDetails.status === JOB_STATUS_OPEN && (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN || userDetails.account_type === SUPER_ADMIN) && (
                                <Button className="btn btn-outline-primary gap-3 w-100" style={{ marginTop: '0px', textWrap: 'nowrap' }}
                                    onClick={() => { setUploadedCandidatebulkFileName(null); setUploadedCandidateCSVFileName(null); setShowUploadProfiles(true) }}
                                >
                                    <BsFillCalendarWeekFill size={16} className='me-3' /><span>Upload profiles</span>
                                </Button>
                            )
                        }
                    </div>
                    <div className="col-12 col-md-8 col-lg-4 my-2 my-md-0">
                        <Form.Control type="text" id="jobTitle" className='w-100' onChange={(e) => {
                            setSearchText(e.target.value);
                        }} name="jobTitle" placeholder="Search" maxLength={150} autoComplete="off" />
                    </div>
                </div>
                <div className="row history-feednback-row">
                    <div className="col-12">
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12 employer-dashboard-title">
                                        <Table bordered striped size="lg" responsive className="history-feednback-completed">
                                            <thead className='sticky-top'>
                                                <tr>
                                                    <th onClick={() => onSort('_id')}>Candidate ID {getSortIcon('_id')}</th>
                                                    <th onClick={() => onSort('candidate_name')}>Candidate Name {getSortIcon('candidate_name')}</th>
                                                    <th onClick={() => onSort('candidate_mobile_number')}>Mobile Number {getSortIcon('candidate_mobile_number')}</th>
                                                    <th onClick={() => onSort('candidate_email')}>Email ID {getSortIcon('candidate_email')}</th>
                                                    <th onClick={() => onSort('resume')}>Resume {getSortIcon('resume')}</th>
                                                    {
                                                        (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                            <>
                                                                <th onClick={() => onSort('primary_skill')}>Primary Skill {getSortIcon('primary_skill')}</th>
                                                                <th onClick={() => onSort('createdAt')}>Uploaded On {getSortIcon('createdAt')}</th>
                                                                <th onClick={() => onSort('available_on')}>Available On {getSortIcon('available_on')}</th>
                                                                <th onClick={() => onSort('scheduled_on')}>Scheduled On {getSortIcon('scheduled_on')}</th>
                                                                <th onClick={() => onSort('scheduler_name')}>Scheduler {getSortIcon('scheduler_name')}</th>
                                                                <th>Schedule</th>
                                                            </>
                                                        )
                                                    }
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !_.isUndefined(candidates) && !_.isNull(candidates) &&
                                                    _.map(candidates, (candidate, index) => {
                                                        return <tr key={"feednback-completed-" + index}>
                                                            <td>{candidate._id}</td>
                                                            <td className='fw-bold'>{candidate.candidate_name}</td>
                                                            <td>{candidate.candidate_mobile_number}</td>
                                                            <td>{candidate.candidate_email}</td>
                                                            <td className='text-center'>
                                                                {
                                                                    candidate.resume == "" ? <>
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={<Tooltip>Upload Resume</Tooltip>}
                                                                        >
                                                                            <button className='btn btn-outline-primary btn-sm' onClick={() => {
                                                                                setSelectedItem(candidate);
                                                                                fileInputRef.current.click();
                                                                            }}>Browse</button>
                                                                        </OverlayTrigger>
                                                                    </> : <>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Candidate Resume</Tooltip>}
                                                                        >
                                                                            <a style={{color: 'black'}} className="cursor-pointer" onClick={() => {
                                                                                setSelectedItem(candidate)
                                                                                setIsShowResumeDialog(true)
                                                                            }}><FaFileAlt /></a>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                }
                                                            </td>
                                                            {
                                                                (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                                    <>
                                                                        <td>{candidate.primary_skill.join(',')}</td>
                                                                        <td>{commonDateFormat(candidate.createdAt)}</td>
                                                                        <td>{candidate.available_on ? commonDateFormat(candidate.available_on) : ""}</td>
                                                                        <td>{candidate.scheduled_on ? commonDateFormat(candidate.scheduled_on) : ""}</td>
                                                                        <td>{candidate.schedulerUserInfo ? `${candidate.schedulerUserInfo.first_name} ${candidate.schedulerUserInfo.last_name}` : ""}</td>
                                                                        <td>
                                                                        <>
                                                                            {
                                                                                candidate.scheduler_status != SCHEDULER_STATUS_PENDING ? getSchedulerStatusLabel(candidate.scheduler_status) : "" 
                                                                            }
                                                                            {
                                                                                candidate.scheduler_status != SCHEDULER_STATUS_DONE ? 
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    overlay={<Tooltip>Schedule Interview</Tooltip>}
                                                                                >
                                                                                    <div onClick={() => handleButtonClick(candidate)}><SlCalender /></div>
                                                                                </OverlayTrigger> : ""
                                                                            } 
                                                                        </>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                                <td>
                                                                {
                                                                    (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) ? getRalaAdminActions(candidate) : getActions(candidate)                                                               
                                                                }
                                                                </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            !_.isUndefined(candidates) && !_.isNull(candidates) && candidates.length === 0 && (
                                                <NoRecordFound></NoRecordFound>    
                                            )
                                        }
                                        {
                                            
                                            candidates && candidates.length > 0 && (
                                                <CustomPaginationComponent total={totalCandidates} data={jobDetails} updatePagination={updatePagination} />
                                            )
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                    </div>
                </div>

            </div>

            <Dialog visible={showHistoryModal} modal={false} style={{ width: '60vw' }} onHide={() => setShowHistoryModal(false)} dismissableMask="true">
                <div className='px-md-5 text-end'>
                    {
                        selectedInterviewerApproveStatus !== null && (
                            selectedInterviewerApproveStatus === PROPOSED_INTERVIEWER_STATUS_APPROVE ? <Badge pill bg="success">Approved</Badge> : <Badge pill bg="warning">Pending</Badge>
                        )
                    }
                </div>
                <div className="row px-md-2">
                    <div className="col-12 col-md-6">
                        <label><small>Primary Skills:</small></label>
                        <ReactTags
                            labelText="Select Primary Skills"
                            selected={formData.selected_primary_skill}
                            suggestions={skillList}
                            allowNew={true}
                            onAdd={(selectedList) => {
                                setFormData({
                                    ...formData,
                                    selected_primary_skill: [...formData.selected_primary_skill, selectedList]
                                });
                            }}
                            onDelete={(selectedList) => {
                                setFormData({
                                    ...formData,
                                    selected_primary_skill: formData.selected_primary_skill.filter((_, i) => i !== selectedList)
                                });
                            }}
                            placeholderText="Add new skill"
                            noOptionsText="No matching skill"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label><small>Secondary Skills:</small></label>
                        <ReactTags
                            labelText="Select Secondary Skills"
                            selected={formData.selected_secondary_skill}
                            suggestions={skillList}
                            allowNew={true}
                            onAdd={(selectedList) => {
                                setFormData({
                                    ...formData,
                                    selected_secondary_skill: [...formData.selected_secondary_skill, selectedList]
                                });
                            }}
                            onDelete={(selectedList) => {
                                setFormData({
                                    ...formData,
                                    selected_secondary_skill: formData.selected_secondary_skill.filter((_, i) => i !== selectedList)
                                });
                            }}
                            placeholderText="Add new skill"
                            noOptionsText="No matching skill"
                        />
                    </div>
                </div>
                <div className="row px-md-2 py-2">
                    <div className="col-12 col-md-6">
                        <div className="d-flex justify-content-between mb-2">
                            <p className="font-weight-bold"><small>Candidate Name</small></p>
                            <p className="font-weight-bold"><span className='fw-bold'>{candidateData ? candidateData.candidate_name : ""}</span></p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <p className="font-weight-bold"><small>Job title</small></p>
                            <p className="font-weight-bold"><span className='fw-bold'>{jobDetails ? jobDetails.job_title : ""}</span></p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <p className="font-weight-bold"><small>Primary skill</small></p>
                            <p className="font-weight-bold word-break-all"><small className='fw-bold'>{candidateData ? candidateData.primary_skill : ""}</small></p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <div className="font-weight-bold"><small>Schedule</small></div>
                            <div className="font-weight-bold">
                                <div className="row">
                                    <div className='col-12 col-md-6'>
                                        <input
                                            min={currentDate}
                                            type="date"
                                            name="date"
                                            pattern="\d{2}/\d{2}/\d{4}"
                                            placeholder="DD/MM/YYYY"
                                            value={formData.date}
                                            onChange={scheduleHandleInputChange}
                                            className='form-control'
                                            required
                                            style={{
                                                'width': '141px',
                                                'padding': '5px 9px',
                                                'marginRight': '10px'
                                            }}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <Form.Select value={formData.time} aria-label="Default select example" name="time" onChange={scheduleHandleInputChange}>
                                            <option value="">Select Slot</option>
                                            {
                                                timeSlots.map((item) => (
                                                    <option value={item.value}>{item.text}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">

                        <div>
                            <Form.Group className="mb-0 mt-0">
                                <input
                                    type="checkbox"
                                    name="isAdhocInterview"
                                    checked={formData.isAdhocInterview}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            isAdhocInterview: e.target.checked
                                        });
                                    }}
                                />
                                <Form.Label>
                                    &nbsp; Schedule Ad-Hoc Interview
                                </Form.Label>
                            </Form.Group>
                        </div>

                        <Form.Select aria-label="Default select example" onChange={scheduleHandleInputChange} name="interviewer_user_id" >
                            <option>Choose Interviewer</option>
                            {
                                availableInterviewers.map((interviewer) => (
                                    <option approveStatus={interviewer.status} key={interviewer.interviewerId} value={interviewer.interviewerId}>
                                        {`${interviewer.first_name} ${interviewer.last_name}`}
                                    </option>
                                ))
                            }
                        </Form.Select>

                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <Form.Label>Upload Sample feedback sheet</Form.Label>
                                <Form.Control type="file" id="sampleFeedbackSheet" name="uploadJD" placeholder="load Sample feedback sheet" maxLength={10} onChange={(e) => uploadFile(e, 'sample_feedback_sheet')} autoComplete="off" />
                            </Form.Group>
                        </div>

                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <input
                                    type="checkbox"
                                    name="clientHasMeetingLink"
                                    checked={formData.clientHasMeetingLink}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            clientHasMeetingLink: e.target.checked
                                        });
                                    }}
                                />
                                <Form.Label>
                                    &nbsp; Client has meeting link ?
                                </Form.Label>
                                {
                                    formData.clientHasMeetingLink && (
                                        <Form.Control type="text" name="meetingLink" onChange={scheduleHandleInputChange} placeholder="Enter meeting link" autoComplete="off" />
                                    )
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className='col-12'>
                        {
                            formData.clientHasMeetingLink && (
                                <Form.Group>
                                    <Form.Label>
                                        Tracer details or instruction
                                    </Form.Label>
                                    <CKEditor
                                        config={{ placeholder: "Tracer details or instruction" }}
                                        editor={ClassicEditor}
                                        data={formData.instruction}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFormData({ ...formData, instruction: data });
                                        }}
                                    />
                                </Form.Group>
                            )
                        }
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 text-center">
                            <button className="btn btn-outline-primary" onClick={() => setShowHistoryModal(false)}>Cancel</button>
                            <button onClick={() => {
                                handleSubmit();
                            }} className="btn btn-primary ms-2">Save</button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Remove profile?" visible={showRemoveProfileConfirmDialog} style={{ width: '350px', textAlign: 'center' }} pt={{
                root: { className: 'dialog-root' },
                header: { className: 'dialog-header' },
                content: { className: 'dialog-content' },
                footer: { className: 'dialog-footer' },
                closeButton: { className: 'hide' }
            }} footer={removeProfileFooter} onHide={() => setShowRemoveProfileConfirmDialog(false)} dismissableMask="true"></Dialog>

            {
                showUploadProfiles && !_.isUndefined(jobDetails) && !_.isNull(jobDetails) && !_.isUndefined(jobDetails._id) && !_.isNull(jobDetails._id) && (jobDetails._id !== '')
                &&

                <Dialog visible={showUploadProfiles} style={{ width: '90vw', textAlign: 'left', fontSize: '16px' }} pt={{
                    root: { className: 'dialog-root' },
                    header: { className: 'dialog-header' },
                    content: { className: 'dialog-content' },
                    footer: { className: 'dialog-footer' },
                    closeButton: { className: 'hide' }
                }} footer={uploadProfileModalfooterContent} onHide={() => setShowUploadProfiles(false)} dismissableMask="true">
                    <div className="row mt-4">
                        <div className="col-12 my-2 my-md-0 col-md-12 col-lg-6 col-xl-6">
                            <div className="row">
                                <div className="col-12 mb-2"><small>Job title: </small><span><span className='fs-5 fw-bold'>{jobDetails.job_title}</span></span></div>
                                <div className="col-12 mb-2"><small>Primary Skill: </small><span className='fs-5 fw-bold mx-2'>{jobDetails.primary_skill.length > 0 ? jobDetails.primary_skill.join(', ') : ''}</span></div>
                                <div className="col-12 mb-2"><small>Job created date: </small><span className='fs-5 fw-bold'>{commonDateFormat(jobDetails.createdAt)}</span></div>
                                <div className="col-12 mb-2"><small>Job created by: </small><span className='fs-5 fw-bold'>{jobDetails.job_created_by_name}</span></div>
                            </div>
                        </div>
                        <div className="col-12 my-2 my-md-0 col-md-12 col-lg-6 col-xl-6">
                            <div className='upload-profile-resume-cv'>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <input style={{ display: 'none' }} type="file" id="upload_profile" name="upload_profile" onChange={uploadFiles} placeholder="Upload Profile" multiple ref={browserResumesFileRef} />
                                        <div className="file-upload-container mt-2" onClick={() => browserResumesFileRef.current.click()}>
                                            <div className="custom-file-upload">
                                                Choose file
                                            </div>
                                            <div className="file-name">{uploadedCandidatebulkFileName ?? "No file chosen"}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                        <input style={{ display: 'none' }} type="file" id="upload_csv" name="upload_csv" onChange={uploadCSV} placeholder="CSV" accept=".csv" ref={browserCSVFileRef} />
                                        <div className="file-upload-container mt-2" onClick={() => browserCSVFileRef.current.click()}>
                                            <div className="custom-file-upload">
                                                Choose file
                                            </div>
                                            <div className="file-name">{uploadedCandidateCSVFileName ?? "No file chosen"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <small style={{ fontSize: '12px' }}>*Maximum 25 profile can be uploaded at once</small>
                                    </div>
                                    <div className="col-6">
                                        <small style={{ fontSize: '12px' }}>*Upload CSV in a specific format <a href={Sample_CSV_format} download>Sample CSV format</a></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Candidate Name</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Phone Number</small>
                        </div>
                        <div className="col-12 col-lg-3">
                            <small className='fs-5'>Email Address</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Available on</small>
                        </div>
                        <div className="col-12 col-lg-2">
                            <small className='fs-5'>Resume</small>
                        </div>
                        <div className="col-12 col-lg-1">
                            <small className='fs-5'>Delete</small>
                        </div>
                    </div>

                    {
                        _.map(candidateProfileData, (candidate, index) => {
                            return <div className="row mb-3" key={"candidateProfileData_" + index}>
                                <div className="col-2">
                                    <InputText style={{
                                        width: '100%'
                                    }} name="candidate_name" value={candidate.candidate_name} placeholder="Candidate Name" onChange={(event) => profileChangeHandler(index, event)} maxLength={50} autoComplete="off" keyfilter={/^[A-Za-z\s]*$/} />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'candidate_name') ?
                                                <Alert variant="danger" className="mt-2">
                                                    <small>{getProfileErrorMessage(candidate.uniqueId, 'candidate_name')}</small>
                                                </Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-2">
                                    <InputText style={{
                                        width: '100%'
                                    }} name="candidate_mobile_number" value={candidate.candidate_mobile_number} placeholder="Mobile Number" onChange={(event) => profileChangeHandler(index, event)} minLength={10} maxLength={10} autoComplete="off" keyfilter="int" />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'candidate_mobile_number') ?
                                                <Alert variant="danger" className="mt-2">
                                                    <small>{getProfileErrorMessage(candidate.uniqueId, 'candidate_mobile_number')}</small>
                                                </Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-3">
                                    <InputText style={{
                                        width: '100%'
                                    }} name="candidate_email" value={candidate.candidate_email} placeholder="Email Address" onChange={(event) => profileChangeHandler(index, event)} maxLength={50} autoComplete="off" />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'candidate_email') ?
                                                <Alert variant="danger" className="mt-2">{getProfileErrorMessage(candidate.uniqueId, 'candidate_email')}</Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-2">
                                    <DatePicker className="form-control" placeholderText="dd/MM/yyyy hh:mm a" showTimeSelect selected={new Date(candidate.available_on)} minDate={new Date()} dateFormat="dd/MM/yyyy hh:mm a" onChange={(date) => changeDateHandler(index, date)} />
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'available_on') ?
                                                <Alert variant="danger" className="mt-2">{getProfileErrorMessage(candidate.uniqueId, 'candidate_email')}</Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-2">
                                    {
                                        candidate.resume === "" ? (
                                            <FileUpload ref={resumeUploadRef} mode="basic" chooseLabel="Upload Resume" name="resume" accept="application/*" maxFileSize={1000000} customUpload onSelect={(event) => uploadResumeHandler(index, event)} />
                                        ) :
                                            <div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Candidate Resume</Tooltip>}
                                                >
                                                    <small>
                                                        <a target='_blank' href={candidate.resume}>
                                                            {(new URL(candidate.resume).pathname.split('/').pop().replaceAll("%20", " "))}
                                                        </a>
                                                    </small>
                                                </OverlayTrigger>
                                                &nbsp;&nbsp;
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Remove Resume</Tooltip>}
                                                >
                                                    <button className='btn'>
                                                        <IoClose style={{
                                                            cursor: 'pointer',
                                                            color: 'red'
                                                        }} size={30} onClick={() => {
                                                            let newCandidateProfileData = [...candidateProfileData];
                                                            newCandidateProfileData[index]['resume'] = "";
                                                            setCandidateProfileData(newCandidateProfileData);
                                                        }} />
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                    }
                                    {
                                        !_.isUndefined(errors) && !_.isNull(errors) && errors.length > 0 && (
                                            getProfileErrorMessage(candidate.uniqueId, 'resume') ?
                                                <Alert variant="danger" className="mt-2">{getProfileErrorMessage(candidate.uniqueId, 'resume')}</Alert> :
                                                ""
                                        )
                                    }
                                </div>
                                <div className="col-1 text-center">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Remove Profile</Tooltip>}
                                    >
                                        <div style={{
                                            background: '#f3ebe9',
                                            borderRadius: '50%',
                                            width: '48px',
                                            textAlign: 'center',
                                            paddingTop: '12px',
                                            height: '48px'
                                        }}>
                                            <i style={{
                                                cursor: 'pointer',
                                                fontSize: '1.5rem',
                                            }} onClick={() => removeCandidateProfile(index)} className="pi pi-times" ></i>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        })
                    }


                </Dialog>
            }

            <Dialog header="Candidate Resume" visible={isShowResumeDialog} style={{ width: '50vw', height: '80vh' }} onHide={() => setIsShowResumeDialog(false)} dismissableMask="true">
                {
                    selectedItem && selectedItem.resume &&
                    (
                        selectedItem.resume.includes('.doc') ?
                            <iframe title="Resume" key="iframe-doc" style={{
                                width: '100%',
                                height: '100%'
                            }} src={`https://docs.google.com/gview?url=${selectedItem.resume}&embedded=true`}></iframe> :
                            <object title="Resume" key="object-other" data={selectedItem.resume} style={{
                                width: '100%',
                                height: '100%'
                            }}></object>
                    )
                }
            </Dialog>

        </Fragment>

    );
}

export default ScheduledProfileScreen;
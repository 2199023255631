import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, Card, Col, Container, DropdownButton, FloatingLabel, Form, InputGroup, ListGroup, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiMessageRounded, BiSolidBookAdd, BiSolidVideos } from "react-icons/bi";
import { CiFilter } from "react-icons/ci";
import { FaFileExcel } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { FaDownload, FaFileAlt, FaHistory, FaMailBulk, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Loader from "react-js-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_URL, INTERVIEW_FINAL_STATUS_REJECTED, INTERVIEW_FINAL_STATUS_SELECTED, ITEMS_PER_PAGE, PROXY, QC_STATUS_FAIL, QC_STATUS_PASS, commonDateFormat, fileName, generateContent, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, getincompleteInterview } from "../../common/constant";
import PaginationComponent from "../../common/PaginationComponent/PaginationComponent";
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./ReportScreen.css";
import NoRecordFound from '../../common/NoRecordFound/NoRecordFound';
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';

const ReportScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    var currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const [fromDate, setFromDate] = useState(currentDate);
    const [toDate, setToDate] = useState(new Date());
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const [interviewList, setInterviewList] = useState([]);
    const [totalInterviewList, setTotalInterviewList] = useState(0);
    const [initialInterviewList, setInitialInterviewList] = useState([]); // for date picker cancel action
    const [navType, setNavType] = useState("Pending");
    const { id: jobId } = useParams();
    const [breadJobTitle, setBreadJobTitle] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [selecteAll, setSelecteAll] = useState(false);
    const [isShowSuggestion, setIsShowSuggestion] = useState(false);

    const [isShowQCRemark, setIsShowQCRemark] = useState(false)
    const [updatedInterviewId, setUpdatedInterviewId] = useState("");
    const [interviewerBody, setInterviewerBody] = useState({
        qc_status: "",
        qc_remark: "",
        record_editable_till: ""
    });

    const [interviewIdForQcRemark, setInterviewIdForQcRemark] = useState("");
    const [qcRemark, setQcRemark] = useState("");

    const [isShowHistoryModel, setIsShowHistoryModel] = useState(false)
    const [candidateData, setCandidateData] = useState(null)
    const [addedAttempts, setAddedAttempts] = useState([]);
    const toast = useRef(null);

    const [buCode, setBuCode] = useState([]);
    const [jobIds, setJobIds] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [recruiter, setRecruiter] = useState([]);
    const [inititalRecruiter, setInititalRecruiter] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [selectedFields, setSelectedFields] = useState({
        companyIds: [],
        subAdminIds: [],
        jobTitles: [],
        jobIds: [],
        ratings: [],
        statuses: [],
        buCodes: [],
    });

    const [editedId, setEditedId] = useState("");

    const [reportPageNumber, setReportPageNumber] = useState(1);
    const [reportPageSize, setReportPageSize] = useState(ITEMS_PER_PAGE);

    const updateReportPagination = (pageNumber, pageSize = 20) => {
        setReportPageNumber(pageNumber)
        setReportPageSize(pageSize);
    }

    useEffect(() => {
        if (!_.isUndefined(interviewList) && !_.isNull(interviewList) && (interviewList.length === 0)) {
            getCandidates();
        }
        if (!_.isUndefined(companyList) && !_.isNull(companyList) && (companyList.length === 0)) {
            getCompanyList();
        }
        if (!_.isUndefined(recruiter) && !_.isNull(recruiter) && (recruiter.length === 0)) {
            getRalaAdminList();
        }
    }, []);

    useEffect(() => {
        if (updatedInterviewId != "") {
            updateCandidateProfile(updatedInterviewId, interviewerBody);
        }
    }, [updatedInterviewId])

    useEffect(() => {
        getCandidates();
    }, [searchText, navType, selectedFields, reportPageNumber, reportPageSize, sortConfig]);

    const getRalaAdminList = () => {
        setSearchText("")
        fetch(`${API_URL}api/getUsers?sortBy=first_name&sortOrder=asc`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                account_type: SUB_ADMIN  
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setRecruiter(response.data);
                    setInititalRecruiter(response.data);
                }
            })
            .catch((err) => {
                setRecruiter([]);
            });
    }

    const getCandidates = () => {
        setIsLoading(true)
        const apiUrl = `${API_URL}api/getCandidatesByJob?page=${reportPageNumber}&limit=${reportPageSize}&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`;
        let param = {
            search: searchText,
            ...selectedFields
        }
        if (fromDate && toDate) {
            param = {
                ...param,
                startDate: fromDate,
                endDate: toDate
            }
        }
        if (jobId != "" && jobId != undefined && jobId != null) {
            param = {
                ...param,
                jobIds: [jobId]
            }
        }
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(param)
        }
        )
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setBuCode(response.jobBuCodes);
                    setJobIds(response.jobIds)
                    setJobTitles(response.jobTitles)
                    setInterviewList(response.data);
                    setInitialInterviewList(response.data)
                    setTotalInterviewList(response.totalCount)
                    setUpdatedInterviewId("")
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setBuCode([]);
                setJobIds([])
                setJobTitles([])
                setInterviewList([]);
                setInitialInterviewList([])
                setTotalInterviewList(0)
                setIsLoading(false)
            });
    }

    const getCompanyList = () => {
        fetch(`${API_URL}api/companies`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setCompanyList(response.data);
                }
            })
            .catch((err) => {
                setCompanyList([]);
            });
    }
    
    const getStartDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(0, 0, 0, 0);
        return originalDate;
    }

    const getEndDate = (date) => {
        const originalDate = new Date(date);
        originalDate.setHours(23, 59, 59, 999);
        return originalDate;
    }

    const downloadCSV = () => {
        if (interviewList.length > 0) {
            const csvContent = convertToCsv(interviewList);
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName() + '.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback downloaded successfully", life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records available for download.', life: 3000 });
        }
    };

    async function imageToBase64(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
            });
        } catch (error) {
            console.error('Error converting image to base64:', error);
            return null;
        }
    }

    const getInterviewDetails = async (id) => {
        try {
            const response = await fetch(`${API_URL}api/getInterview/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            });

            const responseData = await response.json();
            const interviewData = responseData.data;
            var averagePrimarySkillsRating = 0;
            interviewData.primary_skill_feedback.map((item) => {
                averagePrimarySkillsRating += Number(item.rating);
            });
            interviewData.averagePrimarySkillsRating = Math.floor(averagePrimarySkillsRating / interviewData.primary_skill_feedback.length);
            var averageSecondarySkillsRating = 0;
            interviewData && interviewData.secondary_skill_feedback.map((item) => {
                averageSecondarySkillsRating += Number(item.rating);
            });
            interviewData.averageSecondarySkillsRating = Math.floor(averageSecondarySkillsRating / interviewData.primary_skill_feedback.length);

            const uploadedScreenshots = [];
            for (var j = 0; j < interviewData.code_of_screenshot.length; j++) {
                const base64 = await imageToBase64(interviewData.code_of_screenshot[j]);
                uploadedScreenshots.push(base64);
            }

            interviewData.uploadedScreenshots = uploadedScreenshots;

            return interviewData;
        } catch (error) {
            console.log("error", error)
            return null;
        }
    }

    const downloadZIP = async () => {
        setIsLoading(true);
        const contents = [];
        var itemNumber = 1;
        for (const item of interviewList) {
            if (item.is_feedback_given) {
                if (item.feedback_sheet === "") {
                    const interviewDetail = await getInterviewDetails(item._id);
                    if (interviewDetail) {
                        const htmlContent = generateContent(interviewDetail);
                        contents.push({ candidateId: item._id, type: 'html', content: htmlContent });
                    }
                } else {
                    contents.push({ candidateId: item._id, type: 'file', content: item.feedback_sheet, fileName: `file_${item._id}` });
                }
            }
        }

        if (contents.length > 0) {
            const zip = new JSZip();
            await Promise.all(contents.map(async (item, index) => {
                if (item.type === 'html') {
                    const element = document.createElement('div');
                    element.innerHTML = item.content;

                    // Improved PDF generation with higher quality and pagebreak handling
                    const opt = {
                        margin: [0.5, 0, 0.5, 0], // Top, Right, Bottom, Left margins
                        filename: `${fileName()}.pdf`,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
                        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                        pagebreak: { mode: ['css', 'legacy'] },
                    };

                    const pdfBlob = await html2pdf().set(opt).from(element).toPdf().output('blob');

                    zip.file(`${item.candidateId}_${fileName()}.pdf`, pdfBlob);
                } else {
                    const response = await fetch(item.content); // Assuming item.content is the URL of the file
                    const fileBlob = await response.blob();
                    const fileExtension = item.content.split('.').pop(); // Get file extension from URL
                    zip.file(`${item.candidateId}_${fileName()}.fileExtension`, fileBlob);
                }
            }));

            zip.generateAsync({ type: 'blob' }).then((content) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(content);
                link.download = `${fileName()}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });

            setIsLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback downloaded successfully", life: 3000 });
        } else {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records available for download.', life: 3000 });
        }
    }

    const reportData = () => {
        selectedItems.length = 0;
        if (interviewList && interviewList.length > 0) {
            for (var index = 0; index < interviewList.length; index++) {
                if (!interviewList[index].is_sent_to_employeer) {
                    selectedItems.push(interviewList[index]._id);
                }
            }
            sentToEmployeer();
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records to send report.', life: 3000 });
        }
    }

    const convertToCsv = (data) => {
        const header = "Job ID,Candidate ID,Candidate Name,Email ID,Mobile Number,Job Title,Status,Incomplete Interview,Rating,Scheduler Name,Scheduler Number,Interviewer Name,Interviewer Number,BU Code,Profile Uploaded,Interview Scheduled,Video,QC Status,QC Remark,Follow Up Attempts,History"
        const rows = data.map((row) => {
            const followUpData = row.follow_up_attempts.map((item) => {
                return `${commonDateFormat(item.follow_up_date)} ${item.follow_up_reason.replace(",", "")} ${item.upload_by.replace(",", "")}`;
            }).join('        ');
            return [
                row.job_id,
                row._id,
                row.candidate_name,
                row.candidate_email,
                row.candidate_mobile_number,
                row.job_title,
                getInterviewFinalStatus(row.final_status),
                getincompleteInterview(row.incomplete_interview),
                row.overall_rating,
                row.schedulerUserInfo ? `${row.schedulerUserInfo.first_name} ${row.schedulerUserInfo.last_name}` : "",
                row.schedulerUserInfo ? row.schedulerUserInfo.mobile_number : "",
                row.interviewInfo ? `${row.interviewInfo.first_name} ${row.interviewInfo.last_name}` : "",
                row.interviewInfo ? row.interviewInfo.mobile_number : "",
                row.jobBuCode,
                commonDateFormat(row.createdAt),
                row.scheduled_on ? commonDateFormat(row.scheduled_on) : "",
                row.video.length,
                getQCStatuslabel(row.qc_status),
                row.qc_remark.replace(",", ""),
                row.follow_up_attempts.length,
                followUpData
            ];
        });
        const csvContent = `${header}\n${rows.map(row => row.join(',')).join('\n')}`;

        return csvContent;
    };

    const qcStatus = (rowData) => {
        return ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && editedId === rowData._id) ? (
            <Form.Select style={{ width: '100px' }}
                onChange={(e) => {
                    const value = e.target.value;
                    const a = interviewList.map((interviewer) =>
                        interviewer._id === rowData._id
                            ? { ...interviewer, qc_status: value ? QC_STATUS_PASS : QC_STATUS_FAIL }
                            : interviewer
                    );
                    setInterviewList(a)
                    setInterviewerBody({
                        ...interviewerBody,
                        qc_status: value,
                        qc_remark: rowData.qc_remark
                    })
                    if (value === QC_STATUS_FAIL) {
                        setInterviewIdForQcRemark(rowData._id);
                        setIsShowQCRemark(true);
                    } else {
                        setUpdatedInterviewId(rowData._id);
                    }
                }}
            >
                <option value="">Actions</option>
                <option style={{ color: 'green' }} selected={rowData.qc_status === QC_STATUS_PASS} value={QC_STATUS_PASS}>Pass</option>
                <option style={{ color: 'red' }} selected={rowData.qc_status === QC_STATUS_FAIL} value={QC_STATUS_FAIL}>Fail</option>
            </Form.Select>
        ) : (
            <div style={{ color: rowData.qc_status === QC_STATUS_PASS ? 'green' : (rowData.qc_status === QC_STATUS_FAIL ? 'red' : '') }}>{getQCStatuslabel(rowData.qc_status)}</div>
        )
    }

    const calRef = useRef(null)
    const editField = (rowData) => {
        return (userDetails.account_type == SUPER_ADMIN && editedId === rowData._id) ? (
            <DatePicker 
                ref={calRef}
                showTimeSelect 
                className="form-select w-100 record_editable_till_datepicker" 
                selected={rowData.record_editable_till ? new Date(rowData.record_editable_till) : new Date()} 
                dateFormat="MMMM d, yyyy h:mm aa" 
                onChange={(value) => {
                    const a = interviewList.map((interviewer) =>
                    interviewer._id === rowData._id
                        ? { ...interviewer, record_editable_till: value }
                        : interviewer
                    );
                    setInterviewList(a)
                    setInterviewerBody({
                        ...interviewerBody,
                        record_editable_till: value
                    })
                }}
            >
                <div>
                    <Button
                        className='history-search-button'
                        onClick={() => {
                            calRef.current.setOpen(false);
                            setUpdatedInterviewId(rowData._id);
                        }}
                    >Apply</Button>
                    &nbsp;
                    <Button
                        className='history-search-button'
                        onClick={() => {
                            calRef.current.setOpen(false);
                            const filterRecord = initialInterviewList.filter((item) => item._id === rowData._id)
                            let initialRecordEditableTillValue = null;
                            if (filterRecord.length > 0) {
                                initialRecordEditableTillValue = filterRecord[0].record_editable_till
                            }
                            const a = interviewList.map((interviewer) =>
                            interviewer._id === rowData._id
                                ? { ...interviewer, record_editable_till: initialRecordEditableTillValue }
                                : interviewer
                            );
                            setInterviewList(a)
                            setInterviewerBody({
                                ...interviewerBody,
                                record_editable_till: ""
                            })
                        }}
                    >Cancel</Button>
                </div>
            </DatePicker>

        ) : (
            <div>{rowData.record_editable_till ? commonDateFormat(rowData.record_editable_till) : ""}</div>
        )
    }

    const updateCandidateProfile = (candidateId, body, isCallApi = true, message = "") => {
        fetch(`${API_URL}api/updateCandidateProfileData/${candidateId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((response) => {
                if (isCallApi) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: message ? message : "Report updated successfully", life: 3000 });
                    getCandidates()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            });
    }

    const sentInterviewtoEmployeer = async (candidateId, body) => {
        try {
            const response = await fetch(`${API_URL}api/updateCandidateProfileData/${candidateId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(body)
            });
            const responseData = await response.json();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        }
    };

    const location = useLocation();
    const navigate = useNavigate();

    const sentToEmployeer = async () => {
        if (selectedItems.length > 0) {
            setIsLoading(true)
            for (const item of selectedItems) {
                await sentInterviewtoEmployeer(item, { is_sent_to_employeer: true }, false);
            }
            setIsLoading(false)
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Feedback sent successfully", life: 3000 });
            navigate(location.pathname, { replace: true });
            getCandidates();
            setSelectedItems([]);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Currently, there are no records to send report.', life: 3000 });
        }
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
            const isSelected = prevSelectedFields[fieldType].includes(value);
            let updatedFields = {
                ...prevSelectedFields,
                [fieldType]: isSelected
                    ? prevSelectedFields[fieldType].filter((item) => item !== value)
                    : [...prevSelectedFields[fieldType], value],
            };
            if (fieldType === "companyIds") {
                updatedFields['subAdminIds'] = [];
                if (updatedFields.companyIds.length === 0) {
                    setRecruiter(inititalRecruiter)
                } else {
                    const recruiterIds = (companyList.filter((item) => updatedFields.companyIds.includes(item.id))).map((item) => item.assigned_rala_sub_admin)
                    const filteredRecruiters = inititalRecruiter.filter((item) => recruiterIds.includes(item.id))
                    setRecruiter(filteredRecruiters)
                }
            }
            return updatedFields;
        });
    };

    const toggleAll = (checked) => {
        if (checked) {
            var items = [];
            interviewList.map((item) => {
                if (item.is_feedback_given && !item.is_sent_to_employeer) {
                    items.push(item._id);
                }
            })
            setSelectedItems(items)
        } else {
            setSelectedItems([])
        }
    }

    const [isShowVideoModal, setIsShowVideoModal] = useState(false);
    const [videoData, setVideoData] = useState([])

    const handleClear = (name) => {
        if (name == 'toDate') {
            setToDate(null);
        } else {
            setFromDate(null);
        }
    };

    const clearSearch = () => {
        setSearchText("")
    }

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control" // Assuming you're using Bootstrap for styling
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort />;
        }
        if (sortConfig.direction === 'asc') {
            return <FaSortUp />;
        }
        return <FaSortDown />;
    };

    const clearFilter = () => {
        setRecruiter(inititalRecruiter)
        setSelectedFields({
            companyIds: [],
            subAdminIds: [],
            jobTitles: [],
            jobIds: [],
            ratings: [],
            statuses: [],
            buCodes: [],
        })
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid history-container fixed-padding-3">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pageTitle_fixed">
                        <p className="employer-dashboard-jobs-posted mb-0">
                            <div className='d-flex align-items-center gap-2'>
                                <BiSolidReport size={18} />
                                {
                                    (jobId != undefined && jobId != null && jobId != "") ? (
                                        breadJobTitle + " / " + jobId.substring(0, 5) + "..."
                                    ) : "REPORT"
                                }
                            </div>
                        </p>
                    </div>
                </div>
                <div className="row mb-3 date_filter">
                    <div className='col-12 col-md-12 col-lg-4 mb-2'>
                        <div className='d-flex gap-2'>
                            <Button variant="outline-primary" onClick={downloadCSV}>
                                <FaFileExcel /> {" "} CVC
                            </Button>
                            <Button variant="outline-primary" onClick={reportData}>
                                <FaMailBulk /> {" "} Report
                            </Button>
                            <Button variant="outline-primary" onClick={downloadZIP}>
                                <FaDownload /> {" "} Feedback
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-5 mb-2'>
                        <div className='row'>
                            <div className='col-12 col-md-4 col-lg-4'>
                                <DatePicker className="form-select w-100" maxDate={toDate ? new Date(toDate) : null} onChange={(date) => {
                                setFromDate(date);
                                handleClear('toDate');
                            }} selected={fromDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('fromDate')} />} />
                                <small className="last-one-month text-danger mt-2" style={{float:'left'}}>Available for last one month</small>
                            </div>
                            <div className='col-12 col-md-4 col-lg-4 mb-2 mb-md-0'>
                                <DatePicker className="form-select w-100 " minDate={fromDate ? new Date(fromDate) : null} maxDate={fromDate ? new Date(moment(fromDate).add(30, 'days')) : null} onChange={(date) => setToDate(date)} selected={toDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('toDate')} />} />
                            </div>
                            <div className='col-12 col-md-4 col-lg-4'>
                                <Button className="history-search-button w-100" onClick={() => getCandidates()} style={{ width: '120px' }} >Search</Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-3 mb-2'>
                        <InputGroup>
                            <Form.Control 
                                type="text" 
                                id="Search" 
                                onChange={(e) => {
                                    setSearchText(e.target.value.trim());
                                }}
                                name="Search"
                                value={searchText}
                                placeholder="Search"
                                maxLength={150}
                                onFocus={() => {
                                    if (userDetails && userDetails.account_type === SUPER_ADMIN) {
                                        setIsShowSuggestion(true)
                                    }
                                }}
                                onBlur={() => {
                                    if (userDetails && userDetails.account_type === SUPER_ADMIN) {
                                        setTimeout(() => {
                                            setIsShowSuggestion(false);
                                        }, 100);
                                    }
                                }}
                                autoComplete="off"
                            />
                            {
                                searchText && (
                                    <InputGroup.Text onClick={clearSearch} className="search-clear cursor-pointer">&#x2715;</InputGroup.Text>
                                )
                            }
                        </InputGroup>

                    </div>
                </div>
                <div className="row history-feednback-row">
                    <div className="col-12">
                        {
                            ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId != undefined && jobId != "" && jobId != null) && (
                                <Tab.Container defaultActiveKey="first">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" onClick={() => {
                                                setNavType('Pending');
                                                setReportPageNumber(1);
                                            }} >Pending</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" onClick={() => {
                                                setNavType('Sent');
                                                setReportPageNumber(1);
                                            }}>Sent</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Tab.Container>
                            )
                        }
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    {
                                        (userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN || userDetails.account_type === SUB_ADMIN)) && (
                                            <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Filters</Tooltip>}
                                                >
                                                    <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >
                                                        {
                                                            userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                <DropdownButton className="nested-button" title={"Company" + (selectedFields.companyIds.length > 0 ? ` (${selectedFields.companyIds.length})` : "")}>
                                                                    {companyList &&
                                                                        companyList.map((item, index) => (
                                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }} key={item.id}>
                                                                                <span title={item.company_name} className="filter-item">{item.company_name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"companyIds" + index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('companyIds', item.id)}
                                                                                    checked={selectedFields.companyIds.includes(item.id)}
                                                                                />
                                                                            </InputGroup>
                                                                        ))}
                                                                </DropdownButton>
                                                            )
                                                        }
                                                        {
                                                            userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                                <DropdownButton className="nested-button" title={"Recruiter" + (selectedFields.subAdminIds.length > 0 ? ` (${selectedFields.subAdminIds.length})` : "")}>
                                                                    {recruiter &&
                                                                        recruiter.map((item, index) => (
                                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }} key={item.id}>
                                                                                <span title={item.first_name + " " + (item.last_name ?? "")} className="filter-item">{item.first_name + " " + (item.last_name ?? "")}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"subAdminIds" + index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('subAdminIds', item.id)}
                                                                                    checked={selectedFields.subAdminIds.includes(item.id)}
                                                                                />
                                                                            </InputGroup>
                                                                        ))}
                                                                </DropdownButton>
                                                            )
                                                        }
                                                        <DropdownButton className="nested-button" title={"Job Title" + (selectedFields.jobTitles.length > 0 ? ` (${selectedFields.jobTitles.length})` : "")}>
                                                            {
                                                                jobTitles.map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_title" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('jobTitles', item)}
                                                                            checked={selectedFields.jobTitles.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Job ID" + (selectedFields.jobIds.length > 0 ? ` (${selectedFields.jobIds.length})` : "")}>
                                                            {
                                                                jobIds.map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_id" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('jobIds', item)}
                                                                            checked={selectedFields.jobIds.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Rating" + (selectedFields.ratings.length > 0 ? ` (${selectedFields.ratings.length})` : "")}>
                                                            {
                                                                [1, 2, 3, 4, 5].map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"ratings" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('ratings', item)}
                                                                            checked={selectedFields.ratings.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"Status" + (selectedFields.statuses.length > 0 ? ` (${selectedFields.statuses.length})` : "")}>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Selcted'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 0}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', INTERVIEW_FINAL_STATUS_SELECTED)}
                                                                    checked={selectedFields.statuses.includes(INTERVIEW_FINAL_STATUS_SELECTED)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Rejected'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 1}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', INTERVIEW_FINAL_STATUS_REJECTED)}
                                                                    checked={selectedFields.statuses.includes(INTERVIEW_FINAL_STATUS_REJECTED)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'No Show'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 2}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', 'NO_SHOW')}
                                                                    checked={selectedFields.statuses.includes('NO_SHOW')}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                {'Proxy'}
                                                                <InputGroup.Checkbox
                                                                    key={"statuses" + 3}
                                                                    aria-label="Checkbox for following text input"
                                                                    onChange={() => handleCheckboxChange('statuses', PROXY)}
                                                                    checked={selectedFields.statuses.includes(PROXY)}
                                                                />
                                                            </InputGroup>
                                                        </DropdownButton>
                                                        <DropdownButton className="nested-button" title={"BU Code" + (selectedFields.buCodes.length > 0 ? ` (${selectedFields.buCodes.length})` : "")}>
                                                            {
                                                                buCode.map((item, index) => {
                                                                    return <InputGroup className="flex" style={{ justifyContent: 'space-between', padding: '8px' }}>
                                                                        <span title={item} className="filter-item">{item}</span>
                                                                        <InputGroup.Checkbox
                                                                            key={"job_id" + index}
                                                                            aria-label="Checkbox for following text input"
                                                                            onChange={() => handleCheckboxChange('buCodes', item)}
                                                                            checked={selectedFields.buCodes.includes(item)}
                                                                        />
                                                                    </InputGroup>;
                                                                })
                                                            }
                                                        </DropdownButton>
                                                        <div className="my-2" style={{ textAlign: 'center' }}>
                                                            <Button onClick={clearFilter} style={{
                                                                marginTop: '0px',
                                                                background: '#FFFBFE',
                                                                color: 'black',
                                                                border: 'none'
                                                            }} className="employer-dashboard-interview-join">
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </DropdownButton>
                                                </OverlayTrigger>
                                            </div>
                                        )
                                    }
                                    <div className="col-11 reporTable" style={{ flex: 1 }}>
                                        <Table bordered striped hover size="lg" responsive className="history-feednback-completed">
                                            <thead className='sticky-top'>
                                                <tr>
                                                    {
                                                        ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId && navType === 'Pending') && (
                                                            <th>
                                                                <input onChange={(e) => toggleAll(e.target.checked)} type="checkbox" />
                                                            </th>
                                                        )
                                                    }
                                                    <th className='text-start' onClick={() => onSort('job_id')}>Job ID {getSortIcon('job_id')}</th>
                                                    <th className='text-start' onClick={() => onSort('_id')}>Candidate ID {getSortIcon('_id')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_name')}>Candidate Name {getSortIcon('candidate_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_email')}>Email ID {getSortIcon('candidate_email')}</th>
                                                    <th className='text-start' onClick={() => onSort('candidate_mobile_number')}>Mobile Number {getSortIcon('candidate_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('jobTitle')}>Job Title {getSortIcon('jobTitle')}</th>
                                                    <th className='text-start' onClick={() => onSort('final_status')}>Status {getSortIcon('final_status')}</th>
                                                    <th className='text-start' onClick={() => onSort('incomplete_interview')}>Incomplete Interview {getSortIcon('incomplete_interview')}</th>
                                                    <th className='text-start' onClick={() => onSort('overall_rating')}>Rating {getSortIcon('overall_rating')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduler_name')}>Scheduler Name {getSortIcon('scheduler_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduler_mobile_number')}>Scheduler Number {getSortIcon('scheduler_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('interview_name')}>Interviewer Name {getSortIcon('interview_name')}</th>
                                                    <th className='text-start' onClick={() => onSort('interview_mobile_number')}>Interviewer Number {getSortIcon('interview_mobile_number')}</th>
                                                    <th className='text-start' onClick={() => onSort('jobBuCode')}>BU Code {getSortIcon('jobBuCode')}</th>
                                                    <th className='text-start' onClick={() => onSort('createdAt')}>Profile Uploaded {getSortIcon('createdAt')}</th>
                                                    <th className='text-start' onClick={() => onSort('scheduled_on')}>Scheduled On{getSortIcon('scheduled_on')}</th>
                                                    <th>Recording</th>
                                                    <th>Report</th>
                                                    {
                                                        userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN && (
                                                            <>
                                                                <th onClick={() => onSort('QCStatus')}>QC Status {getSortIcon('QCStatus')}</th>
                                                                <th>QC Remark</th>
                                                            </>
                                                        )
                                                    }
                                                    <th onClick={() => onSort('follow_up_attempts')}>Follow Up Attempts {getSortIcon('follow_up_attempts')}</th>
                                                    <th>History</th>
                                                    {
                                                        userDetails && userDetails.account_type === SUPER_ADMIN && (
                                                            <th>Can Edit</th>
                                                        )
                                                    }
                                                    {
                                                        // userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN 
                                                        userDetails
                                                        && (
                                                            <>
                                                                <th>Edit</th>
                                                            </>
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !_.isUndefined(interviewList) && !_.isNull(interviewList) &&
                                                    _.map(interviewList, (interview, index) => {
                                                            return <tr key={"feednback-completed-" + index}>
                                                                {
                                                                    ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId && navType === 'Pending') && (
                                                                        <td><input disabled={!interview.is_feedback_given} checked={selectedItems.includes(interview._id)} onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            let updatedItems;
                                                                            if (isChecked) {
                                                                                updatedItems = [...selectedItems, interview._id];
                                                                            } else {
                                                                                updatedItems = selectedItems.filter((itemId) => itemId !== interview._id);
                                                                            }
                                                                            setSelectedItems(updatedItems);
                                                                        }} type="checkbox" /> </td>
                                                                    )
                                                                }
                                                                <td className="text-start">{interview.job_id}</td>
                                                                <td className="text-start">{interview._id}</td>
                                                                <td className="text-start fw-bold">{interview.candidate_name}</td>
                                                                <td className="text-start">{interview.candidate_email}</td>
                                                                <td className="text-start">{interview.candidate_mobile_number}</td>
                                                                <td className="text-start">{interview.job_title}</td>
                                                                <td className="text-start interviewSatus">
                                                                    <span className={interview.final_status === INTERVIEW_FINAL_STATUS_REJECTED ? 'rejected' : (interview.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? 'selected' : '')} style={{
                                                                        color: interview.final_status === INTERVIEW_FINAL_STATUS_REJECTED ? '#840202' : (
                                                                            interview.final_status === INTERVIEW_FINAL_STATUS_SELECTED ? '#0d5c01' : ''
                                                                        )
                                                                    }}>{getInterviewFinalStatus(interview.final_status)}</span>
                                                                </td>
                                                                <td className="text-center">{getincompleteInterview(interview.incomplete_interview)}</td>
                                                                <td className="text-center">{interview.overall_rating}</td>
                                                                <td className="text-start">{interview.schedulerUserInfo ? `${interview.schedulerUserInfo.first_name} ${interview.schedulerUserInfo.last_name}` : ""}</td>
                                                                <td className="text-start">{interview.schedulerUserInfo ? `${interview.schedulerUserInfo.mobile_number}` : ""}</td>
                                                                <td className="text-start">{interview.interviewerInfo ? `${interview.interviewerInfo.first_name} ${interview.interviewerInfo.last_name}` : ""}</td>
                                                                <td className="text-start">{interview.interviewerInfo ? `${interview.interviewerInfo.mobile_number}` : ""}</td>
                                                                <td className="text-start">{interview.jobBuCode}</td>
                                                                <td className="text-start">{commonDateFormat(interview.createdAt)}</td>
                                                                <td className="text-start">{interview.scheduled_on ? commonDateFormat(interview.scheduled_on) : ""}</td>
                                                                <td className="text-center">
                                                                    {
                                                                        ([EMPLOYER_SUPER_ADMIN, EMPLOYER_SUB_ADMIN].includes(userDetails.account_type) && interview.is_sent_to_employeer) || [SUB_ADMIN, SUPER_ADMIN].includes(userDetails.account_type) && (interview.video.length > 0) && (
                                                                            <BiSolidVideos size={20} onClick={() => {
                                                                                setVideoData(interview.video)
                                                                                setIsShowVideoModal(true)
                                                                            }} />             
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={interview.is_feedback_given ? <Tooltip>Interview Report</Tooltip> : <></>}
                                                                    >
                                                                        <button className="btn">
                                                                            {
                                                                                (
                                                                                    (
                                                                                        (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && interview.is_feedback_given
                                                                                    ) ||
                                                                                    (
                                                                                        (userDetails.account_type === EMPLOYER_SUPER_ADMIN || userDetails.account_type === EMPLOYER_SUB_ADMIN) && interview.is_sent_to_employeer
                                                                                    )
                                                                                ) && (
                                                                                    <FaFileAlt onClick={() => {
                                                                                        if (interview.feedback_sheet != "") {
                                                                                            window.open(interview.feedback_sheet, '_blank');
                                                                                        } else {
                                                                                            window.open(`/finalInterviewReport/${interview._id}`, '_blank')
                                                                                        }
                                                                                    }} />
                                                                                )
                                                                            }
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {
                                                                    userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN && (
                                                                        <>
                                                                            <td className="text-center">
                                                                                {qcStatus(interview)}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip>Add QC Remark</Tooltip>}
                                                                                >
                                                                                    <button className="btn">
                                                                                        <BiMessageRounded onClick={() => {
                                                                                            setInterviewIdForQcRemark(interview._id);
                                                                                            setIsShowQCRemark(true);
                                                                                        }} size={20} />
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                        </>
                                                                    )
                                                                }
                                                                <td className="text-center">{interview.follow_up_attempts.length}</td>
                                                                <td className="text-center">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>Follow History</Tooltip>}
                                                                    >
                                                                        <button className="btn">
                                                                            <FaHistory onClick={() => {
                                                                                setInterviewIdForQcRemark(interview._id);
                                                                                setCandidateData(interview);
                                                                                setIsShowHistoryModel(true);
                                                                            }} size={20} />
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {
                                                                    userDetails.account_type == SUPER_ADMIN && (
                                                                        <td>{editField(interview)}</td>
                                                                    )
                                                                }
                                                                {
                                                                    // userDetails && userDetails.account_type !== EMPLOYER_SUPER_ADMIN && userDetails.account_type !== EMPLOYER_SUB_ADMIN 
                                                                    userDetails && [SUPER_ADMIN, SUB_ADMIN].includes(userDetails.account_type)
                                                                    && (
                                                                        <>
                                                                            <td>
                                                                                <button className="btn btn-outline-primary btn-sm" 
                                                                                // disabled={interview.feedback_date != null && dayDifference(interview.feedback_date) > 1}
                                                                                onClick={() => {
                                                                                    if (editedId === interview._id) {
                                                                                        setEditedId("")
                                                                                    } else {
                                                                                        setEditedId(interview._id)
                                                                                    }
                                                                                }}>
                                                                                    {
                                                                                        editedId === interview._id ? 'Save' : 'Edit'
                                                                                    }
                                                                                </button>
                                                                            </td>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                                
                                                               </tr>
                                                        })
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            !_.isUndefined(interviewList) && !_.isNull(interviewList) && interviewList.length === 0 && (
                                                <NoRecordFound></NoRecordFound>    
                                            )
                                        }
                                        {
                                            ((userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && jobId != "" && jobId != undefined && jobId != null) && navType == 'Pending' && selectedItems.length > 0 && (
                                                <div className="col-12 my-auto" style={{ textAlign: 'right' }}>
                                                    <Button className="history-search-button" onClick={sentToEmployeer} >Send</Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="employer-dashboard-title">
                    {
                        interviewList && interviewList.length > 0 && (
                            <CustomPaginationComponent total={totalInterviewList} updatePagination={updateReportPagination} />
                        )
                    }
                </div>
            </div>
            {
                isShowQCRemark && <Modal show={isShowQCRemark} centered>
                    <Modal.Body>
                        <Form>
                            <Form.Control
                                as="textarea"
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                onChange={(e) => {
                                    setQcRemark(e.target.value)
                                }}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { setIsShowQCRemark(false); }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            updateCandidateProfile(interviewIdForQcRemark, {
                                ...interviewerBody,
                                qc_remark: qcRemark
                            });
                            setIsShowQCRemark(false)
                        }}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {
                isShowHistoryModel && <Modal show={isShowHistoryModel} centered>
                    <Modal.Body>
                        <Container>
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={() => {
                                    setAddedAttempts([
                                        ...addedAttempts,
                                        {
                                            follow_up_date: new Date(),
                                            follow_up_reason: 'follow_up_reason',
                                            upload_by: userDetails.first_name
                                        }
                                    ]);
                                }} >Add</Button>
                            </div>
                            <Row>
                                <Col>Candidate Name : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_name : ""}</b></Col>
                            </Row>
                            <Row>
                                <Col>Email address : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_email : ""}</b></Col>
                            </Row>
                            <Row>
                                <Col>Mobile number : </Col>
                                <Col><b>{candidateData ? candidateData.candidate_mobile_number : ""}</b></Col>
                            </Row>
                            <Row>
                                <p>Follow up attempts</p>
                            </Row>
                            <Row>
                                <Table bordered hover size="lg">
                                    <thead>
                                        <tr>
                                            <th>Follow Up Date</th>
                                            <th>Follow Up Reason</th>
                                            <th>Updated By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            candidateData && candidateData.follow_up_attempts.map((item) => {
                                                return (<tr>
                                                    <td>{commonDateFormat(item.follow_up_date)}</td>
                                                    <td>{item.follow_up_reason}</td>
                                                    <td>{item.upload_by}</td>
                                                </tr>);
                                            })
                                        }
                                        {
                                            addedAttempts && addedAttempts.map((item, index) => {
                                                return (<tr key={'attempts' + index}>
                                                    <td>
                                                        <DatePicker
                                                            onChange={(date) => {
                                                                setAddedAttempts((prevAttempts) =>
                                                                    prevAttempts.map((prevItem, i) =>
                                                                        i === index
                                                                            ? { ...prevItem, follow_up_date: date }
                                                                            : prevItem
                                                                    )
                                                                );
                                                            }}
                                                            selected={item.follow_up_date}
                                                            maxDate={new Date()}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control onChange={(e) => {
                                                            setAddedAttempts((prevAttempts) =>
                                                                prevAttempts.map((prevItem, i) =>
                                                                    i === index
                                                                        ? { ...prevItem, follow_up_reason: e.target.value }
                                                                        : prevItem
                                                                )
                                                            );
                                                        }} type="text" placeholder="Put Reason" />
                                                    </td>
                                                    <td>{item.upload_by}</td>
                                                </tr>);
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { setIsShowHistoryModel(false); }}>
                            Close
                        </Button>
                        {
                            addedAttempts && addedAttempts.length > 0 && (
                                <Button variant="primary" onClick={() => {
                                    updateCandidateProfile(interviewIdForQcRemark, {
                                        follow_up_attempts: [
                                            ...candidateData.follow_up_attempts,
                                            ...addedAttempts
                                        ]
                                    });
                                    setIsShowHistoryModel(false)
                                    setAddedAttempts([])
                                }}>
                                    Submit
                                </Button>
                            )
                        }

                    </Modal.Footer>
                </Modal>
            }

            {
                isShowVideoModal &&
                <Modal show={isShowVideoModal} centered>
                    <Modal.Header>
                        <Modal.Title>Recordings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            videoData.length > 0 && videoData.map((item) => (
                                <ListGroup className='mb-1'>
                                    <ListGroup.Item>Recording: <a rel="noreferrer" target="_blank" href={item.url}>{item.fileName}</a></ListGroup.Item>
                                    <ListGroup.Item>Transcript <a rel="noreferrer" target="_blank" href={item.transcriptUrl}>{item.fileName}</a>:</ListGroup.Item>
                                </ListGroup>
                            ))
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='btn btn-outline-primary' onClick={() => setIsShowVideoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

        </Fragment>
    )
}
export default ReportScreen;
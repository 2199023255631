import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { Carousel } from 'primereact/carousel';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Accordion, Alert, Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { BsFillSendFill } from "react-icons/bs";
import { FaDownload, FaEye, FaFileAlt, FaRegEdit } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { ReactTags } from "react-tag-autocomplete";
import default_profile_picture from "../../Assets/default_profile_picture.png";
import { API_URL, QC_STATUS_FAIL, QC_STATUS_PASS, allowedImageTypes, allowedResumeTypes, commonDateFormat, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, imageFileTypeErrorMessage, resumeFileTypeErrorMessage } from "../../common/constant";
import { INTERVIEWER, SUB_ADMIN, SUPER_ADMIN } from "../../common/roles";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./InterviewerProfileScreen.css";
import { Dialog } from 'primereact/dialog';

const InterviewerProfileScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [showLocationPreferences, setShowLocationPreferences] = useState(false);
    const [locationPreferences, setLocationPreferences] = useState([]);
    const [showWorkExperience, setShowWorkExperience] = useState(false);
    const [workExperience, setWorkExperience] = useState({});
    const [showEducations, setShowEducations] = useState(false);
    const [educations, setEducations] = useState({});
    const [showSkills, setShowSkills] = useState(false);
    const [skills, setSkills] = useState([]);

    const [showSecondarySkills, setShowSecondarySkills] = useState(false);

    const [showCertificates, setShowCertificates] = useState(false);
    const [certificates, setCertificates] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [showDocuments, setShowDocuments] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showLanguages, setShowLanguages] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [cities, setCities] = useState([]);
    const [interviewerProfile, setInterviewerProfile] = useState({});
    const [city, setCity] = useState("");
    const [interviewerState, setState] = useState("");
    const [interviewerCountry, setCountry] = useState("");
    const [workExperienceTitleError, setIsWorkExperienceTitleError] = useState("");
    const [workExperienceEmploymentTypeError, setIsWorkExperienceEmploymentTypeError] = useState("");
    const [workExperienceCompanyNameError, setIsWorkExperienceCompanyNameError] = useState("");
    const [workExperienceLocationError, setIsWorkExperienceLocationError] = useState("");
    const [workExperienceLocationTypeError, setIsWorkExperienceLocationTypeError] = useState("");
    const [workExperienceStartDateMonthError, setIsWorkExperienceStartDateMonthError] = useState("");
    const [workExperienceStartDateYearError, setIsWorkExperienceStartDateYearError] = useState("");
    const [workExperienceEndDateMonthError, setIsWorkExperienceEndDateMonthError] = useState("");
    const [workExperienceEndDateYearError, setIsWorkExperienceEndDateYearError] = useState("");
    const [educationSchoolNameError, setIsEducationsSchoolNameError] = useState("");
    const [educationGradeError, setIsEducationsGradeError] = useState("");
    const [educationLocationError, setIsEducationsLocationError] = useState("");
    const [educationsEducationError, setIsEducationsEducationError] = useState("");
    const [educationStartDateMonthError, setIsEducationsStartDateMonthError] = useState("");
    const [educationStartDateYearError, setIsEducationsStartDateYearError] = useState("");
    const [educationEndDateMonthError, setIsEducationsEndDateMonthError] = useState("");
    const [educationEndDateYearError, setIsEducationsEndDateYearError] = useState("");
    const [certificatesTitleError, setIsCertificatesTitleError] = useState("");
    const [certificatesFileError, setIsCertificatesFileError] = useState("");
    const [documentsTitleError, setIsDocumentsTitleError] = useState("");
    const [documentsFileError, setIsDocumentsFileError] = useState("");
    const [bankUserNameError, setIsBankUserNameError] = useState("");
    const [bankNameError, setIsBankNameError] = useState("");
    const [bankBranchNameError, setIsBankBranchNameError] = useState("");
    const [bankAccountNumberError, setIsBankAccountNumberError] = useState("");
    const [bankIFSCCodeError, setIsBankIFSCCodeError] = useState("");
    const [bankUPIIDError, setIsBankUPIIDError] = useState("");
    const [chargeError, setIsChargeError] = useState("");
    const [skillsOptions, setSkillsOptions] = useState([]);
    const [secondarySkills, setSecondarySkills] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);

    const [isShowResumeDialog, setIsShowResumeDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);

    const [languageOptions, setLanguageOptions] = useState([
        { id: 1, name: "Abkhaz" },
        { id: 2, name: "Afar" },
        { id: 3, name: "Afrikaans" },
        { id: 4, name: "Akan" },
        { id: 5, name: "Albanian" },
        { id: 6, name: "Amharic" },
        { id: 7, name: "Arabic" },
        { id: 8, name: "Aragonese" },
        { id: 9, name: "Armenian" },
        { id: 10, name: "Assamese" },
        { id: 11, name: "Avaric" },
        { id: 12, name: "Avestan" },
        { id: 13, name: "Aymara" },
        { id: 14, name: "Azerbaijani" },
        { id: 15, name: "Bambara" },
        { id: 16, name: "Bashkir" },
        { id: 17, name: "Basque" },
        { id: 18, name: "Belarusian" },
        { id: 19, name: "Bengali" },
        { id: 20, name: "Bihari" },
        { id: 21, name: "Bislama" },
        { id: 22, name: "Bosnian" },
        { id: 23, name: "Breton" },
        { id: 24, name: "Bulgarian" },
        { id: 25, name: "Burmese" },
        { id: 26, name: "Catalan; Valencian" },
        { id: 27, name: "Chamorro" },
        { id: 28, name: "Chechen" },
        { id: 29, name: "Chichewa; Chewa; Nyanja" },
        { id: 30, name: "Chinese" },
        { id: 31, name: "Chuvash" },
        { id: 32, name: "Cornish" },
        { id: 33, name: "Corsican" },
        { id: 34, name: "Cree" },
        { id: 35, name: "Croatian" },
        { id: 36, name: "Czech" },
        { id: 37, name: "Danish" },
        { id: 38, name: "Divehi; Dhivehi; Maldivian;" },
        { id: 39, name: "Dutch" },
        { id: 40, name: "English" },
        { id: 41, name: "Esperanto" },
        { id: 42, name: "Estonian" },
        { id: 43, name: "Ewe" },
        { id: 44, name: "Faroese" },
        { id: 45, name: "Fijian" },
        { id: 46, name: "Finnish" },
        { id: 47, name: "French" },
        { id: 48, name: "Fula; Fulah; Pulaar; Pular" },
        { id: 49, name: "Galician" },
        { id: 50, name: "Georgian" },
        { id: 51, name: "German" },
        { id: 52, name: "Greek, Modern" },
        { id: 53, name: "Guaraní" },
        { id: 54, name: "Gujarati" },
        { id: 55, name: "Haitian; Haitian Creole" },
        { id: 56, name: "Hausa" },
        { id: 57, name: "Hebrew (modern)" },
        { id: 58, name: "Herero" },
        { id: 59, name: "Hindi" },
        { id: 60, name: "Hiri Motu" },
        { id: 61, name: "Hungarian" },
        { id: 62, name: "Interlingua" },
        { id: 63, name: "Indonesian" },
        { id: 64, name: "Interlingue" },
        { id: 65, name: "Irish" },
        { id: 66, name: "Igbo" },
        { id: 67, name: "Inupiaq" },
        { id: 68, name: "Ido" },
        { id: 69, name: "Icelandic" },
        { id: 70, name: "Italian" },
        { id: 71, name: "Inuktitut" },
        { id: 72, name: "Japanese" },
        { id: 73, name: "Javanese" },
        { id: 74, name: "Kalaallisut, Greenlandic" },
        { id: 75, name: "Kannada" },
        { id: 76, name: "Kanuri" },
        { id: 77, name: "Kashmiri" },
        { id: 78, name: "Kazakh" },
        { id: 79, name: "Khmer" },
        { id: 80, name: "Kikuyu, Gikuyu" },
        { id: 81, name: "Kinyarwanda" },
        { id: 82, name: "Kirghiz, Kyrgyz" },
        { id: 83, name: "Komi" },
        { id: 84, name: "Kongo" },
        { id: 85, name: "Korean" },
        { id: 86, name: "Kurdish" },
        { id: 87, name: "Kwanyama, Kuanyama" },
        { id: 88, name: "Latin" },
        { id: 89, name: "Luxembourgish, Letzeburgesch" },
        { id: 90, name: "Luganda" },
        { id: 91, name: "Limburgish, Limburgan, Limburger" },
        { id: 92, name: "Lingala" },
        { id: 93, name: "Lao" },
        { id: 94, name: "Lithuanian" },
        { id: 95, name: "Luba-Katanga" },
        { id: 96, name: "Latvian" },
        { id: 97, name: "Manx" },
        { id: 98, name: "Macedonian" },
        { id: 99, name: "Malagasy" },
        { id: 100, name: "Malay" },
        { id: 101, name: "Malayalam" },
        { id: 102, name: "Maltese" },
        { id: 103, name: "Māori" },
        { id: 104, name: "Marathi (Marāṭhī)" },
        { id: 105, name: "Marshallese" },
        { id: 106, name: "Mongolian" },
        { id: 107, name: "Nauru" },
        { id: 108, name: "Navajo, Navaho" },
        { id: 109, name: "Norwegian Bokmål" },
        { id: 110, name: "North Ndebele" },
        { id: 111, name: "Nepali" },
        { id: 112, name: "Ndonga" },
        { id: 113, name: "Norwegian Nynorsk" },
        { id: 114, name: "Norwegian" },
        { id: 115, name: "Nuosu" },
        { id: 116, name: "South Ndebele" },
        { id: 117, name: "Occitan" },
        { id: 118, name: "Ojibwe, Ojibwa" },
        { id: 119, name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic" },
        { id: 120, name: "Oromo" },
        { id: 121, name: "Oriya" },
        { id: 122, name: "Ossetian, Ossetic" },
        { id: 123, name: "Panjabi, Punjabi" },
        { id: 124, name: "Pāli" },
        { id: 125, name: "Persian" },
        { id: 126, name: "Polish" },
        { id: 127, name: "Pashto, Pushto" },
        { id: 128, name: "Portuguese" },
        { id: 129, name: "Quechua" },
        { id: 130, name: "Romansh" },
        { id: 131, name: "Kirundi" },
        { id: 132, name: "Romanian, Moldavian, Moldovan" },
        { id: 133, name: "Russian" },
        { id: 134, name: "Sanskrit (Saṁskṛta)" },
        { id: 135, name: "Sardinian" },
        { id: 136, name: "Sindhi" },
        { id: 137, name: "Northern Sami" },
        { id: 138, name: "Samoan" },
        { id: 139, name: "Sango" },
        { id: 140, name: "Serbian" },
        { id: 141, name: "Scottish Gaelic; Gaelic" },
        { id: 142, name: "Shona" },
        { id: 143, name: "Sinhala, Sinhalese" },
        { id: 144, name: "Slovak" },
        { id: 145, name: "Slovene" },
        { id: 146, name: "Somali" },
        { id: 147, name: "Southern Sotho" },
        { id: 148, name: "Spanish; Castilian" },
        { id: 149, name: "Sundanese" },
        { id: 150, name: "Swahili" },
        { id: 151, name: "Swati" },
        { id: 152, name: "Swedish" },
        { id: 153, name: "Tamil" },
        { id: 154, name: "Telugu" },
        { id: 155, name: "Tajik" },
        { id: 156, name: "Thai" },
        { id: 157, name: "Tigrinya" },
        { id: 158, name: "Tibetan Standard, Tibetan, Central" },
        { id: 159, name: "Turkmen" },
        { id: 160, name: "Tagalog" },
        { id: 161, name: "Tswana" },
        { id: 162, name: "Tonga (Tonga Islands)" },
        { id: 163, name: "Turkish" },
        { id: 164, name: "Tsonga" },
        { id: 165, name: "Tatar" },
        { id: 166, name: "Twi" },
        { id: 167, name: "Tahitian" },
        { id: 168, name: "Uighur, Uyghur" },
        { id: 169, name: "Ukrainian" },
        { id: 170, name: "Urdu" },
        { id: 171, name: "Uzbek" },
        { id: 172, name: "Venda" },
        { id: 173, name: "Vietnamese" },
        { id: 174, name: "Volapük" },
        { id: 175, name: "Walloon" },
        { id: 176, name: "Welsh" },
        { id: 177, name: "Wolof" },
        { id: 178, name: "Western Frisian" },
        { id: 179, name: "Xhosa" },
        { id: 180, name: "Yiddish" },
        { id: 181, name: "Yoruba" },
        { id: 182, name: "Zhuang, Chuang" }
    ]);

    const toast = useRef(null);

    const [monthNames, setMonthNames] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);
    const [currentMonth, setCurrentMonth] = useState('January');
    const [currentYear, setCurrentYear] = useState(new Date());
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 3,
            numScroll: 1
        }
    ];

    const handleItemClick = (item) => {
        // Handle the clicked item here
        console.log('Clicked Item:', item);
        // setClickedItem(item);
        setCurrentMonth(item)
    };

    const carouselItems = (month) => {
        return (
            <div style={{
                background: currentMonth == month ? 'black' : 'pink',
                color: currentMonth == month ? 'white' : 'black',
                textAlign: "center",
                borderRadius: "6px",
                marginRight: "14px",
                fontSize: '500',
                cursor: 'pointer'
            }} onClick={() => handleItemClick(month)}>{month}</div>
        );
    };

    const months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
    ];

    const [yearList, setYearList] = useState([]);

    const getYearList = (startYear) => {
        var currentYear = new Date().getFullYear(), years = [];
        startYear = 1980;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }
        setYearList(years)
    }

    const [interviewState, setInterviewState] = useState([
        {
            scheduled_interview_count: 0,
            conducted__interview_count: 0,
            selected_interview_count: 0,
            rejected_interview_count: 0,
            no_show_by_interviewer_count: 0,
            no_show_by_candidate_count: 0
        }
    ]);

    const { id } = useParams();

    const changeWorkExperienceHandler = (e) => {
        const { name, value } = e.target;
        if (name === "is_currently_working") {
            setWorkExperience({
                ...workExperience,
                is_currently_working: e.target.checked,
                end_date_month: "",
                end_date_year: ""
            });
            setIsWorkExperienceEndDateMonthError("");
            setIsWorkExperienceEndDateYearError("");
        } else {
            setWorkExperience({
                ...workExperience,
                [name]: value,
            });
        }
    };

    const changeEducationHandler = (e) => {
        const { name, value } = e.target;
        setEducations({
            ...educations,
            [name]: value,
        });
    };

    const changeBankDetailsHandler = (e) => {
        const { name, value } = e.target;
        setBankDetails({
            ...bankDetails,
            [name]: value,
        });
    };

    const changeCertificatesHandler = (e) => {
        const { name, value } = e.target;
        setCertificates({
            ...certificates,
            [name]: value,
        });
    };

    const changeDocumentsHandler = (e) => {
        const { name, value } = e.target;
        setDocuments({
            ...documents,
            [name]: value,
        });
    };

    const [profileDetail, setProfileDetail] = useState({});

    useEffect(() => {
        if (!_.isUndefined(interviewerProfile) && !_.isNull(interviewerProfile) && _.isEmpty(interviewerProfile) && !_.isUndefined(id) && !_.isNull(id) && (id !== '')) {
            getInterviewerProfile();
            getAllCity();
            getYearList();
            getSkills();
            getCountryList();
            getPositions();
        }
    }, [interviewerProfile]);

    useEffect(() => {
        if (currentMonth && currentYear) {
            getInterviewerStates();
        }
    }, [currentMonth, currentYear])

    const getInterviewerStates = () => {
        setIsLoading(true)
        const index = monthNames.indexOf(currentMonth);
        fetch(`${API_URL}api/getInterviewerStates/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                currentMonth: (index + 1),
                currentYear: currentYear.getFullYear()
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setInterviewState([{
                    scheduled_interview_count: response.data.scheduled_interview_count,
                    conducted__interview_count: response.data.conducted__interview_count,
                    selected_interview_count: response.data.selected_interview_count,
                    rejected_interview_count: response.data.rejected_interview_count,
                    no_show_by_interviewer_count: response.data.no_show_by_interviewer_count,
                    no_show_by_candidate_count: response.data.no_show_by_candidate_count
                }])
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                var allSkills = [];
                response.data.map((item) => {
                    allSkills.push({
                        name: item.name,
                        value: item.name,
                        label: item.name
                    })
                })
                setSkillsOptions(allSkills)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getAllCity = () => {
        fetch(`${API_URL}api/cities`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && (response.data.length > 0)) {
                    let cities = [];
                    _.map(response.data, (city, index) => {
                        cities.push({ id: city.city_id, name: city.name });
                    })
                    setCities(cities);
                }
            })
            .catch((err) => {
                setCities("");
            });
    }

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                const transformedOptions = response.data.map(option => ({
                    name: option.name,
                    value: option.name,
                    label: option.name
                }));
                setDesignationOptions(transformedOptions);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const getInterviewerProfile = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/profileByID/${id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
                    setInterviewerProfile(response.data);
                    if (!_.isUndefined(response.data.city_id) && !_.isNull(response.data.city_id) && (response.data.city_id !== '')) {
                        getCityName(response.data.city_id);
                    }
                    if (!_.isUndefined(response.data.state_id) && !_.isNull(response.data.state_id) && (response.data.state_id !== '')) {
                        getStateName(response.data.state_id);
                    }
                    if (!_.isUndefined(response.data.country_id) && !_.isNull(response.data.country_id) && (response.data.country_id !== '')) {
                        getCountry(response.data.country_id);
                    }

                    setProfileDetail({
                        email: response.data.email,
                        designation: response.data.designation,
                        profile_image: response.data.profile_image,
                        naukari_profile: response.data.naukari_profile,
                        linkedin: response.data.linkedin,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        mobile_number: response.data.mobile_number,
                        resume: response.data.resume
                    })

                    setBankDetails({
                        bank_user_name: response.data.bank_user_name,
                        bank_name: response.data.bank_name,
                        bank_branch_name: response.data.bank_branch_name,
                        bank_account_number: response.data.bank_account_number,
                        bank_ifsc_code: response.data.bank_ifsc_code,
                        bank_upi_id: response.data.bank_upi_id,
                        charge: response.data.charge
                    })
                    var allSelectedPrimarySkills = [];
                    response.data.primary_skill.map((item) => {
                        allSelectedPrimarySkills.push({
                            name: item,
                            value: item,
                            label: item
                        })
                    })
                    setSkills(allSelectedPrimarySkills)
                    var allSelectedSecondaySkills = [];
                    response.data.secondary_skill.map((item) => {
                        allSelectedSecondaySkills.push({
                            name: item,
                            value: item,
                            label: item
                        })
                    })
                    setSecondarySkills(allSelectedSecondaySkills)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                setInterviewerProfile({});
                setIsLoading(false)
            });
    }

    const getCityName = (cityBYID) => {
        fetch(`${API_URL}api/cities/cityBYID/${cityBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCity(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const getStateName = (stateBYID) => {
        fetch(`${API_URL}api/states/stateBYID/${stateBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setState(response.data.name);
                }
            })
            .catch((err) => {
                setCity("");
            });
    }

    const [countryOptions, setCountryOptions] = useState([]);

    const getCountryList = () => {
        fetch(`${API_URL}api/countries`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    const transformedOptions = response.data.map(option => ({
                        id: option.country_id,
                        name: option.name
                    }));
                    setCountryOptions(transformedOptions);
                }
            })
            .catch((err) => {
                setCountryOptions([]);
            });
    }

    const getCountry = (countryBYID) => {
        fetch(`${API_URL}api/countries/${countryBYID}`)
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data) && !_.isUndefined(response.data.name) && !_.isNull(response.data.name) && (response.data.name !== '')) {
                    setCountry(response.data.name);
                }
            })
            .catch((err) => {
                setCountry({});
            });
    }

    const onRemoveLocationPreferences = (selectedList, removedItem) => {
        setLocationPreferences(selectedList);

    }
    const onSelectLocationPreferences = (selectedList, selectedItem) => {
        setLocationPreferences(selectedList);
    }

    const submitLocationPreferences = () => {
        if (locationPreferences.length > 0) {
            setIsLoading(true)
            let params = {
                location_preferences: locationPreferences
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                    setIsLoading(false)
                    setShowLocationPreferences(false);
                    getInterviewerProfile();
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                    setShowLocationPreferences(false)
                });
        }
    }

    const onRemoveLanguages = (selectedList, removedItem) => {
        setLanguages(selectedList);
    }
    const onSelectLanguages = (selectedList, selectedItem) => {
        setLanguages(selectedList);
    }

    const submitLanguages = () => {
        if (languages.length > 0) {
            setIsLoading(true)
            let params = {
                languages: languages
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                        setShowLanguages(false)
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const onRemoveSkills = (selectedList, removedItem) => {
        setSkills(selectedList);
    }
    const onSelectSkills = (selectedList, selectedItem) => {
        setSkills(selectedList);
    }
    const submitSkills = () => {
        if (skills.length > 0) {
            setIsLoading(true)
            var allPrimarySkills = [];
            skills.map((item) => {
                allPrimarySkills.push(item.value)
            })
            let params = {
                primary_skill: allPrimarySkills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowSkills(false)
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const submitSecondaySkills = () => {
        if (secondarySkills.length > 0) {
            setIsLoading(true)
            var allSecondarySkills = [];
            secondarySkills.map((item) => {
                allSecondarySkills.push(item.value)
            })
            let params = {
                secondary_skill: allSecondarySkills
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        setShowSecondarySkills(false)
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                });
        }
    }
    const submitWorkExperience = (updatedEducations = [], isUpdate = false) => {
        if (isUpdate || validateWorkExperienceForm()) {
            setIsLoading(true)
            let workExperiencesRequest = [];
            if (isUpdate) {
                workExperiencesRequest = updatedEducations;
            } else {
                workExperiencesRequest = interviewerProfile.work_experience;
                workExperiencesRequest.push(workExperience);
            }
            let params = {
                work_experience: workExperiencesRequest
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                    setShowWorkExperience(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                    setShowWorkExperience(false)
                });
        }
    }

    const validateWorkExperienceForm = () => {
        if (_.isUndefined(workExperience.title) || _.isNull(workExperience.title) || (workExperience.title === '') || (workExperience.title.trim() === '')) {
            setIsWorkExperienceTitleError("Experience title is required!");
            return false;
        }
        else {
            setIsWorkExperienceTitleError("");
        }
        if (_.isUndefined(workExperience.employment_type) || _.isNull(workExperience.employment_type) || (workExperience.employment_type === '') || (workExperience.employment_type.trim() === '')) {
            setIsWorkExperienceEmploymentTypeError("Employment type is required!");
            return false;
        }
        else {
            setIsWorkExperienceEmploymentTypeError("");
        }
        if (_.isUndefined(workExperience.company_name) || _.isNull(workExperience.company_name) || (workExperience.company_name === '') || (workExperience.company_name.trim() === '')) {
            setIsWorkExperienceCompanyNameError("Company name is required!");
            return false;
        }
        else {
            setIsWorkExperienceCompanyNameError("");
        }
        if (_.isUndefined(workExperience.location) || _.isNull(workExperience.location) || (workExperience.location === '') || (workExperience.location.trim() === '')) {
            setIsWorkExperienceLocationError("Location is required!");
            return false;
        }
        else {
            setIsWorkExperienceLocationError("");
        }
        if (_.isUndefined(workExperience.location_type) || _.isNull(workExperience.location_type) || (workExperience.location_type === '') || (workExperience.location_type.trim() === '')) {
            setIsWorkExperienceLocationTypeError("Location type is required!");
            return false;
        }
        else {
            setIsWorkExperienceLocationTypeError("");
        }
        if (_.isUndefined(workExperience.start_date_month) || _.isNull(workExperience.start_date_month) || (workExperience.start_date_month === '') || (workExperience.start_date_month.trim() === '')) {
            setIsWorkExperienceStartDateMonthError("Start Date Month is required!");
            return false;
        }
        else {
            setIsWorkExperienceStartDateMonthError("");
        }
        if (_.isUndefined(workExperience.start_date_year) || _.isNull(workExperience.start_date_year) || (workExperience.start_date_year === '') || (workExperience.start_date_year.trim() === '')) {
            setIsWorkExperienceStartDateYearError("Start Date Year is required!");
            return false;
        }
        else {
            setIsWorkExperienceStartDateYearError("");
        }
        if (_.isUndefined(workExperience.is_currently_working) || _.isNull(workExperience.is_currently_working) || (workExperience.is_currently_working === '') || (workExperience.is_currently_working === false)) {
            if (_.isUndefined(workExperience.end_date_month) || _.isNull(workExperience.end_date_month) || (workExperience.end_date_month === '') || (workExperience.end_date_month.trim() === '')) {
                setIsWorkExperienceEndDateMonthError("End Date Month is required!");
                return false;
            }
            else {
                setIsWorkExperienceEndDateMonthError("");
            }
            if (_.isUndefined(workExperience.end_date_year) || _.isNull(workExperience.end_date_year) || (workExperience.end_date_year === '') || (workExperience.end_date_year.trim() === '')) {
                setIsWorkExperienceEndDateYearError("End Date Year is required!");
                return false;
            }
            else {
                if (workExperience.end_date_year < workExperience.start_date_year) {
                    setIsWorkExperienceEndDateYearError("End Date Year must be grater than Start Date Year!");
                    return false;
                } else {
                    if (workExperience.end_date_year == workExperience.start_date_year && workExperience.end_date_month <= workExperience.start_date_month) {
                        setIsWorkExperienceEndDateMonthError("End Date Month must be grater than Start Date Month!");
                        return false;
                    } else {
                        setIsWorkExperienceEndDateYearError("");
                        return true;
                    }
                }
            }
        } else {
            return true;
        }
    };

    const submitEducations = (updatedEducations = [], isUpdate = false) => {
        if (isUpdate || validateEducationForm()) {
            setIsLoading(true)
            let newEducations = [];
            if (isUpdate) {
                newEducations = updatedEducations;
            } else {
                newEducations = interviewerProfile.educations;
                newEducations.push(educations);
            }
            let params = {
                educations: newEducations
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        setShowEducations(false);
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
                    }
                });
        }
    }

    const validateEducationForm = () => {
        if (_.isUndefined(educations.school_name) || _.isNull(educations.school_name) || (educations.school_name === '') || (educations.school_name.trim() === '')) {
            setIsEducationsSchoolNameError("School name is required!");
            return false;
        }
        else {
            setIsEducationsSchoolNameError("");
        }
        if (_.isUndefined(educations.grade) || _.isNull(educations.grade) || (educations.grade === '') || (educations.grade.trim() === '')) {
            setIsEducationsGradeError("Grade is required!");
            return false;
        }
        else {
            setIsEducationsGradeError("");
        }
        if (_.isUndefined(educations.location) || _.isNull(educations.location) || (educations.location === '') || (educations.location.trim() === '')) {
            setIsEducationsLocationError("Location is required!");
            return false;
        }
        else {
            setIsEducationsLocationError("");
        }
        if (_.isUndefined(educations.education) || _.isNull(educations.education) || (educations.education === '') || (educations.education.trim() === '')) {
            setIsEducationsEducationError("Education is required!");
            return false;
        }
        else {
            setIsEducationsEducationError("");
        }
        if (_.isUndefined(educations.start_date_month) || _.isNull(educations.start_date_month) || (educations.start_date_month === '') || (educations.start_date_month.trim() === '')) {
            setIsEducationsStartDateMonthError("Start Date Month is required!");
            return false;
        }
        else {
            setIsEducationsStartDateMonthError("");
        }
        if (_.isUndefined(educations.start_date_year) || _.isNull(educations.start_date_year) || (educations.start_date_year === '') || (educations.start_date_year.trim() === '')) {
            setIsEducationsStartDateYearError("Start Date Year is required!");
            return false;
        }
        else {
            setIsEducationsStartDateYearError("");
        }
        if (_.isUndefined(educations.end_date_month) || _.isNull(educations.end_date_month) || (educations.end_date_month === '') || (educations.end_date_month.trim() === '')) {
            setIsEducationsEndDateMonthError("End Date Month is required!");
            return false;
        }
        else {
            setIsEducationsEndDateMonthError("");
        }
        if (_.isUndefined(educations.end_date_year) || _.isNull(educations.end_date_year) || (educations.end_date_year === '') || (educations.end_date_year.trim() === '')) {
            setIsEducationsEndDateYearError("End Date Year is required!");
            return false;
        }
        else {
            if (educations.end_date_year < educations.start_date_year) {
                setIsEducationsEndDateYearError("End Date Year must be grater than Start Date Year!");
                return false;
            } else {
                if (educations.end_date_year == educations.start_date_year && educations.end_date_month <= educations.start_date_month) {
                    setIsEducationsEndDateMonthError("End Date Month must be grater than Start Date Month!");
                    return false;
                } else {
                    setIsEducationsEndDateYearError("");
                }
            }
        }

        return true;
    };

    const submitCertificates = (updatedItems = [], isUpdate = false) => {
        if (isUpdate || validateCertificatesForm()) {
            setIsLoading(true)
            let newCertificates = [];
            if (isUpdate) {
                newCertificates = updatedItems;
            } else {
                newCertificates = interviewerProfile.certificates;
                newCertificates.push(certificates);
            }
            let params = {
                certificates: newCertificates
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    setIsLoading(false)
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    setShowCertificates(false)
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                    setShowCertificates(false)
                    setIsLoading(false)
                });
        }
    }
    const checkedValidateCertificatesForm = () => {
        if (_.isUndefined(certificates.title) || _.isNull(certificates.title) || (certificates.title === '') || (certificates.title.trim() === '')) {
            return false;
        }
        if (_.isUndefined(certificates.certificate_file) || _.isNull(certificates.certificate_file) || (certificates.certificate_file === '') || (certificates.certificate_file.trim() === '')) {
            return false;
        }
        return true;
    };
    const validateCertificatesForm = () => {
        if (_.isUndefined(certificates.title) || _.isNull(certificates.title) || (certificates.title === '') || (certificates.title.trim() === '')) {
            setIsCertificatesTitleError("Title is required!");
            return false;
        }
        else {
            setIsCertificatesTitleError("");
        }
        if (_.isUndefined(certificates.certificate_file) || _.isNull(certificates.certificate_file) || (certificates.certificate_file === '') || (certificates.certificate_file.trim() === '')) {
            setIsCertificatesFileError("Certificate File is required!");
            return false;
        }
        else {
            setIsCertificatesFileError("");
            return true;
        }
    };
    const changeUploadCertificatesHandler = (event) => {
        setIsLoading(true)
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setCertificates({
                        ...certificates,
                        certificate_file: response.filepath,
                    });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                }
            })
            .catch((err) => {
                console.log("error", err);
                toast.current.show({ severity: 'success', summary: 'Success', detail: err.message, life: 3000 });
                setIsLoading(false)
            });
    }

    const submitDocuments = (updatedEducations = [], isUpdate = false) => {
        if (isUpdate || validateDocumentsForm()) {
            setIsLoading(true)
            let newDocuments = [];
            if (isUpdate) {
                newDocuments = updatedEducations;
            } else {
                newDocuments = interviewerProfile.documents;
                newDocuments.push(documents);
            }
            let params = {
                documents: newDocuments
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    setShowDocuments(false)
                    setIsLoading(false)
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                    setShowDocuments(false)
                    setIsLoading(false)
                });
        }
    }

    const validateDocumentsForm = () => {
        if (_.isUndefined(documents.title) || _.isNull(documents.title) || (documents.title === '') || (documents.title.trim() === '')) {
            setIsDocumentsTitleError("Title is required!");
            return false;
        }
        else {
            setIsDocumentsTitleError("");
        }
        if (_.isUndefined(documents.document_file) || _.isNull(documents.document_file) || (documents.document_file === '') || (documents.document_file.trim() === '')) {
            setIsDocumentsFileError("Document File is required!");
            return false;
        }
        else {
            setIsDocumentsFileError("");
            return true;
        }
    };

    const submitBankDetails = () => {
        validateBankDetailsForm();
        if (checkedValidateBankDetailsForm()) {
            setIsLoading(true)
            let params = {
                bank_user_name: bankDetails.bank_user_name,
                bank_name: bankDetails.bank_name,
                bank_branch_name: bankDetails.bank_branch_name,
                bank_account_number: bankDetails.bank_account_number,
                bank_ifsc_code: bankDetails.bank_ifsc_code,
                bank_upi_id: bankDetails.bank_upi_id,
                charge: bankDetails.charge
            }
            fetch(`${API_URL}api/updateProfileByID/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify(params)
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.user) && !_.isNull(response.user) && !_.isEmpty(response.user) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                        getInterviewerProfile();
                    }
                    else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    }
                    setShowBankDetails(false);
                    getInterviewerProfile();
                    setIsLoading(false)
                })
                .catch((error) => {
                    if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: error.message, life: 3000 });
                    }
                    setIsLoading(false)
                });
        }
    }
    const checkedValidateBankDetailsForm = () => {
        if (_.isUndefined(bankDetails.bank_user_name) || _.isNull(bankDetails.bank_user_name) || (bankDetails.bank_user_name === '') || (bankDetails.bank_user_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_name) || _.isNull(bankDetails.bank_name) || (bankDetails.bank_name === '') || (bankDetails.bank_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_branch_name) || _.isNull(bankDetails.bank_branch_name) || (bankDetails.bank_branch_name === '') || (bankDetails.bank_branch_name.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_account_number) || _.isNull(bankDetails.bank_account_number) || (bankDetails.bank_account_number === '') || (bankDetails.bank_account_number === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_ifsc_code) || _.isNull(bankDetails.bank_ifsc_code) || (bankDetails.bank_ifsc_code === '') || (bankDetails.bank_ifsc_code.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.bank_upi_id) || _.isNull(bankDetails.bank_upi_id) || (bankDetails.bank_upi_id === '') || (bankDetails.bank_upi_id.trim() === '')) {
            return false;
        }
        if (_.isUndefined(bankDetails.charge) || _.isNull(bankDetails.charge) || (bankDetails.charge === '')) {
            return false;
        }
        return true;
    };
    const validateBankDetailsForm = () => {
        if (_.isUndefined(bankDetails.bank_user_name) || _.isNull(bankDetails.bank_user_name) || (bankDetails.bank_user_name === '') || (bankDetails.bank_user_name.trim() === '')) {
            setIsBankUserNameError("Account Holder Name is required!");
        }
        else {
            setIsBankUserNameError("");
        }
        if (_.isUndefined(bankDetails.bank_name) || _.isNull(bankDetails.bank_name) || (bankDetails.bank_name === '') || (bankDetails.bank_name.trim() === '')) {
            setIsBankNameError("Bank Name is required!");
        }
        else {
            setIsBankNameError("");
        }
        if (_.isUndefined(bankDetails.bank_branch_name) || _.isNull(bankDetails.bank_branch_name) || (bankDetails.bank_branch_name === '') || (bankDetails.bank_branch_name.trim() === '')) {
            setIsBankBranchNameError("Bank Branch Name is required!");
        }
        else {
            setIsBankBranchNameError("");
        }
        if (_.isUndefined(bankDetails.bank_account_number) || _.isNull(bankDetails.bank_account_number) || (bankDetails.bank_account_number === '') || (bankDetails.bank_account_number === '')) {
            setIsBankAccountNumberError("Bank Account Number is required!");
        }
        else {
            setIsBankAccountNumberError("");
        }
        if (_.isUndefined(bankDetails.bank_ifsc_code) || _.isNull(bankDetails.bank_ifsc_code) || (bankDetails.bank_ifsc_code === '') || (bankDetails.bank_ifsc_code.trim() === '')) {
            setIsBankIFSCCodeError("Bank IFSC code is required!");
        }
        else {
            setIsBankIFSCCodeError("");
        }
        if (_.isUndefined(bankDetails.bank_upi_id) || _.isNull(bankDetails.bank_upi_id) || (bankDetails.bank_upi_id === '') || (bankDetails.bank_upi_id.trim() === '')) {
            setIsBankUPIIDError("Bank UPI ID is required!");
        }
        else {
            setIsBankUPIIDError("");
        }
        if (_.isUndefined(bankDetails.charge) || _.isNull(bankDetails.charge) || (bankDetails.charge === '')) {
            setIsChargeError("Charge is required!");
        }
        else {
            setIsChargeError("");
        }
    };
    const changeUploadDocumentsHandler = (event) => {
        setIsLoading(true)
        const data = new FormData();
        data.append('file', event.target.files[0]);
        fetch(`${API_URL}api/uploadFiles`, {
            method: 'POST',
            body: data
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setDocuments({
                        ...documents,
                        document_file: response.filepath,
                    });
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("error", err);
                toast.current.show({ severity: 'success', summary: 'Success', detail: err.message, life: 3000 });
                setIsLoading(false)
            });
    }
    const downloadFile = async (url) => {
        await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = Date.now();
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    const [isDetailEdit, setIsDetailEdit] = useState(false);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "mobile_number") {
            const input = e.target.value;
            const numericInput = input.replace(/\D/g, '');
            const truncatedInput = numericInput.slice(0, 10);
            setProfileDetail({
                ...profileDetail,
                [name]: truncatedInput,
            });
        } else {
            setProfileDetail({
                ...profileDetail,
                [e.target.name]: value,
            });
        }

    }

    const fileUploadRef = useRef(null);
    const [fileName, setFileName] = useState(null);

    const uploadFileHandler = async (event) => {
        if (allowedImageTypes.includes(event.target.files[0].type)) {
            setFileName(event.target.files[0].name)
            setIsLoading(true)
            const file = event.target.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
                .then((response) => response.json())
                .then((response) => {
                    profileDetail.profile_image = response.filepath;
                    clearFile(fileUploadRef);
                    const event = new CustomEvent('profileImageUpdated', {
                        detail: { profileImage: response.filepath }
                    });
                    window.dispatchEvent(event);
                    updateProfile();
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("error", err);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    clearFile(fileUploadRef);
                    setIsLoading(false)
                });
        } else {
            clearFile(fileUploadRef)
            toast.current.show({ severity: 'error', summary: 'Error', detail: imageFileTypeErrorMessage, life: 3000 });
        }
    };

    const clearFile = (fileInput) => {
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset file input
        }
        setFileName(null)
    }

    const resumeFileUploadRef = useRef(null);

    const uploadResumeFileHandler = async (event) => {
        if (allowedResumeTypes.includes(event.target.files[0].type)) {
            setIsLoading(true)
            const file = event.target.files[0];
            const data = new FormData();
            data.append('file', file);
            fetch(`${API_URL}api/uploadFiles`, {
                method: 'POST',
                body: data
            })
                .then((response) => response.json())
                .then((response) => {
                    profileDetail.resume = response.filepath;
                    clearFile(resumeFileUploadRef);
                    updateProfile();
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("error", err);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    clearFile(resumeFileUploadRef);
                    setIsLoading(false)
                });
        } else {
            clearFile(resumeFileUploadRef)
            toast.current.show({ severity: 'error', summary: 'Error', detail: resumeFileTypeErrorMessage, life: 3000 });
        }
    };

    const updateProfile = () => {
        setIsLoading(true);
        fetch(`${API_URL}api/updateProfileByID/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(profileDetail)
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false);
                setIsDetailEdit(false);
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    if (interviewerProfile.resume === profileDetail.resume) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                    } else {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: "Resume updated successfully!", life: 3000 });
                    }
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
                }
                getInterviewerProfile();
            })
            .catch((error) => {
                setIsDetailEdit(false);
                setIsLoading(false);
            });
    }

    const onRemovePrimarySkills = (selectedList) => {
        setSkills(skills.filter((_, i) => i !== selectedList))
    }
    const onSelectPrimarySkills = (selectedList) => {
        setSkills([...skills, selectedList]);
    }

    const onRemoveSecondarySkills = (selectedList) => {
        setSecondarySkills(secondarySkills.filter((_, i) => i !== selectedList))
    }
    const onSelectSecondarSkills = (selectedList) => {
        setSecondarySkills([...secondarySkills, selectedList]);
    }

    const [educationEditItemIndex, setEducationEditItemIndex] = useState(-1)
    const [educationDeleteId, setEducationDeleteId] = useState(-1)

    useEffect(() => {
        if (educationDeleteId != -1) {
            deleteEducationConfirm()
        }
    }, [educationDeleteId])

    const educationDeleteAccept = () => {
        const updatedEducations = [...interviewerProfile.educations];
        updatedEducations.splice(educationDeleteId, 1);
        setInterviewerProfile(prevProfile => {
            return {
                ...prevProfile,
                educations: updatedEducations,
            };
        });
        submitEducations(updatedEducations, true)
    }

    const deleteEducationConfirm = () => {
        confirmDialog({
            message: 'Do you want to remove the education?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            onHide: () => {
                setEducationDeleteId(-1)
            },
            accept: educationDeleteAccept,
            reject: () => {
                setEducationDeleteId(-1)
            }
        });
    };

    const [workExperienceEditItemIndex, setWorkExperienceEditItemIndex] = useState(-1)
    const [workExperienceDeleteId, setWorkExperienceDeleteId] = useState(-1)

    useEffect(() => {
        if (workExperienceDeleteId != -1) {
            deleteWorkExperienceDeleteIdConfirm()
        }
    }, [workExperienceDeleteId])

    const workExperienceDeleteIdDeleteAccept = () => {
        const updatedEducations = [...interviewerProfile.work_experience];
        updatedEducations.splice(workExperienceDeleteId, 1);
        setInterviewerProfile(prevProfile => {
            return {
                ...prevProfile,
                work_experience: updatedEducations,
            };
        });
        submitWorkExperience(updatedEducations, true)
    }

    const deleteWorkExperienceDeleteIdConfirm = () => {
        confirmDialog({
            message: 'Do you want to remove the work experience?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            onHide: () => {
                setWorkExperienceDeleteId(-1)
            },
            accept: workExperienceDeleteIdDeleteAccept,
            reject: () => {
                setWorkExperienceDeleteId(-1)
            }
        });
    };

    const [documentDeleteId, setDocumentDeleteId] = useState(-1)

    useEffect(() => {
        if (documentDeleteId != -1) {
            deleteDocumenteDeleteIdConfirm()
        }
    }, [documentDeleteId])

    const documentDeleteIdDeleteAccept = () => {
        const updatedEducations = [...interviewerProfile.documents];
        updatedEducations.splice(documentDeleteId, 1);
        setInterviewerProfile(prevProfile => {
            return {
                ...prevProfile,
                documents: updatedEducations,
            };
        });
        submitDocuments(updatedEducations, true)
    }

    const deleteDocumenteDeleteIdConfirm = () => {
        confirmDialog({
            message: 'Do you want to remove the document?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            onHide: () => {
                setDocumentDeleteId(-1)
            },
            accept: documentDeleteIdDeleteAccept,
            reject: () => {
                setDocumentDeleteId(-1)
            }
        });
    };

    const [certificateDeleteId, setCertificateDeleteId] = useState(-1)

    useEffect(() => {
        if (certificateDeleteId != -1) {
            deleteCertificateDeleteIdConfirm()
        }
    }, [certificateDeleteId])

    const certificateDeleteIdDeleteAccept = () => {
        const updatedEducations = [...interviewerProfile.certificates];
        updatedEducations.splice(certificateDeleteId, 1);
        setInterviewerProfile(prevProfile => {
            return {
                ...prevProfile,
                certificates: updatedEducations,
            };
        });
        submitCertificates(updatedEducations, true)
    }

    const deleteCertificateDeleteIdConfirm = () => {
        confirmDialog({
            message: 'Do you want to remove the certificate?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            onHide: () => {
                setCertificateDeleteId(-1)
            },
            accept: certificateDeleteIdDeleteAccept,
            reject: () => {
                setCertificateDeleteId(-1)
            }
        });
    };

    const [csvContent, setCsvContent] = useState('');
    const [showInterviewReportModal, setShowInterviewReportModal] = useState(false);

    const convertToCsv = (data) => {
        const header = "Candidate name, Interviewer E-mail, Title, Company name, Schedule date, Scheduled By, Rating, Verdit, Duration, QC status, QC Feedback, Location, Amount, QC deduction, Final Amount";
        const rowData = [];
        data.map((item) => {
            const datum = {
                "candidate_name": item.candidate_name,
                "interview_email": item.interview_email,
                "title": item.job_title,
                "company_name": item.company_name,
                "schedule_date": item.scheduled_date,
                "schedule_by": item.coordinator ? item.coordinator.first_name : "",
                "rating": item.overall_rating,
                "verdit": getInterviewFinalStatus(item.final_status),
                "duration": item.interview_duration,
                "qc_status": getQCStatuslabel(item.qc_status),
                "qc_feedback": item.qc_remark,
                "location": item.job_location,
                "amount": item.interview_charge,
                "qc_deduction": 0,
                "final_amount": item.interview_charge
            };
            rowData.push(datum);
        });
        const rows = rowData.map((row) => Object.values(row).join(','));
        return `${header}\n${rows.join('\n')}`;
    };
    const sendInterviewReport = (interviewId) => {
        setIsLoading(true)
        const monthIndex = monthNames.indexOf(currentMonth);
        fetch(`${API_URL}api/sendInterviewReport/${interviewId}?currentMonth=${monthIndex + 1}&currentYear=${currentYear.getFullYear()}&qcStatus=${QC_STATUS_PASS}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    const downloadInterviewReport = (interviewId, type = 'download') => {
        setIsLoading(true)
        const monthIndex = monthNames.indexOf(currentMonth);
        fetch(`${API_URL}api/interviews/${interviewId}?currentMonth=${monthIndex + 1}&currentYear=${currentYear.getFullYear()}&qcStatus=${QC_STATUS_PASS}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                const interviewReportData = response.data;
                if (type === 'download') {
                    const csvContent = convertToCsv(interviewReportData);
                    const blob = new Blob([csvContent], { type: 'text/csv' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'table_data.csv';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report downloaded successfully", life: 3000 });
                } else if (type === 'view') {
                    setCsvContent(interviewReportData);
                    setShowInterviewReportModal(true);
                    setIsLoading(false)
                }
            })
            .catch((err) => {
            });
    }

    const selectChangeHandler = (e, name) => {
        const { value } = e;
        setProfileDetail({
            ...profileDetail,
            [name]: value,
        });
    }

    return (
        <Fragment>
            <ConfirmDialog />
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="pageTitle_fixed">
                            <div className="">
                                <p className="employer-dashboard-jobs-posted"><span className='text-black-50'>Settings</span> <span className='px-2 text-warning'>/</span> <span className='text-black-50'>Interviewer</span> <span className='px-2 text-warning'>/</span> <span className='text-primary'>{interviewerProfile.first_name + " " + interviewerProfile.last_name}</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-3 col-12 interviewer-profile-details-left">
                                <Card>
                                    <Card.Body>
                                        <div className="row pt-3 mb-3">
                                            <div className="col-12 text-center">
                                                <img className="profile-image" src={!_.isUndefined(profileDetail.profile_image) && !_.isNull(profileDetail.profile_image) && (profileDetail.profile_image !== '') ? profileDetail.profile_image : default_profile_picture} alt="Profile" />
                                                {
                                                    userDetails && userDetails.id === interviewerProfile.id && (

                                                        <>
                                                            <input style={{ display: 'none' }} type="file" id="upload_profile" name="upload_profile" onChange={uploadFileHandler} placeholder="Profile Photo" multiple ref={fileUploadRef} />
                                                            <div className="file-upload-container mt-2" onClick={() => fileUploadRef.current.click()}>
                                                                <div className="custom-file-upload">
                                                                    Choose file
                                                                </div>
                                                                <div className="file-name">{fileName ?? "No file chosen"}</div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row my-6">
                                            <div className="col-12 mt-3 mb-2">
                                                <b className='fs-5 '>PROFILE</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email*</Form.Label>
                                                    {
                                                        isDetailEdit ?
                                                            (
                                                                <Form.Control
                                                                    value={profileDetail.email}
                                                                    type="text"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    onChange={changeHandler}
                                                                    maxLength={150}
                                                                    autoComplete="off"
                                                                />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.email}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Registration ID</label>
                                                <p className="interviewer-profile-details-value">{interviewerProfile.id}</p>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobile Number*</Form.Label>

                                                    {
                                                        isDetailEdit && userDetails.account_type === SUPER_ADMIN ?
                                                            (
                                                                <Form.Control value={profileDetail ? profileDetail.mobile_number : ""} type="text" id="mobile_number" name="mobile_number" placeholder="Mobile Number" onChange={changeHandler} maxLength={10} autoComplete="off" />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{interviewerProfile.mobile_number}</p>
                                                            )
                                                    }

                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Designation*</Form.Label>
                                                    {
                                                        isDetailEdit ?
                                                            (
                                                                <ReactTags
                                                                    labelText="Select designation"
                                                                    selected={profileDetail.designation === "" || profileDetail.designation === null ? [] : [{ name: profileDetail.designation, value: profileDetail.designation, label: profileDetail.designation }]}
                                                                    suggestions={designationOptions}
                                                                    allowNew={true}
                                                                    onAdd={(selectedList) => {
                                                                        setProfileDetail({
                                                                            ...profileDetail,
                                                                            designation: selectedList.value
                                                                        });
                                                                    }}
                                                                    onDelete={(selectedList) => {
                                                                        setProfileDetail({
                                                                            ...profileDetail,
                                                                            designation: ""
                                                                        });
                                                                    }}
                                                                    placeholderText="Designation"
                                                                    noOptionsText="No Matching designation"
                                                                />
                                                            ) : (
                                                                <p className="interviewer-profile-details-value">{profileDetail.designation}</p>
                                                            )
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <label className="interviewer-profile-details-label">Sign Up Date</label>
                                                <p className="interviewer-profile-details-value">{commonDateFormat(interviewerProfile.createdAt)}</p>
                                            </div>

                                            {
                                                userDetails && userDetails.account_type === INTERVIEWER && (
                                                    <div className="col-12">
                                                        <label className="interviewer-profile-details-label">
                                                            Resume &nbsp;
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>Interviewer Resume</Tooltip>}
                                                            >
                                                                <a href="javascript:void(0)" style={{ color: 'black', fontSize: '14px' }} className="cursor-pointer" onClick={() => {
                                                                    setSelectedItem(interviewerProfile)
                                                                    setIsShowResumeDialog(true)
                                                                }}><FaFileAlt /></a>
                                                            </OverlayTrigger>
                                                        </label>
                                                        
                                                        <p className='interviewer-profile-details-value mt-2'>
                                                            <input style={{ display: 'none' }} type="file" id="resume" name="resume" onChange={uploadResumeFileHandler} placeholder="Resume" multiple ref={resumeFileUploadRef} />
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={<Tooltip>Upload Resume</Tooltip>}
                                                            >
                                                                <button className='btn btn-outline-primary btn-sm' onClick={() => {
                                                                    // setSelectedItem(item);
                                                                    resumeFileUploadRef.current.click();
                                                                }}>Browse</button>
                                                            </OverlayTrigger>
                                                        </p>
                                                    </div>
                                                )
                                            }

                                            <div className="col-12">
                                                {
                                                    userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                        isDetailEdit ? (
                                                            <a href="javascript:void(0)" onClick={() => updateProfile()} className="btn btn-primary">Save</a>
                                                        ) : (
                                                            <a href="javascript:void(0)" onClick={() => setIsDetailEdit(true)} className="btn btn-outline-primary">Edit</a>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-9 col-12 interviewer-profile-details-right">
                                <Tabs defaultActiveKey="InterviewerProfile" className="mb-2">
                                    <Tab eventKey="InterviewerProfile" title="Interviewer Profile">
                                        <Card>
                                            <Card.Body>
                                                <div className="row px-4">
                                                    <div className="col-12 px-0">
                                                        <b className="interviewer-profile-details-right-name">{interviewerProfile.first_name + " " + interviewerProfile.last_name} <span className="interviewer-profile-details-right-location"><IoLocationOutline size={16} />{city + ", " + interviewerState + ", " + interviewerCountry}</span></b>
                                                    </div>
                                                    <div className="col-12 px-0">
                                                        <p className="interviewer-profile-details-right-designation">{interviewerProfile.designation}</p>
                                                    </div>
                                                </div>
                                                <div className="row px-4">
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-label">Linkedin</label>
                                                        {
                                                            isEditProfile ? (
                                                                <Form.Control value={profileDetail.linkedin} type="text" placeholder="Linkedin" name="linkedin" id="linkedin" onChange={changeHandler} autoComplete="off" />
                                                            ) : (
                                                                <p className="interviewer-profile-details-right-value">{interviewerProfile.linkedin}</p>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-label">Naukari Profile</label>
                                                        {
                                                            isEditProfile ? (
                                                                <Form.Control value={profileDetail.naukari_profile} type="text" placeholder="Naukari Profile" name="naukari_profile" id="naukari_profile" onChange={changeHandler} autoComplete="off" />
                                                            ) : (
                                                                <p className="interviewer-profile-details-right-value">{interviewerProfile.naukari_profile}</p>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-label">First name</label>
                                                        {
                                                            isEditProfile ? (
                                                                <Form.Control value={profileDetail.first_name} type="text" placeholder="First Name" name="first_name" id="first_name" onChange={changeHandler} autoComplete="off" />
                                                            ) : (
                                                                <p className="interviewer-profile-details-right-value">{interviewerProfile.first_name}</p>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-label">Last name</label>
                                                        <p className="interviewer-profile-details-right-value">
                                                            <Row>
                                                                <Col>
                                                                    {
                                                                        isEditProfile ? (
                                                                            <Form.Control value={profileDetail.last_name} type="text" placeholder="Last Name" name="last_name" id="last_name" onChange={changeHandler} autoComplete="off" />
                                                                        ) : (
                                                                            <p className="interviewer-profile-details-right-value">{interviewerProfile.last_name}</p>
                                                                        )
                                                                    }
                                                                </Col>
                                                                {
                                                                    userDetails && userDetails.account_type === INTERVIEWER && (
                                                                        <Col>
                                                                            <a href="javascript:void(0);" onClick={() => {
                                                                                if (isEditProfile) {
                                                                                    updateProfile();
                                                                                }
                                                                                setIsEditProfile(!isEditProfile);
                                                                            }} className="interviewer-profile-details-button btn btn-outline-primary mt-minus" >

                                                                                {
                                                                                    isEditProfile ? 'Save' : 'Edit'
                                                                                }
                                                                            </a>
                                                                        </Col>
                                                                    )
                                                                }
                                                            </Row>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 px-4">
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row px-4">
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-bank-label">Name</label>
                                                        <p className="interviewer-profile-details-right-bank-value">{interviewerProfile.bank_user_name}</p>
                                                    </div>
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-bank-label">Bank name</label>
                                                        <p className="interviewer-profile-details-right-bank-value">{interviewerProfile.bank_name}</p>
                                                    </div>
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-bank-label">Branch name</label>
                                                        <p className="interviewer-profile-details-right-bank-value">{interviewerProfile.bank_branch_name}</p>
                                                    </div>
                                                    <div className="col-md-3 col-12 px-1">
                                                        <label className="interviewer-profile-details-right-bank-label">Account number</label>
                                                        <p className="interviewer-profile-details-right-bank-value">{interviewerProfile.bank_account_number}</p>
                                                    </div>
                                                </div>
                                                <div className="row px-4">
                                                    <div className="col-md-3 col-12 px-0">
                                                        <label className="interviewer-profile-details-right-bank-label">IFSC Code</label>
                                                        <p className="interviewer-profile-details-right-bank-value">{interviewerProfile.bank_ifsc_code}</p>
                                                    </div>
                                                    <div className="col-md-3 col-12 px-0">
                                                        <label className="interviewer-profile-details-right-bank-label">UPI ID</label>
                                                        <p className="interviewer-profile-details-right-bank-value">{interviewerProfile.bank_upi_id}</p>
                                                    </div>
                                                    <div className="col-md-3 col-12 px-0">
                                                        <label className="interviewer-profile-details-right-bank-label">Mobile number</label>
                                                        <p className="interviewer-profile-details-right-bank-value">
                                                            {interviewerProfile.country_code + interviewerProfile.mobile_number}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3 col-12 px-0 ">
                                                        <label class="interviewer-profile-details-right-bank-label">Charge</label>
                                                        <p class="interviewer-profile-details-right-bank-value">
                                                            {interviewerProfile.charge}
                                                            {
                                                                userDetails && (userDetails.account_type === SUPER_ADMIN || userDetails.account_type === SUB_ADMIN) && (
                                                                    <a style={{ float: 'right' }} href="JavaScript:void(0);" onClick={() => setShowBankDetails(true)} className="btn btn-outline-primary">Edit</a>
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 px-4 pb-4">
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 px-4">
                                                        <Accordion className="interviewer-profile-accordion">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Location Preferences</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="0">
                                                                    <Accordion.Header><span>Location Preferences</span>
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (

                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => {
                                                                                    setShowLocationPreferences(true)
                                                                                }} />

                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.location_preferences) && !_.isNull(interviewerProfile.location_preferences) && (interviewerProfile.location_preferences.length > 0) &&
                                                                            <ol>
                                                                                {
                                                                                    _.map(interviewerProfile.location_preferences, (location, index) => {
                                                                                        return <li key={"location_preferences_" + index}>{location.name}</li>
                                                                                    })
                                                                                }
                                                                            </ol>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Work Experience</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>Work Experience
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => {
                                                                                    setWorkExperience({});
                                                                                    setWorkExperienceEditItemIndex(-1);

                                                                                    setIsWorkExperienceTitleError("");
                                                                                    setIsWorkExperienceEmploymentTypeError("");
                                                                                    setIsWorkExperienceCompanyNameError("");
                                                                                    setIsWorkExperienceLocationError("");
                                                                                    setIsWorkExperienceLocationTypeError("");
                                                                                    setIsWorkExperienceStartDateMonthError("");
                                                                                    setIsWorkExperienceStartDateYearError("");
                                                                                    setIsWorkExperienceEndDateMonthError("");
                                                                                    setIsWorkExperienceEndDateYearError("");

                                                                                    setShowWorkExperience(true)
                                                                                }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.work_experience) && !_.isNull(interviewerProfile.work_experience) && (interviewerProfile.work_experience.length > 0) &&
                                                                            _.map(interviewerProfile.work_experience, (experience, index) => {
                                                                                return <div className="row mb-3" key={"work_experience_" + index}>
                                                                                    <div className="col-12 work_experience_details" style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'space-between'
                                                                                    }}>
                                                                                        <div className="work_experience_details">
                                                                                            <p className="work_experience_details_title">{experience.title}</p>
                                                                                            <p className="work_experience_details_company_name">{experience.company_name}</p>
                                                                                            <p className="work_experience_details_educaton">{experience.educaton}</p>
                                                                                            <p className="work_experience_details_start_and_end_time">{experience.start_date_month + ", " + experience.start_date_year + " to " + (experience.is_currently_working ? "I am currently working in this role" : (experience.end_date_month + ", " + experience.end_date_year))}</p>
                                                                                            <p className="work_experience_details_company_location">{experience.location}</p>
                                                                                            <p className="work_experience_details_employment_type"><span className="employment_type">Employment Type: </span>{experience.employment_type}</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                                    <>
                                                                                                        <Button variant="outline-primary" className='me-2' onClick={() => {
                                                                                                            setWorkExperience(experience);
                                                                                                            setWorkExperienceEditItemIndex(index);
                                                                                                            setShowWorkExperience(true)
                                                                                                        }}>
                                                                                                            Edit
                                                                                                        </Button>
                                                                                                        <Button variant="outline-danger" onClick={() => {
                                                                                                            setWorkExperienceDeleteId(index);
                                                                                                        }}>
                                                                                                            Delete
                                                                                                        </Button>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Education</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="2">
                                                                    <Accordion.Header>Education
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => {
                                                                                    setEducations({});
                                                                                    setEducationEditItemIndex(-1);
                                                                                    setShowEducations(true);
                                                                                }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.educations) && !_.isNull(interviewerProfile.educations) && (interviewerProfile.educations.length > 0) &&
                                                                            _.map(interviewerProfile.educations, (education, index) => {
                                                                                return <div className="row mb-3" key={"education_" + index}>
                                                                                    <div className="col-12 education_details" style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'space-between'
                                                                                    }}>
                                                                                        <div className="education_details">
                                                                                            <p className="education_school_name">{education.school_name}</p>
                                                                                            <p className="education_school_location">{education.location}</p>
                                                                                            <p className="education_school_start_and_end_time">{education.start_date_month + ", " + education.start_date_year + " to " + education.end_date_month + ", " + education.end_date_year}</p>
                                                                                            <p className="education_school_grade"><span className="employment_type">Grade: </span>{education.grade}</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                                    <>
                                                                                                        <Button variant="outline-primary" onClick={() => {
                                                                                                            setEducations(education);
                                                                                                            setEducationEditItemIndex(index);
                                                                                                            setShowEducations(true)
                                                                                                        }}>
                                                                                                            Edit
                                                                                                        </Button>
                                                                                                        <Button variant="outline-danger" onClick={() => {
                                                                                                            setEducationDeleteId(index);
                                                                                                        }}>
                                                                                                            Delete
                                                                                                        </Button>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Primary Skills</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="3">
                                                                    <Accordion.Header>Primary skills
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => { setShowSkills(true) }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.primary_skill) && !_.isNull(interviewerProfile.primary_skill) && (interviewerProfile.primary_skill.length > 0) &&
                                                                            <ol>
                                                                                {
                                                                                    _.map(interviewerProfile.primary_skill, (skill, index) => {
                                                                                        return <li key={"skills_" + index}>{skill}</li>
                                                                                    })
                                                                                }
                                                                            </ol>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Secondary Skills</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="7">
                                                                    <Accordion.Header>Secondary skills
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => { setShowSecondarySkills(true) }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.secondary_skill) && !_.isNull(interviewerProfile.secondary_skill) && (interviewerProfile.secondary_skill.length > 0) &&
                                                                            <ol>
                                                                                {
                                                                                    _.map(interviewerProfile.secondary_skill, (skill, index) => {
                                                                                        return <li key={"skills_" + index}>{skill}</li>
                                                                                    })
                                                                                }
                                                                            </ol>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Certificates</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="4">
                                                                    <Accordion.Header>Certificates
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => { setShowCertificates(true) }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.certificates) && !_.isNull(interviewerProfile.certificates) && (interviewerProfile.certificates.length > 0) &&
                                                                            <ol>
                                                                                {
                                                                                    _.map(interviewerProfile.certificates, (certificate, index) => {
                                                                                        return <li key={"certificates_" + index}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', alignItems: 'center' }}>
                                                                                                <div>
                                                                                                    {certificate.title}
                                                                                                </div>
                                                                                                <div>
                                                                                                    <FaEye size={24} onClick={() => window.open(certificate.certificate_file, '_blank')} />
                                                                                                    {
                                                                                                        userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                                            <>
                                                                                                                &nbsp;&nbsp;
                                                                                                                <Button variant="outline-danger" onClick={() => {
                                                                                                                    setCertificateDeleteId(index);
                                                                                                                }}>
                                                                                                                    Delete
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    })
                                                                                }
                                                                            </ol>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Documents</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="5">
                                                                    <Accordion.Header><div className='flex-column'>Documents <div><small>Please upload identification documents such as Aadhar card, PAN card, or any other valid government-issued ID for verification</small></div></div>
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => {
                                                                                    setDocumentDeleteId(-1);
                                                                                    setShowDocuments(true)
                                                                                }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.documents) && !_.isNull(interviewerProfile.documents) && (interviewerProfile.documents.length > 0) &&
                                                                            <ol>
                                                                                {
                                                                                    _.map(interviewerProfile.documents, (document, index) => {
                                                                                        return <li id={"documents_" + index} key={"documents_" + index}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', alignItems: 'center' }}>
                                                                                                <div>{document.title}</div>
                                                                                                <div>
                                                                                                    <FaEye size={24} onClick={() => window.open(document.document_file, '_blank')} />
                                                                                                    {
                                                                                                        userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                                            <>
                                                                                                                &nbsp;&nbsp;
                                                                                                                <Button variant="outline-danger" onClick={() => {
                                                                                                                    setDocumentDeleteId(index);
                                                                                                                }}>Delete</Button>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    })
                                                                                }
                                                                            </ol>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={userDetails.account_type === INTERVIEWER ? <Tooltip>Edit Languages</Tooltip> : <></>}
                                                            >
                                                                <Accordion.Item eventKey="6">
                                                                    <Accordion.Header>Languages
                                                                        {
                                                                            userDetails && userDetails.account_type === INTERVIEWER && (
                                                                                <FaRegEdit size={14} className="FaRegEdit acc-edit" onClick={() => { setShowLanguages(true) }} />
                                                                            )
                                                                        }
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {
                                                                            !_.isUndefined(interviewerProfile.languages) && !_.isNull(interviewerProfile.languages) && (interviewerProfile.languages.length > 0) &&
                                                                            <ol>
                                                                                {
                                                                                    _.map(interviewerProfile.languages, (language, index) => {
                                                                                        return <li key={"languages_" + index}>{language.name}</li>
                                                                                    })
                                                                                }
                                                                            </ol>
                                                                        }
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </OverlayTrigger>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    {
                                        userDetails && userDetails.account_type === SUPER_ADMIN && (
                                            <Tab eventKey="InterviewerStats" title="Interviewer Stats">
                                                <div className="row" style={{ marginBottom: '20px' }}>
                                                    <div className="col-12 col-md-6 col-lg-2" style={{ fontSize: '14px' }}>
                                                        View by Month
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className="carousel-demo">
                                                            <Carousel value={monthNames} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} className="custom-carousel" circular={true}
                                                                autoplayInterval={60000} itemTemplate={carouselItems} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-2">
                                                        <DatePicker showYearPicker onChange={setCurrentYear} selected={currentYear} maxDate={new Date()} className="form-control" dateFormat="yyyy" />
                                                    </div>

                                                    <div className="col-12 col-md-6 col-lg-4">
                                                        <div className='d-flex mt-2'>
                                                            <div className='input_grp_buttons' style={{ width: '100%', }}>
                                                                <Container>
                                                                    <Row>
                                                                        <Col className="col-2 btn btn-outline-primary" onClick={() => downloadInterviewReport(id, 'view')} md={2} style={{ borderRight: '2px solid white' }}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>See</Tooltip>}
                                                                            >
                                                                                <div ><FaEye className="cursor-pointer" /></div>
                                                                            </OverlayTrigger>
                                                                        </Col>
                                                                        <Col className="col-2 btn btn-outline-primary" onClick={() => downloadInterviewReport(id, 'download')} md={2} style={{ borderRight: '2px solid white' }}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>Download</Tooltip>}
                                                                            >
                                                                                <div ><FaDownload className="cursor-pointer" /></div>
                                                                            </OverlayTrigger>
                                                                        </Col>
                                                                        <Col className="col-2 btn btn-outline-primary" onClick={() => sendInterviewReport(id)} md={2} style={{ borderRight: '2px solid white' }}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>Send</Tooltip>}
                                                                            >
                                                                                <div ><BsFillSendFill className="cursor-pointer" /></div>
                                                                            </OverlayTrigger>
                                                                        </Col>
                                                                        <Col className="col-6 btn btn-outline-primary" md={6}>
                                                                            <div style={{ textWrap: 'nowrap' }} >Payment Report</div>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                            <thead className='sticky-top'>
                                                                <tr>
                                                                    <th>Scheduled Interview</th>
                                                                    <th>Conducted Interview</th>
                                                                    <th>Selected</th>
                                                                    <th>Rejected</th>
                                                                    <th>No Show By Interviewer</th>
                                                                    <th>No Show By Candidate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !_.isUndefined(interviewState) && !_.isNull(interviewState) &&
                                                                    _.map(interviewState, (item, index) => {
                                                                        return <tr key={"interview-list-" + index}>
                                                                            <td style={{ textAlign: 'center' }}>{item.scheduled_interview_count}</td>
                                                                            <td style={{ textAlign: 'center' }}>{item.conducted__interview_count}</td>
                                                                            <td style={{ textAlign: 'center' }}>{item.selected_interview_count}</td>
                                                                            <td style={{ textAlign: 'center' }}>{item.rejected_interview_count}</td>
                                                                            <td style={{ textAlign: 'center' }}>{item.no_show_by_interviewer_count}</td>
                                                                            <td style={{ textAlign: 'center' }}>{item.no_show_by_candidate_count}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>

                                            </Tab>
                                        )
                                    }

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showLocationPreferences &&
                <Modal centered backdrop="static" show={showLocationPreferences} onHide={() => setShowLocationPreferences(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Location Preferences</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Multiselect
                            options={countryOptions} // Options to display in the dropdown
                            selectedValues={interviewerProfile.location_preferences} // Preselected value to persist in dropdown
                            onSelect={onSelectLocationPreferences} // Function will trigger on select event
                            onRemove={onRemoveLocationPreferences} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowLocationPreferences(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitLocationPreferences()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showLanguages &&
                <Modal centered backdrop="static" show={showLanguages} onHide={() => setShowLanguages(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Language</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Multiselect
                            options={languageOptions} // Options to display in the dropdown
                            selectedValues={interviewerProfile.languages} // Preselected value to persist in dropdown
                            onSelect={onSelectLanguages} // Function will trigger on select event
                            onRemove={onRemoveLanguages} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowLanguages(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitLanguages()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showSkills &&
                <Modal centered backdrop="static" show={showSkills} onHide={() => setShowSkills(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Primary skills</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactTags
                            labelText="Select Primary Skills"
                            selected={skills}
                            suggestions={skillsOptions}
                            allowNew={true}
                            onAdd={onSelectPrimarySkills}
                            onDelete={onRemovePrimarySkills}
                            placeholderText="Primary Skill"
                            noOptionsText="No Matching Primary Skill"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowSkills(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitSkills()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showSecondarySkills &&
                <Modal centered backdrop="static" show={showSecondarySkills} onHide={() => setShowSecondarySkills(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Seconday skills </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactTags
                            labelText="Select Secondary Skills"
                            selected={secondarySkills}
                            suggestions={skillsOptions}
                            allowNew={true}
                            onAdd={onSelectSecondarSkills}
                            onDelete={onRemoveSecondarySkills}
                            placeholderText="Seconadry Skill"
                            noOptionsText="No Matching Seconadry Skill"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowSecondarySkills(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitSecondaySkills()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }

            {
                showWorkExperience &&
                <Modal centered backdrop="static" show={showWorkExperience} onHide={() => setShowWorkExperience(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{workExperienceEditItemIndex !== -1 ? "Edit Experience" : "Add Experience"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-scroll'>
                        <Form.Group className="mb-2">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" value={workExperience ? workExperience.title : ""} placeholder="Title" name="title" id="title" onChange={changeWorkExperienceHandler} />
                            {
                                !_.isUndefined(workExperienceTitleError) && !_.isNull(workExperienceTitleError) && (workExperienceTitleError !== '') &&
                                <Alert variant="outline-secondary" className="mt-2">{workExperienceTitleError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Employment type</Form.Label>
                            <Form.Select value={workExperience ? workExperience.employment_type : ""} aria-label="Employment type" name="employment_type" id="employment_type" onChange={changeWorkExperienceHandler}>
                                <option>Please select</option>
                                <option value="Full-time">Full-time</option>
                                <option value="Part-time">Part-time</option>
                                <option value="Self-employed">Self-employed</option>
                                <option value="Freelance">Freelance</option>
                                <option value="Internship">Internship</option>
                                <option value="Trainee">Trainee</option>
                            </Form.Select>
                            {
                                !_.isUndefined(workExperienceEmploymentTypeError) && !_.isNull(workExperienceEmploymentTypeError) && (workExperienceEmploymentTypeError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceEmploymentTypeError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Company name</Form.Label>
                            <Form.Control type="text" value={workExperience ? workExperience.company_name : ""} placeholder="Company name" name="company_name" id="company_name" onChange={changeWorkExperienceHandler} />
                            {
                                !_.isUndefined(workExperienceCompanyNameError) && !_.isNull(workExperienceCompanyNameError) && (workExperienceCompanyNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceCompanyNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" value={workExperience ? workExperience.location : ""} placeholder="Location" name="location" id="location" onChange={changeWorkExperienceHandler} />
                            {
                                !_.isUndefined(workExperienceLocationError) && !_.isNull(workExperienceLocationError) && (workExperienceLocationError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceLocationError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location type</Form.Label>
                            <Form.Select aria-label="Location type" name="location_type" value={workExperience ? workExperience.location_type : ""} id="location_type" onChange={changeWorkExperienceHandler}>
                                <option>Please select</option>
                                <option value="On-site">On-site</option>
                                <option value="Hybrid">Hybrid</option>
                                <option value="Remote">Remote</option>
                            </Form.Select>
                            {
                                !_.isUndefined(workExperienceLocationTypeError) && !_.isNull(workExperienceLocationTypeError) && (workExperienceLocationTypeError !== '') &&
                                <Alert variant="danger" className="mt-2">{workExperienceLocationTypeError}</Alert>
                            }
                        </Form.Group>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>Start Date</Form.Label>
                            </div>
                            <div className="col-6" key={'workexperience_start_date'}>
                                <Form.Select aria-label="Month" value={workExperience ? workExperience.start_date_month : ""} name="start_date_month" id="start_date_month" onChange={changeWorkExperienceHandler}>
                                    <option>Month</option>
                                    {
                                        months && months.map((item) => {
                                            return <option value={item.id}>{item.name}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceStartDateMonthError) && !_.isNull(workExperienceStartDateMonthError) && (workExperienceStartDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceStartDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select aria-label="Year" value={workExperience ? workExperience.start_date_year : ""} name="start_date_year" id="start_date_year" onChange={changeWorkExperienceHandler}>
                                    <option>Year</option>
                                    {
                                        yearList && yearList.map((item) => {
                                            return <option value={item}>{item}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceStartDateYearError) && !_.isNull(workExperienceStartDateYearError) && (workExperienceStartDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceStartDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>End Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select value={workExperience ? workExperience.end_date_month : ""} aria-label="Month" name="end_date_month" id="end_date_month" onChange={changeWorkExperienceHandler}>
                                    <option>Month</option>
                                    {
                                        months && months.map((item) => {
                                            return <option value={item.id}>{item.name}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceEndDateMonthError) && !_.isNull(workExperienceEndDateMonthError) && (workExperienceEndDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceEndDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select value={workExperience ? workExperience.end_date_year : ""} aria-label="Year" name="end_date_year" id="end_date_year" onChange={changeWorkExperienceHandler}>
                                    <option>Year</option>
                                    {
                                        yearList && yearList.map((item) => {
                                            return <option value={item}>{item}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(workExperienceEndDateYearError) && !_.isNull(workExperienceEndDateYearError) && (workExperienceEndDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{workExperienceEndDateYearError}</Alert>
                                }
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <Form.Check.Input checked={workExperience ? workExperience.is_currently_working : ""} name="is_currently_working" type="checkbox" onChange={changeWorkExperienceHandler} />
                                    <Form.Check.Label>&nbsp;I am currently working in this role</Form.Check.Label>
                                </div>
                            </div>
                        </div>

                        <Form.Group className="mb-2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" value={workExperience ? workExperience.description : ""} rows={3} aria-label="Description" name="description" id="description" onChange={changeWorkExperienceHandler} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowWorkExperience(false)}>Close</Button>
                        <Button variant="primary" onClick={() => {
                            if (workExperienceEditItemIndex !== -1) {
                                const updatedEducations = interviewerProfile.work_experience.map((item, index) => {
                                    if (index === workExperienceEditItemIndex) {
                                        return workExperience;
                                    }
                                    return item;
                                });
                                setInterviewerProfile(prevProfile => {
                                    return {
                                        ...prevProfile,
                                        work_experience: updatedEducations,
                                    };
                                });
                                submitWorkExperience(updatedEducations)
                            } else {
                                submitWorkExperience()
                            }
                        }}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showEducations &&
                <Modal centered backdrop="static" show={showEducations} onHide={() => setShowEducations(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{educationEditItemIndex !== -1 ? "Edit Education" : "Add Education"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-scroll'>
                        <Form.Group className="mb-2">
                            <Form.Label>School/College</Form.Label>
                            <Form.Control type="text" value={educations ? educations.school_name : ""} placeholder="School" name="school_name" id="school_name" onChange={changeEducationHandler} />
                            {
                                !_.isUndefined(educationSchoolNameError) && !_.isNull(educationSchoolNameError) && (educationSchoolNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationSchoolNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Grade</Form.Label>
                            <Form.Control type="text" value={educations ? educations.grade : ""} placeholder="Grade" name="grade" id="grade" onChange={changeEducationHandler} />
                            {
                                !_.isUndefined(educationGradeError) && !_.isNull(educationGradeError) && (educationGradeError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationGradeError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" value={educations ? educations.location : ""} placeholder="Location" name="location" id="location" onChange={changeEducationHandler} />
                            {
                                !_.isUndefined(educationLocationError) && !_.isNull(educationLocationError) && (educationLocationError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationLocationError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Education</Form.Label>
                            <Form.Select value={educations ? educations.education : ""} aria-label="education" name="education" id="education" onChange={changeEducationHandler}>
                                <option>Education</option>
                                <option value="Bsc">Bsc</option>
                                <option value="Msc">Msc</option>
                                <option value="BCA">BCA</option>
                                <option value="MCA">MCA</option>
                                <option value="Diploma in CS">Diploma in CS</option>
                                <option value="Btech">Btech</option>
                                <option value="MTECH">MTECH</option>
                                <option value="BE">BE</option>
                                <option value="ME">ME</option>
                                <option value="Bcom">Bcom</option>
                                <option value="Mcom">Mcom</option>
                                <option value="BBA">BBA</option>
                                <option value="MBA">MBA</option>
                                <option value="BA/MA">BA/MA</option>
                                <option value="others">others</option>
                            </Form.Select>
                            {
                                !_.isUndefined(educationsEducationError) && !_.isNull(educationsEducationError) && (educationsEducationError !== '') &&
                                <Alert variant="danger" className="mt-2">{educationsEducationError}</Alert>
                            }
                        </Form.Group>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>Start Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select value={educations ? educations.start_date_month : ""} aria-label="Month" name="start_date_month" id="start_date_month" onChange={changeEducationHandler}>
                                    <option>Month</option>
                                    {
                                        months && months.map((item) => {
                                            return <option value={item.id}>{item.name}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(educationStartDateMonthError) && !_.isNull(educationStartDateMonthError) && (educationStartDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationStartDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select value={educations ? educations.start_date_year : ""} aria-label="Year" name="start_date_year" id="start_date_year" onChange={changeEducationHandler}>
                                    <option>Year</option>
                                    {
                                        yearList && yearList.map((item) => {
                                            return <option value={item}>{item}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(educationStartDateYearError) && !_.isNull(educationStartDateYearError) && (educationStartDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationStartDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <Form.Label>End Date</Form.Label>
                            </div>
                            <div className="col-6">
                                <Form.Select value={educations ? educations.end_date_month : ""} aria-label="Month" name="end_date_month" id="end_date_month" onChange={changeEducationHandler}>
                                    <option>Month</option>
                                    {
                                        months && months.map((item) => {
                                            return <option value={item.id}>{item.name}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(educationEndDateMonthError) && !_.isNull(educationEndDateMonthError) && (educationEndDateMonthError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationEndDateMonthError}</Alert>
                                }
                            </div>
                            <div className="col-6">
                                <Form.Select value={educations ? educations.end_date_year : ""} aria-label="Year" name="end_date_year" id="end_date_year" onChange={changeEducationHandler}>
                                    <option>Year</option>
                                    {
                                        yearList && yearList.map((item) => {
                                            return <option value={item}>{item}</option>;
                                        })
                                    }
                                </Form.Select>
                                {
                                    !_.isUndefined(educationEndDateYearError) && !_.isNull(educationEndDateYearError) && (educationEndDateYearError !== '') &&
                                    <Alert variant="danger" className="mt-2">{educationEndDateYearError}</Alert>
                                }
                            </div>
                        </div>
                        <Form.Group className="mb-2">
                            <Form.Label>Description</Form.Label>
                            <Form.Control value={educations ? educations.description : ""} as="textarea" rows={3} aria-label="Description" name="description" id="description" onChange={changeEducationHandler} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowEducations(false)}>Close</Button>
                        <Button variant="primary" onClick={() => {
                            if (educationEditItemIndex !== -1) {
                                const updatedEducations = interviewerProfile.educations.map((education, index) => {
                                    if (index === educationEditItemIndex) {
                                        return educations;
                                    }
                                    return education;
                                });
                                setInterviewerProfile(prevProfile => {
                                    return {
                                        ...prevProfile,
                                        educations: updatedEducations,
                                    };
                                });
                                submitEducations(updatedEducations)
                            } else {
                                submitEducations()
                            }
                        }}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showCertificates &&
                <Modal centered backdrop="static" show={showCertificates} onHide={() => setShowCertificates(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Certificates</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" name="title" id="title" onChange={changeCertificatesHandler} />
                            {
                                !_.isUndefined(certificatesTitleError) && !_.isNull(certificatesTitleError) && (certificatesTitleError !== '') &&
                                <Alert variant="danger" className="mt-2">{certificatesTitleError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>File</Form.Label>
                            <Form.Control type="file" placeholder="Certificate" name="certificate_file" id="certificate_file" onChange={(event) => changeUploadCertificatesHandler(event)} />
                            {
                                !_.isUndefined(certificatesFileError) && !_.isNull(certificatesFileError) && (certificatesFileError !== '') &&
                                <Alert variant="danger" className="mt-2">{certificatesFileError}</Alert>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowCertificates(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitCertificates()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showDocuments &&
                <Modal centered backdrop="static" show={showDocuments} onHide={() => setShowDocuments(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" name="title" id="title" onChange={changeDocumentsHandler} />
                            {
                                !_.isUndefined(documentsTitleError) && !_.isNull(documentsTitleError) && (documentsTitleError !== '') &&
                                <Alert variant="danger" className="mt-2">{documentsTitleError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Documents</Form.Label>
                            <Form.Control type="file" placeholder="Documents" name="documents_file" id="documents_file" onChange={(event) => changeUploadDocumentsHandler(event)} />
                            {
                                !_.isUndefined(documentsFileError) && !_.isNull(documentsFileError) && (documentsFileError !== '') &&
                                <Alert variant="danger" className="mt-2">{documentsFileError}</Alert>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowDocuments(false)}>Close</Button>
                        <Button variant="primary" onClick={() => submitDocuments()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showBankDetails &&
                <Modal centered backdrop="static" show={showBankDetails} onHide={() => setShowBankDetails(false)} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bank Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-2">
                            <Form.Label>Account Holder Name</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.bank_user_name} type="text" placeholder="Account Holder Name" name="bank_user_name" id="bank_user_name" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(bankUserNameError) && !_.isNull(bankUserNameError) && (bankUserNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{bankUserNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Bank name</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.bank_name} type="text" placeholder="Bank name" name="bank_name" id="bank_name" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(bankNameError) && !_.isNull(bankNameError) && (bankNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{bankNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Branch name</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.bank_branch_name} type="text" placeholder="Branch name" name="bank_branch_name" id="bank_branch_name" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(bankBranchNameError) && !_.isNull(bankBranchNameError) && (bankBranchNameError !== '') &&
                                <Alert variant="danger" className="mt-2">{bankBranchNameError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Account number</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.bank_account_number} type="text" placeholder="Account number" name="bank_account_number" id="bank_account_number" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(bankAccountNumberError) && !_.isNull(bankAccountNumberError) && (bankAccountNumberError !== '') &&
                                <Alert variant="danger" className="mt-2">{bankAccountNumberError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>IFSC Code</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.bank_ifsc_code} type="text" placeholder="IFSC Code" name="bank_ifsc_code" id="bank_ifsc_code" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(bankIFSCCodeError) && !_.isNull(bankIFSCCodeError) && (bankIFSCCodeError !== '') &&
                                <Alert variant="danger" className="mt-2">{bankIFSCCodeError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>UPI ID</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.bank_upi_id} type="text" placeholder="UPI ID" name="bank_upi_id" id="bank_upi_id" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(bankUPIIDError) && !_.isNull(bankUPIIDError) && (bankUPIIDError !== '') &&
                                <Alert variant="danger" className="mt-2">{bankUPIIDError}</Alert>
                            }
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Charge</Form.Label>
                            <Form.Control readOnly={userDetails && userDetails.account_type !== SUPER_ADMIN} value={bankDetails.charge} type="number" placeholder="Charge" name="charge" id="charge" onChange={changeBankDetailsHandler} />
                            {
                                !_.isUndefined(chargeError) && !_.isNull(chargeError) && (chargeError !== '') &&
                                <Alert variant="danger" className="mt-2">{chargeError}</Alert>
                            }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowBankDetails(false)}>Close</Button>
                        {
                            userDetails.account_type === SUPER_ADMIN && (
                                <Button variant="primary" onClick={() => submitBankDetails()}>Save</Button>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            }

            <Modal centered backdrop="static" show={showInterviewReportModal} onHide={() => setShowInterviewReportModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless responsive className="request-interviewer-users-feednback-completed">
                        <thead>
                            <tr>
                                <th>Candidate Name</th>
                                <th>Candidate E-mail</th>
                                <th>Title</th>
                                <th>Company Name</th>
                                <th>Schedule Date</th>
                                <th>Scheduled By</th>
                                <th>Rating</th>
                                <th>Verdit</th>
                                <th>Duration</th>
                                <th>QC Status</th>
                                <th>QC Feedback</th>
                                <th>Location</th>
                                <th>Amount</th>
                                <th>QC Deduction</th>
                                <th>Final Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                csvContent && csvContent.length > 0 && (
                                    csvContent.map((item) => {
                                        return (<tr>
                                            <td>{item.candidate_name}</td>
                                            <td>{item.candidate_email}</td>
                                            <td>{item.job_title}</td>
                                            <td>{item.company_name}</td>
                                            <td>{commonDateFormat(item.scheduled_on)}</td>
                                            <td>{item.coordinator ? item.coordinator.first_name : ""}</td>
                                            <td>{item.overall_rating}</td>
                                            <td>{getInterviewFinalStatus(item.final_status)}</td>
                                            <td>{item.interview_duration}</td>
                                            <td>{getQCStatuslabel(item.qc_status)}</td>
                                            <td>{item.qc_remark}</td>
                                            <td>{item.job_location}</td>
                                            <td>{item.interview_charge}</td>
                                            <td>{item.qc_status === QC_STATUS_FAIL ? 250 : 0}</td>
                                            <td>{item.interview_charge}</td>
                                        </tr>);
                                    })
                                )
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowInterviewReportModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Dialog header="Candidate Resume" visible={isShowResumeDialog} style={{ width: '50vw', height: '80vh' }} onHide={() => setIsShowResumeDialog(false)} dismissableMask="true">
                {
                    selectedItem && selectedItem.resume &&
                    (
                        selectedItem.resume.includes('.doc') ?
                            <iframe title="Resume" key="iframe-doc" style={{
                                width: '100%',
                                height: '100%'
                            }} src={`https://docs.google.com/gview?url=${selectedItem.resume}&embedded=true`}></iframe> :
                            <object aria-label="Resume" key="object-other" data={selectedItem.resume} style={{
                                width: '100%',
                                height: '100%'
                            }}></object>
                    )
                }
            </Dialog>

        </Fragment>
    )
}
export default InterviewerProfileScreen;
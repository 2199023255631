import _ from 'lodash';
import { Toast } from 'primereact/toast';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, DropdownButton, Form, InputGroup, Modal, Nav, OverlayTrigger, Tab, Table, Tooltip } from 'react-bootstrap';
import { CiFilter } from "react-icons/ci";
import { FaFileAlt, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API_URL, ITEMS_PER_PAGE, USER_STATUS_APPROVE, USER_STATUS_ON_HOLD, USER_STATUS_PENDING, USER_STATUS_REJECT, commonDateFormat, getAuthUserFromSessionStorage, getUserStatus, updateStatusLabel } from "../../common/constant";
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';
import NoRecordFound from "../../common/NoRecordFound/NoRecordFound";
import { EMPLOYER_SUPER_ADMIN, INTERVIEWER, SUB_ADMIN } from '../../common/roles';
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./SuperAdminSettingScreen.css";

const SuperAdminSettingScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [showFormErrors, setShowFormErrors] = useState(true);
    const [showFormSuccess, setShowFormSuccess] = useState(true);

    const [interviewerList, setInterviewerList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [ralaAdminList, setRalaAdminList] = useState([]);

    const [totalInterviewerCount, setTotalInterviewerCount] = useState(0);
    const [totalCompanyCount, setTotalCompanyCount] = useState(0);
    const [totalRalaAdminCount, setTotalRalaAdminCount] = useState(0);

    const [showOpenResume, setShowOpenResume] = useState(false);
    const [interviewerDetails, setInterviewerDetails] = useState({});

    const [searchText, setSearchText] = useState('');
    const [interviewerSortConfig, setInterviewerSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const [companySortConfig, setCompanySortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const [ralaSubAdminSortConfig, setRalaSubAdminSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    
    const [interviewPageNumber, setInterviewPageNumber] = useState(1);
    const [interviewPageSize, seInterviewPageSize] = useState(ITEMS_PER_PAGE);
    const [companyPageNumber, setCompanyPageNumber] = useState(1);
    const [companyPageSize, setCompanyPageSize] = useState(ITEMS_PER_PAGE);
    const [ralaAdminPageNumber, setRalaAdminPageNumber] = useState(1);
    const [ralaAdminPageSize, setRalaAdminPageSize] = useState(ITEMS_PER_PAGE);

    let timeoutId;

    const [selectedFields, setSelectedFields] = useState({
        primarySkills: [],
        secondarySkills: [],
        designations: [],
        months: [],
        years: []
    });

    const months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
    ];

    const [yearsList, setYearsList] = useState([]);

    const [skillsOptions, setSkillsOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);
    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                setSkillsOptions(response.data)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                setDesignationOptions(response.data);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
          const isSelected = prevSelectedFields[fieldType].includes(value);
          let updatedFields = {
            ...prevSelectedFields,
            [fieldType]: isSelected
              ? prevSelectedFields[fieldType].filter((item) => item !== value)
              : [...prevSelectedFields[fieldType], value],
          };
          return updatedFields;
        });
    };

    const updateInterviewPagination = (pageNumber, pageSize) => {
        setInterviewPageNumber(pageNumber)
        seInterviewPageSize(pageSize);
    }

    const updateCompanyPagination = (pageNumber, pageSize) => {
        setCompanyPageNumber(pageNumber)
        setCompanyPageSize(pageSize);
    }

    const updateRalaAdminPagination = (pageNumber, pageSize) => {
        setRalaAdminPageNumber(pageNumber)
        setRalaAdminPageSize(pageSize);
    }

    const toast = useRef(null);

   
    useEffect(() => {
        if (!_.isUndefined(interviewerList) && !_.isNull(interviewerList) && (interviewerList.length === 0)) {
            getInterviewerList();
        }
        // if (!_.isUndefined(companyList) && !_.isNull(companyList) && (companyList.length === 0)) {
        //     getCompanyList();
        // }
        // if (!_.isUndefined(ralaAdminList) && !_.isNull(ralaAdminList) && (ralaAdminList.length === 0)) {
        //     getRalaAdminList();
        // }
        if (!_.isUndefined(skillsOptions) && !_.isNull(skillsOptions) && (skillsOptions.length == 0)) {
            getSkills()
        }
        if (!_.isUndefined(designationOptions) && !_.isNull(designationOptions) && (designationOptions.length == 0)) {
            getPositions()
        }

        // Get the current year
        const currentYear = new Date().getFullYear();
        const startYear = 2024;
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);
        setYearsList(years);
    }, []);

    useEffect(() => {
        getInterviewerList()
    }, [interviewPageNumber, interviewPageSize, searchText, selectedFields, interviewerSortConfig])

    useEffect(() => {
        getCompanyList()
    }, [companyPageNumber, companyPageSize, searchText, companySortConfig])

    useEffect(() => {
        getRalaAdminList()
    }, [ralaAdminPageNumber, ralaAdminPageSize, searchText, ralaSubAdminSortConfig])

    const getInterviewerList = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/getUsers?page=${interviewPageNumber}&limit=${interviewPageSize}&sortBy=${interviewerSortConfig.key}&sortOrder=${interviewerSortConfig.direction}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                search: searchText,
                account_type: INTERVIEWER,
                company_id: "",
                ...selectedFields
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setInterviewerList(response.data);
                    setTotalInterviewerCount(response.totalCount)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setInterviewerList([]);
                setTotalInterviewerCount(0)
            });
    }

    const getCompanyList = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/getUsers?page=${companyPageNumber}&limit=${companyPageSize}&sortBy=${companySortConfig.key}&sortOrder=${companySortConfig.direction}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                search: searchText,
                account_type: EMPLOYER_SUPER_ADMIN,
                company_id: "",
                ...selectedFields
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setCompanyList(response.data);
                    setTotalCompanyCount(response.totalCount)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setCompanyList([]);
                setTotalCompanyCount(0)
            });
    }
    
    const getRalaAdminList = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/getUsers?page=${ralaAdminPageNumber}&limit=${ralaAdminPageSize}&sortBy=${ralaSubAdminSortConfig.key}&sortOrder=${ralaSubAdminSortConfig.direction}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                search: searchText,
                account_type: SUB_ADMIN,
                company_id: "",
                ...selectedFields
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setRalaAdminList(response.data);
                    setTotalRalaAdminCount(response.totalCount)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setRalaAdminList([]);
                setTotalRalaAdminCount(0)
            });
    }

    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
        setFormErrors("");
    };

    const handleCloseFormSuccess = () => {
        setShowFormSuccess(false);
        setFormSuccess("");
    };
    const redirectToInterviewDetails = (id) => {
        navigate("/interviewer-profile-page/" + id, { replace: true });
    }

    const deleteUser = (id) => {
        fetch(`${API_URL}api/deleteUser/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.message, life: 3000 });
                getRalaAdminList();
                getInterviewerList();
                getCompanyList();
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Something went wrong", life: 3000 });
            });
    }

    const updateUserStatus = (id, status, userType = "Interviewer") => {
        setIsLoading(true)
        const params = {
            id: id,
            status: status
        };
        fetch(`${API_URL}api/updateStatus`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: `${userType} ${updateStatusLabel(status)} Successfully`, life: 3000 });
                getInterviewerList();
                getCompanyList();
                getRalaAdminList();
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Something went wrong", life: 3000 });
            });
    }

    const interviewerOnSort = (key) => {
        let direction = 'asc';
        if (interviewerSortConfig.key === key && interviewerSortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setInterviewerSortConfig({ key, direction });
    };

    const getInterviewerSortIcon = (key) => {
        if (interviewerSortConfig.key !== key) {
            return <FaSort className="cursor-pointer"/>;
        }
        if (interviewerSortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    const companyOnSort = (key) => {
        let direction = 'asc';
        if (companySortConfig.key === key && companySortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setCompanySortConfig({ key, direction });
    };
    
    const getCompanySortIcon = (key) => {
        if (companySortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (companySortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    const ralaSubAdminOnSort = (key) => {
        let direction = 'asc';
        if (ralaSubAdminSortConfig.key === key && ralaSubAdminSortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setRalaSubAdminSortConfig({ key, direction });
    };

    const getRalaSubAdminSortIcon = (key) => {
        if (ralaSubAdminSortConfig.key !== key) {
            return <FaSort className="cursor-pointer"/>;
        }
        if (ralaSubAdminSortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    const clearFilter = () => {
        setSelectedFields({
            primarySkills: [],
            secondarySkills: [],
            designations: [],
            months: [],
            years: []
        })
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding settings_mob">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="row mb-3 mb-md-0 justify-content-md-between align-items-center pageTitle_fixed">
                            <div className="col-12 col-md-6 col-lg-3">
                                <p className="employer-dashboard-jobs-posted mb-0 p-0" style={{display: 'flex',justifyContent: 'space-between'}}>
                                    <span className='inner_title d-flex align-items-center gap-2'><FiSettings size={18} />SETTINGS</span>
                                       
                                </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3'>
                            <input type="text" className='form-control' value={searchText} placeholder="Search" style={{paddingLeft: '12px'}} onChange={(e) => {
                                        setSearchText(e.target.value.trim())
                                    }} />
                            </div>
                        </div>
                        <Tab.Container defaultActiveKey="interviewer">
                            <div className="row mb-2">
                                <div className="col-12">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="interviewer" onClick={() =>  setSearchText('')}>Interviewer</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company" onClick={() => setSearchText('')}>Company</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ralaAdmins" onClick={() => setSearchText('')}>Rala admins</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="interviewer">
                                            <Card>
                                                <Card.Body>
                                                    <div className="row">
                                                        <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                        <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>Filters</Tooltip>}
                                                            >
                                                                <DropdownButton id="report-filter" title={<CiFilter style={{width: '25px', height: '25px'}} />} style={{ backgroundImage: 'none' }} >

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Primary Skills" + (selectedFields.primarySkills.length > 0 ? ` (${selectedFields.primarySkills.length})` : "")
                                                                    }>
                                                                        {skillsOptions &&
                                                                            skillsOptions.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item._id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"primary_skill_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('primarySkills', item.name)}
                                                                                    checked={selectedFields.primarySkills.includes(item.name)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Secondary Skills" + (selectedFields.secondarySkills.length > 0 ? ` (${selectedFields.secondarySkills.length})` : "")
                                                                    }>
                                                                        {skillsOptions &&
                                                                            skillsOptions.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item._id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"secondary_skill_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('secondarySkills', item.name)}
                                                                                    checked={selectedFields.secondarySkills.includes(item.name)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Designation" + (selectedFields.designations.length > 0 ? ` (${selectedFields.designations.length})` : "")
                                                                    }>
                                                                        {designationOptions &&
                                                                            designationOptions.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item._id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"designation_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('designations', item.name)}
                                                                                    checked={selectedFields.designations.includes(item.name)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Years" + (selectedFields.years.length > 0 ? ` (${selectedFields.years.length})` : "")
                                                                    }>
                                                                        {yearsList && yearsList.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item}>
                                                                                <span title={item} className="filter-item">{item}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"year_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('years', item)}
                                                                                    checked={selectedFields.years.includes(item)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Months" + (selectedFields.months.length > 0 ? ` (${selectedFields.months.length})` : "")
                                                                    }>
                                                                        {months && months.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item.id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"month_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('months', item.id)}
                                                                                    checked={selectedFields.months.includes(item.id)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <div className="my-2" style={{textAlign: 'center'}}>
                                                                        <Button onClick={clearFilter} style={{
                                                                            marginTop: '0px',
                                                                            background: '#FFFBFE',
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }} className="employer-dashboard-interview-join">
                                                                            Reset
                                                                        </Button>
                                                                    </div>
                                                                </DropdownButton>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="col-11" style={{ flex: 1 }}>
                                                            <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                                <thead className='sticky-top'>
                                                                    <tr>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('id')}>Interviewer ID {getInterviewerSortIcon('id')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('name')}>Interviewer Name {getInterviewerSortIcon('name')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('resume')}>Resume {getInterviewerSortIcon('resume')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('email')}>Email ID {getInterviewerSortIcon('email')}</th>
                                                                        <th onClick={() => interviewerOnSort('mobile_number')}>Mobile Number {getInterviewerSortIcon('mobile_number')}</th>
                                                                        <th className='text-start' style={{minWidth: '450px'}} onClick={() => interviewerOnSort('primary_skill')}>Primary skills {getInterviewerSortIcon('primary_skill')}</th>
                                                                        <th className='text-start' style={{minWidth: '450px'}} onClick={() => interviewerOnSort('secondary_skill')}>Seconday skills {getInterviewerSortIcon('secondary_skill')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('designation')}>Designation {getInterviewerSortIcon('designation')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('createdAt')}>Registered Date {getInterviewerSortIcon('createdAt')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('user_status')}>Profile Status {getInterviewerSortIcon('user_status')}</th>
                                                                        <th className='text-start'>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        !_.isUndefined(interviewerList) && !_.isNull(interviewerList) &&
                                                                        _.map(interviewerList, (interviewer, index) => {
                                                                            return <tr key={"feednback-completed-" + index}>
                                                                                <td>{interviewer.id}</td>
                                                                                <td className='fw-bold'><span>{interviewer.first_name + " " + interviewer.last_name}</span></td>
                                                                                <td style={{textAlign: 'center'}}>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip>Interviewer Resume</Tooltip>}
                                                                                    >
                                                                                        <a style={{color: 'black'}} className="cursor-pointer" onClick={() => { setInterviewerDetails(interviewer); setShowOpenResume(true); }}><FaFileAlt /></a>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                                <td>{interviewer.email}</td>
                                                                                <td>{interviewer.mobile_number}</td>
                                                                                <td>{interviewer.primary_skill.join(", ")}</td>
                                                                                <td>{interviewer.secondary_skill.join(", ")}</td>
                                                                                <td>{interviewer.designation}</td>
                                                                                <td>{commonDateFormat(interviewer.createdAt)}</td>
                                                                                <td>{getUserStatus(interviewer.user_status)}</td>
                                                                                <td style={{minWidth: '250px'}}>
                                                                                    <div className="d-flex">
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip>Edit Profile</Tooltip>}
                                                                                        >
                                                                                            <Button onClick={() => redirectToInterviewDetails(interviewer.id)} variant="outline-primary">
                                                                                                <MdEdit  />
                                                                                            </Button>
                                                                                        </OverlayTrigger>
                                                                                        &nbsp;&nbsp;
                                                                                        <Form.Select style={{width: '120px'}} 
                                                                                            onChange={(e) => {
                                                                                                updateUserStatus(
                                                                                                    interviewer.id,
                                                                                                    e.target.value,
                                                                                                    "Interviewer"
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <option value="PENDING" selected={interviewer.user_status === USER_STATUS_PENDING}>Pending</option>
                                                                                            <option value="APPROVE" selected={interviewer.user_status === USER_STATUS_APPROVE}>Approve</option>
                                                                                            <option value="REJECT" selected={interviewer.user_status === USER_STATUS_REJECT}>Reject</option>
                                                                                            <option value="ON_HOLD" selected={interviewer.user_status === USER_STATUS_ON_HOLD}>On Hold</option>
                                                                                        </Form.Select>
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                    {
                                                        !_.isUndefined(interviewerList) && !_.isNull(interviewerList) && interviewerList.length === 0 && (
                                                            <NoRecordFound></NoRecordFound>    
                                                        )
                                                    }
                                                    {
                                                        interviewerList && interviewerList.length > 0 && (
                                                            <CustomPaginationComponent total={totalInterviewerCount} updatePagination={updateInterviewPagination}></CustomPaginationComponent>
                                                        )
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="company">
                                        <Card>
                                        <Card.Body>       
                                            <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                <thead className='sticky-top'>
                                                    <tr>
                                                        <th className='text-start' onClick={() => companyOnSort('id')}>Registration ID {getCompanySortIcon('id')}</th>
                                                        <th className='text-start' onClick={() => companyOnSort('company_name')}>Company Name {getCompanySortIcon('company_name')}</th>
                                                        <th className='text-start' onClick={() => companyOnSort('email')}>Email ID {getCompanySortIcon('email')}</th>
                                                        <th className='text-start' onClick={() => companyOnSort('mobile_number')}>Mobile Number {getCompanySortIcon('mobile_number')}</th>
                                                        <th className='text-start' onClick={() => companyOnSort('createdAt')}>Registered Date {getCompanySortIcon('createdAt')}</th>
                                                        <th className='text-start' onClick={() => companyOnSort('user_status')}>Profile Status {getCompanySortIcon('user_status')}</th>
                                                        <th className='text-start'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !_.isUndefined(companyList) && !_.isNull(companyList) &&
                                                        _.map(companyList, (company, index) => {
                                                            return <tr key={"feednback-completed-company-" + index}>
                                                                <td>{company.id}</td>
                                                                <td className='fw-bold'>{company.company_name}</td>
                                                                <td>{company.email}</td>
                                                                <td>{company.mobile_number}</td>
                                                                <td>{commonDateFormat(company.createdAt)}</td>
                                                                <td>{getUserStatus(company.user_status)}</td>
                                                                <td style={{minWidth: '250px'}}>
                                                                    <div className="d-flex">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Edit Profile</Tooltip>}
                                                                        >
                                                                            <Button onClick={() => navigate("/company-profile-page/" + company.id, { replace: true })} variant="outline-primary">
                                                                                <MdEdit />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        &nbsp;&nbsp;
                                                                        <Form.Select style={{width: '120px'}} 
                                                                            onChange={(e) => {
                                                                                updateUserStatus(
                                                                                    company.id,
                                                                                    e.target.value,
                                                                                    "Company"
                                                                                )
                                                                            }}
                                                                        >
                                                                            <option value="PENDING" selected={company.user_status === USER_STATUS_PENDING}>Pending</option>
                                                                            <option value="APPROVE" selected={company.user_status === USER_STATUS_APPROVE}>Approve</option>
                                                                            <option value="REJECT" selected={company.user_status === USER_STATUS_REJECT}>Reject</option>
                                                                            <option value="ON_HOLD" selected={company.user_status === USER_STATUS_ON_HOLD}>On Hold</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            {
                                                companyList && companyList.length > 0 && (
                                                    <CustomPaginationComponent total={totalCompanyCount} updatePagination={updateCompanyPagination}></CustomPaginationComponent>
                                                )
                                            }
                                            {
                                                !_.isUndefined(companyList) && !_.isNull(companyList) && companyList.length === 0 && (
                                                    <NoRecordFound></NoRecordFound>    
                                                )
                                            }
                                            </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ralaAdmins">
                                        <Card>
                                        <Card.Body>
                                            <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                <thead className='sticky-top'>
                                                    <tr>
                                                        <th className='text-start' onClick={() => ralaSubAdminOnSort('id')}>Registration ID {getRalaSubAdminSortIcon('id')}</th>
                                                        <th className='text-start' onClick={() => ralaSubAdminOnSort('name')}>Name {getRalaSubAdminSortIcon('name')}</th>
                                                        <th className='text-start' onClick={() => ralaSubAdminOnSort('email')}>Email ID {getRalaSubAdminSortIcon('email')}</th>
                                                        <th className='text-start' onClick={() => ralaSubAdminOnSort('mobile_number')}>Mobile Number {getRalaSubAdminSortIcon('mobile_number')}</th>
                                                        <th className='text-start' onClick={() => ralaSubAdminOnSort('createdAt')}>Registered Date {getRalaSubAdminSortIcon('createdAt')}</th>
                                                        <th className='text-start' onClick={() => ralaSubAdminOnSort('user_status')}>Profile Status {getRalaSubAdminSortIcon('user_status')}</th>
                                                        <th className='text-start'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !_.isUndefined(ralaAdminList) && !_.isNull(ralaAdminList) &&
                                                        _.map(ralaAdminList, (ralaAdmin, index) => {
                                                            return <tr key={"feednback-completed-rala-admin-" + index}>
                                                                <td>{ralaAdmin.id}</td>
                                                                <td>{ralaAdmin.first_name + " " + ralaAdmin.last_name}</td>
                                                                <td>{ralaAdmin.email}</td>
                                                                <td>{ralaAdmin.mobile_number}</td>
                                                                <td>{commonDateFormat(ralaAdmin.createdAt)}</td>
                                                                <td>{getUserStatus(ralaAdmin.user_status)}</td>
                                                                <td style={{minWidth: '250px'}}>
                                                                    <div className="d-flex">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Edit Profile</Tooltip>}
                                                                        >
                                                                            <Button onClick={() => navigate("/admin-profile-page/" + ralaAdmin.id, { replace: true })} variant="outline-primary">
                                                                                <MdEdit/>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        &nbsp;&nbsp;
                                                                        <Form.Select style={{width: '120px'}} 
                                                                            onChange={(e) => {
                                                                                updateUserStatus(
                                                                                    ralaAdmin.id,
                                                                                    e.target.value,
                                                                                    "Rala Admin"
                                                                                )
                                                                            }}
                                                                        >
                                                                            <option value="PENDING" selected={ralaAdmin.user_status === USER_STATUS_PENDING}>Pending</option>
                                                                            <option value="APPROVE" selected={ralaAdmin.user_status === USER_STATUS_APPROVE}>Approve</option>
                                                                            <option value="REJECT" selected={ralaAdmin.user_status === USER_STATUS_REJECT}>Reject</option>
                                                                            <option value="ON_HOLD" selected={ralaAdmin.user_status === USER_STATUS_ON_HOLD}>On Hold</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            {
                                                ralaAdminList && ralaAdminList.length > 0 && (
                                                    <CustomPaginationComponent total={totalRalaAdminCount} updatePagination={updateRalaAdminPagination}></CustomPaginationComponent>
                                                )
                                            }
                                            {
                                                !_.isUndefined(ralaAdminList) && !_.isNull(ralaAdminList) && ralaAdminList.length === 0 && (
                                                    <NoRecordFound></NoRecordFound>    
                                                )
                                            }
                                            </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            {
                formErrors && showFormErrors &&
                <Modal show={showFormErrors} onHide={handleCloseFormErrors} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formErrors}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormErrors}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                formSuccess && showFormSuccess &&
                <Modal show={showFormSuccess} onHide={handleCloseFormSuccess} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formSuccess}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormSuccess}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showOpenResume && !_.isUndefined(interviewerDetails) && !_.isNull(interviewerDetails) && !_.isUndefined(interviewerDetails.id) && !_.isNull(interviewerDetails.id) && (interviewerDetails.id !== '') &&
                <Modal centered dialogClassName="modal-90w" show={showOpenResume} onHide={() => { setShowOpenResume(false) }} animation={false}>
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body>
                        {
                            interviewerDetails.resume && interviewerDetails.resume.includes('.doc') ? 
                            <iframe key="iframe-doc" style={{
                                width: '100%',
                                height: '80vh'
                            }} src={`https://docs.google.com/gview?url=${interviewerDetails.resume}&embedded=true`}></iframe> : 
                            <object key="object-other" data={interviewerDetails.resume} style={{
                                width: '100%',
                                height: '80vh'
                            }}></object>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowOpenResume(false) }}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    )
}
export default SuperAdminSettingScreen;
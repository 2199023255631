import _ from 'lodash';
import moment from 'moment';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, DropdownButton, Form, InputGroup, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import { BsFillSendFill } from "react-icons/bs";
import { CiFilter } from "react-icons/ci";
import { BsCash } from "react-icons/bs";
import { FaDownload, FaEye, FaFileAlt, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API_URL, ITEMS_PER_PAGE, QC_STATUS_FAIL, QC_STATUS_PASS, USER_STATUS_APPROVE, commonDateFormat, fileName, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel } from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./PaymentScreen.css";
import { EMPLOYER_SUPER_ADMIN, INTERVIEWER } from '../../common/roles';
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';
import NoRecordFound from '../../common/NoRecordFound/NoRecordFound';
const PaymentScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [interviewerList, setInterviewerList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    const [bulkMonth, setBulkMonth] = useState(new Date().getMonth() + 1);
    const [bulkYear, setBulkYear] = useState(new Date().getFullYear());

    const [totalInterviewerCount, setTotalInterviewerCount] = useState(0);
    const [totalCompanyCount, setTotalCompanyCount] = useState(0);

    const [interviewerSortConfig, setInterviewerSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const [companySortConfig, setCompanySortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const [showOpenResume, setShowOpenResume] = useState(false);
    const [interviewerDetails, setInterviewerDetails] = useState({});
    const [numPages, setNumPages] = useState(0);

    const [interviewPageNumber, setInterviewPageNumber] = useState(1);
    const [interviewPageSize, seInterviewPageSize] = useState(ITEMS_PER_PAGE);
    const [companyPageNumber, setCompanyPageNumber] = useState(1);
    const [companyPageSize, setCompanyPageSize] = useState(ITEMS_PER_PAGE);

    // Assume you have a state variable to manage the modal's visibility
    const [showInterviewReportModal, setShowInterviewReportModal] = useState(false);
    const [showCompanyReportModal, setShowCompanyReportModal] = useState(false);
    const [csvContent, setCsvContent] = useState('');

    const [searchText, setSearchText] = useState('');

    const [activeTab, setActiveTab] = useState(0);

    const toast = useRef(null);

    const [selectedFields, setSelectedFields] = useState({
        primarySkills: [],
        secondarySkills: [],
        designations: [],
        months: [],
        years: []
    });

    const months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
    ];

    const [yearsList, setYearsList] = useState([]);

    const [skillsOptions, setSkillsOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [isShowResumeDialog, setIsShowResumeDialog] = useState(false)
    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                setSkillsOptions(response.data)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                setDesignationOptions(response.data);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
          const isSelected = prevSelectedFields[fieldType].includes(value);
          let updatedFields = {
            ...prevSelectedFields,
            [fieldType]: isSelected
              ? prevSelectedFields[fieldType].filter((item) => item !== value)
              : [...prevSelectedFields[fieldType], value],
          };
          return updatedFields;
        });
    };

    useEffect(() => {
        if (!_.isUndefined(interviewerList) && !_.isNull(interviewerList) && (interviewerList.length === 0)) {
            getInterviewerList();
        }
        if (!_.isUndefined(skillsOptions) && !_.isNull(skillsOptions) && (skillsOptions.length == 0)) {
            getSkills()
        }
        if (!_.isUndefined(designationOptions) && !_.isNull(designationOptions) && (designationOptions.length == 0)) {
            getPositions()
        }

        // Get the current year
        const currentYear = new Date().getFullYear();
        const startYear = 2024;
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);
        setYearsList(years);
    }, []);

    const getInterviewerList = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/getUsers?page=${interviewPageNumber}&limit=${interviewPageSize}&sortBy=${interviewerSortConfig.key}&sortOrder=${interviewerSortConfig.direction}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                search: searchText,
                account_type: INTERVIEWER,
                company_id: "",
                ...selectedFields
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    const interviewersData = response.data.map((item) => {
                        return {...item, month: bulkMonth, year: bulkYear}
                    })
                    setInterviewerList(interviewersData);
                    setTotalInterviewerCount(response.totalCount)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setInterviewerList([]);
                setTotalInterviewerCount(0)
            });
    }

    const getCompanyList = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/getUsers?page=${companyPageNumber}&limit=${companyPageSize}&sortBy=${companySortConfig.key}&sortOrder=${companySortConfig.direction}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify({
                search: searchText,
                account_type: EMPLOYER_SUPER_ADMIN,
                company_id: "",
                ...selectedFields
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    const companiesData = response.data.map((item) => {
                        return {...item, month: (new Date().getMonth() + 1), year: new Date().getFullYear()}
                    })
                    setCompanyList(companiesData);
                    setTotalCompanyCount(response.totalCount)
                }
            })
            .catch((err) => {
                setCompanyList([]);
                setTotalCompanyCount(0)
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        getInterviewerList()
    }, [interviewPageNumber, interviewPageSize, searchText, selectedFields, interviewerSortConfig])

    useEffect(() => {
        getCompanyList()
    }, [companyPageNumber, companyPageSize, searchText, companySortConfig])
   
    const redirectToInterviewDetails = (id) => {
        navigate("/interviewer-profile-page/" + id, { replace: true });
    }

    const updateInterviewPagination = (pageNumber, pageSize) => {
        setInterviewPageNumber(pageNumber)
        seInterviewPageSize(pageSize)
    }

    const updateCompanyPagination = (pageNumber, pageSize) => {
        setCompanyPageNumber(pageNumber)
        setCompanyPageSize(pageSize)
    }

    const convertToCsv = (data) => {
        const header = "Candidate name, Candidate E-mail, Title, Company name, Client Coordinator, Schedule date, Scheduled By, Rating, Verdit, Duration, QC status, QC Feedback, Location, Amount, QC deduction, Final Amount";
        const rowData = [];
        data.map((item)  => {
            const datum = {
                "candidate_name": item.candidate_name,
                "candidate_email": item.candidate_email,
                "title": item.job_title,
                "company_name": item.company_name,
                "client_coordinator": item.coordinator ? `${item.coordinator.first_name} ${item.coordinator.last_name}` : '',
                "schedule_date": commonDateFormat(item.scheduled_on),
                "schedule_by": item.coordinator ? item.coordinator.first_name : "" ,
                "rating": item.overall_rating,
                "verdit": getInterviewFinalStatus(item.final_status),
                "duration": item.interview_duration,
                "qc_status": getQCStatuslabel(item.qc_status),
                "qc_feedback": item.qc_remark,
                "location": item.job_location,
                "amount": item.interview_charge,
                "qc_deduction": item.qc_status === QC_STATUS_FAIL ? item.interview_charge : 0,
                "final_amount": item.qc_status === QC_STATUS_FAIL ? 0 : item.interview_charge
            };
            rowData.push(datum);
        });
        const rows = rowData.map((row) => Object.values(row).join(','));
        return `${header}\n${rows.join('\n')}`;
    };

    const companyConvertToCsv = (data) => {
        const header = "Candidate Name, Candidate Number, Candidate E-mail, Title, Company Name, Client Coordinator, Scheduled Date, Schedule By, Rating, Verdict, Location, Amount, Final amount, Interviewer Source";
        const rowData = [];
        data.map((item)  => {
            const datum = {
                "candidate_name": item.candidate_name,
                "candidate_mobile_number": item.candidate_mobile_number,
                "interview_email": item.interview_email,
                "title": item.job_title,
                "company_name": item.company_name,
                "client_coordinator": item.coordinator ? `${item.coordinator.first_name} ${item.coordinator.last_name}` : '',
                "schedule_date": commonDateFormat(item.scheduled_on),
                "schedule_by": item.coordinator ? item.coordinator.first_name : "" ,
                "rating": item.overall_rating,
                "verdit": getInterviewFinalStatus(item.final_status),
                "location": item.job_location,
                "amount": item.companyInfo.charge,
                "final_amount": item.qc_status === QC_STATUS_FAIL ? 0 : item.companyInfo.charge,
                "interviewer_source": "Rala Infotech"
            };
            rowData.push(datum);
        });
        const rows = rowData.map((row) => Object.values(row).join(','));
        return `${header}\n${rows.join('\n')}`;
    };

    const downloadInterviewReport = (interviewId, type = 'download') => {
        var currentInterviewyear = "";
        var currentInterviewMonth = "";
        interviewerList.map((item) => {
            if (item.id === interviewId) {
                currentInterviewyear = item.year;
                currentInterviewMonth = item.month;
            }
        });
        setIsLoading(true)
        fetch( `${API_URL}api/interviews/${interviewId}?currentMonth=${currentInterviewMonth}&currentYear=${currentInterviewyear}&qcStatus=${QC_STATUS_PASS}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
        .then((response) => response.json())
        .then((response) => {
            if (type === 'download') {
                const interviewReportData = response.data;
                const csvContent = convertToCsv(interviewReportData);
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${fileName()}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report downloaded successfully", life: 3000 });
            } else if(type === 'view') {
                const interviewReportData = response.data;
                setCsvContent(interviewReportData);
                setShowInterviewReportModal(true);
            }
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
        });
    }

    const downloadCompanyReport = (company, type = 'download') => {
        const companyId = company.id;
        const companyName = company.company_name;
        var currentInterviewyear = "";
        var currentInterviewMonth = "";
        companyList.map((item) => {
            if (item.id === companyId) {
                currentInterviewyear = item.year;
                currentInterviewMonth = item.month;
            }
        });
        setIsLoading(true)
        fetch( `${API_URL}api/getCompanyReport/${companyId}?currentMonth=${currentInterviewMonth}&currentYear=${currentInterviewyear}&qcStatus=${QC_STATUS_PASS}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
        .then((response) => response.json())
        .then((response) => {
            if (type === 'download') {
                const interviewReportData = response.data;
                const csvContent = companyConvertToCsv(interviewReportData);
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${companyName}_${fileName()}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report downloaded successfully", life: 3000 });
            } else if(type === 'view') {
                const interviewReportData = response.data;
                setCsvContent(interviewReportData);
                setShowCompanyReportModal(true);
            }
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
        });
    }

    const sendCompanyReport = (companyId) => {
        setIsLoading(true)
        fetch( `${API_URL}api/sendCompanyReport/${companyId}&qcStatus=${QC_STATUS_PASS}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setIsLoading(false)
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
        })
        .catch((err) => {
            setIsLoading(false)
        });
    }

    const sendInterviewReport = (interviewId) => {
        var currentInterviewyear = "";
        var currentInterviewMonth = "";
        interviewerList.map((item) => {
            if (item.id === interviewId) {
                currentInterviewyear = item.year;
                currentInterviewMonth = item.month;
            }
        });
        setIsLoading(true)
        fetch( `${API_URL}api/sendInterviewReport/${interviewId}?currentMonth=${currentInterviewMonth}&currentYear=${currentInterviewyear}&qcStatus=${QC_STATUS_PASS}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setIsLoading(false)
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
        })
        .catch((err) => {
            setIsLoading(false)
        });
    }

    const sendBuikInterviewReport = async (interviewId) => {
        try {
            const response = await fetch(`${API_URL}api/sendInterviewReport/${interviewId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            });
        } catch (error) {
        }
    };

    const sendBulkCompanyReport = async (companyId) => {
        try {
            const response = await fetch(`${API_URL}api/sendCompanyReport/${companyId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userDetails.token}`,
                }
            });
        } catch (error) {
        }
    }

    const sendAllReport = async () => {
        setIsLoading(true);
        const promises = interviewerList.map(async (item) => {
            if (activeTab === 0) {
                if (item.user_status === USER_STATUS_APPROVE) {
                    await sendBuikInterviewReport(item.id);
                }
            } else {
                await sendBulkCompanyReport(item.id);
            }
        });
    
        await Promise.all(promises);
        setIsLoading(false);
        toast.current.show({ severity: 'success', summary: 'Success', detail: "Payment report sent successfully", life: 3000 });
    }

    const interviewerOnSort = (key) => {
        let direction = 'asc';
        if (interviewerSortConfig.key === key && interviewerSortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setInterviewerSortConfig({ key, direction });
    };

    const getInterviewerSortIcon = (key) => {
        if (interviewerSortConfig.key !== key) {
            return <FaSort className="cursor-pointer"/>;
        }
        if (interviewerSortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    const companyOnSort = (key) => {
        let direction = 'asc';
        if (companySortConfig.key === key && companySortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setCompanySortConfig({ key, direction });
    };
    
    const getCompanySortIcon = (key) => {
        if (companySortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (companySortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    const clearFilter = () => {
        setSelectedFields({
            primarySkills: [],
            secondarySkills: [],
            designations: [],
            months: [],
            years: []
        })
    }

    const setBulkInterviewReportFilter = (name, value) => {
        const a = interviewerList.map((item) => {
            return { ...item, [name]: value }
        });
        setInterviewerList(a)
        const b = companyList.map((item) => {
            return { ...item, [name]: value }
        });
        setCompanyList(b)
    }

    const clearBulkInterviewerPaymentFilter = () => {
        setBulkMonth(new Date().getMonth() + 1)
        setBulkYear(new Date().getFullYear())
        const interviewData = interviewerList.map((item) => {
            return {...item, month: (new Date().getMonth() + 1), year: new Date().getFullYear()}
        })
        setInterviewerList(interviewData);
        const companiesData = companyList.map((item) => {
            return {...item, month: (new Date().getMonth() + 1), year: new Date().getFullYear()}
        })
        setCompanyList(companiesData);
    }

    const setInterviewReportFilter = (name, value, interviewerId) => {
        const a = interviewerList.map((item) =>
            item.id === interviewerId
                ? { ...item, [name]: value }
                : item
        );
        setInterviewerList(a)
    }

    const clearInterviewerPaymentFilter = (interviewerId) => {
        const a = interviewerList.filter((item) => item.id !== interviewerId);
        setInterviewerList(a)
    }

    const setCompanyReportFilter = (name, value, companyId) => {
        const a = companyList.map((item) =>
            item.id === companyId
                ? { ...item, [name]: value }
                : item
        );
        setCompanyList(a)
    }

    const clearCompanyPaymentFilter = (companyId) => {
        const a = companyList.filter((item) => item.id !== companyId);
        setCompanyList(a)
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding payment_mob">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="row mb-3 mb-md-0 justify-content-md-between align-items-center pageTitle_fixed">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                                <p className="employer-dashboard-jobs-posted mb-0 p-0" style={{display: 'flex',justifyContent: 'space-between'}}>
                                    <span className='inner_title d-flex align-items-center gap-2'><BsCash size={18} />PAYMENT</span>   
                                </p>
                            </div>
                            <div className='col-12 col-md-12 col-lg-6 col-xl-5'>
                                <div className='row align-items-center'>
                                    <div className='col-12 col-md-6 col-lg-6 text-end mb-2 mb-md-2 mb-lg-0'>
                                        <button className='btn btn-primary w-100' onClick={() => sendAllReport()}>
                                            Send Payment Report
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Send All</Tooltip>}
                                            >                                        
                                                <BsFillSendFill className='ms-2' />
                                            </OverlayTrigger>
                                        </button>   
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-6 mb-2 mb-md-2 mb-lg-0 d-flex'>
                                        <input type="text" className='form-control' value={searchText} placeholder="Search" style={{paddingLeft: '12px'}} onChange={(e) => {
                                            setSearchText(e.target.value.trim())
                                        }} /> &nbsp;
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Common Filters</Tooltip>}
                                        >
                                            <DropdownButton id="common-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >

                                                <div className="px-2 pb-2 pt-3" style={{width: '250px'}}>
                                                    <Form.Select value={bulkYear} aria-label="Default select example" onChange={(e) => {
                                                        const value = e.target.value;
                                                        setBulkYear(value)
                                                        setBulkInterviewReportFilter("year", value)
                                                    }}>
                                                        {yearsList.map((item, index) => (
                                                            <option value={item} className="filter-item">{item}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className="px-2 pb-2 pt-3" style={{width: '250px'}}>
                                                    <Form.Select value={bulkMonth} aria-label="Default select example" onChange={(e) => {
                                                        const value = e.target.value;
                                                        setBulkMonth(value)
                                                        setBulkInterviewReportFilter("month", value)
                                                    }}>
                                                        {months.map((item, index) => (
                                                            <option value={item.id} className="filter-item">{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                                                    <Button onClick={() => clearBulkInterviewerPaymentFilter()} style={{
                                                        marginTop: '0px',
                                                        background: '#FFFBFE',
                                                        color: 'black',
                                                        border: 'none'
                                                    }} className="employer-dashboard-interview-join">
                                                        Reset
                                                    </Button>
                                                </div>
                                                
                                            </DropdownButton>
                                        </OverlayTrigger>

                                    </div>

                                    
                                </div>
                             
                            </div>
                        </div>
                        <Tab.Container defaultActiveKey="interviewer">
                            <div className="row mb-2">
                                <div className="col-12">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="interviewer" onClick={() => {
                                                setActiveTab(0); 
                                                setSearchText('');
                                            }}>Interviewer</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company" onClick={() => {
                                                setActiveTab(1); 
                                                setSearchText('');
                                            }}>Company</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="interviewer">
                                            <Card>
                                                <Card.Body>
                                                    <div className="row">
                                                        <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip>Filters</Tooltip>}
                                                            >
                                                                <DropdownButton id="report-filter" title={<CiFilter style={{width: '25px', height: '25px'}} />} style={{ backgroundImage: 'none' }} >

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Primary Skills" + (selectedFields.primarySkills.length > 0 ? ` (${selectedFields.primarySkills.length})` : "")
                                                                    }>
                                                                        {skillsOptions &&
                                                                            skillsOptions.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item._id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"primary_skill_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('primarySkills', item.name)}
                                                                                    checked={selectedFields.primarySkills.includes(item.name)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Secondary Skills" + (selectedFields.secondarySkills.length > 0 ? ` (${selectedFields.secondarySkills.length})` : "")
                                                                    }>
                                                                        {skillsOptions &&
                                                                            skillsOptions.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item._id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"secondary_skill_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('secondarySkills', item.name)}
                                                                                    checked={selectedFields.secondarySkills.includes(item.name)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Designation" + (selectedFields.designations.length > 0 ? ` (${selectedFields.designations.length})` : "")
                                                                    }>
                                                                        {designationOptions &&
                                                                            designationOptions.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item._id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"designation_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('designations', item.name)}
                                                                                    checked={selectedFields.designations.includes(item.name)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Years" + (selectedFields.years.length > 0 ? ` (${selectedFields.years.length})` : "")
                                                                    }>
                                                                        {yearsList && yearsList.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item}>
                                                                                <span title={item} className="filter-item">{item}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"year_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('years', item)}
                                                                                    checked={selectedFields.years.includes(item)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <DropdownButton className="nested-button" title={
                                                                        "Months" + (selectedFields.months.length > 0 ? ` (${selectedFields.months.length})` : "")
                                                                    }>
                                                                        {months && months.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{justifyContent: 'space-between'}} key={item.id}>
                                                                                <span title={item.name} className="filter-item">{item.name}</span>
                                                                                <InputGroup.Checkbox
                                                                                    key={"month_"+index}
                                                                                    aria-label="Checkbox for following text input"
                                                                                    onChange={() => handleCheckboxChange('months', item.id)}
                                                                                    checked={selectedFields.months.includes(item.id)}
                                                                                />
                                                                                </InputGroup>
                                                                        ))}
                                                                    </DropdownButton>

                                                                    <div className="my-2" style={{textAlign: 'center'}}>
                                                                        <Button onClick={clearFilter} style={{
                                                                            marginTop: '0px',
                                                                            background: '#FFFBFE',
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }} className="employer-dashboard-interview-join">
                                                                            Reset
                                                                        </Button>
                                                                    </div>
                                                                </DropdownButton>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="col-11 payment_rep" style={{ flex: 1 }}>
                                                            <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                                <thead className='sticky-top'>
                                                                    <tr>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('id')}>Interviewer ID {getInterviewerSortIcon('id')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('name')}>Interviewer Name {getInterviewerSortIcon('name')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('resume')}>Resume {getInterviewerSortIcon('resume')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('email')}>Email ID {getInterviewerSortIcon('email')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('mobile_number')}>Mobile Number {getInterviewerSortIcon('mobile_number')}</th>
                                                                        <th className='text-start' style={{minWidth: '450px'}} onClick={() => interviewerOnSort('primary_skill')}>Primary Skills {getInterviewerSortIcon('primary_skill')}</th>
                                                                        <th className='text-start' style={{minWidth: '450px'}} onClick={() => interviewerOnSort('secondary_skill')}>Secondary Skills {getInterviewerSortIcon('secondary_skill')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('designation')}>Designation {getInterviewerSortIcon('designation')}</th>
                                                                        <th className='text-start' onClick={() => interviewerOnSort('createdAt')}>Registered Date {getInterviewerSortIcon('createdAt')}</th>
                                                                        <th className='text-start'>Payment</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        !_.isUndefined(interviewerList) && !_.isNull(interviewerList) &&
                                                                        _.map(interviewerList, (interviewer, index) => {
                                                                            return <tr key={"feednback-completed-" + index}>
                                                                                <td>{interviewer.id}</td>
                                                                                <td className='fw-bold'><span>{interviewer.first_name + " " + interviewer.last_name}</span></td>
                                                                                <td style={{textAlign: 'center'}}>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip>Candidate Resume</Tooltip>}
                                                                                    >
                                                                                        <a style={{color: 'black'}} className="cursor-pointer" onClick={() => { setInterviewerDetails(interviewer); setShowOpenResume(true); }}><FaFileAlt /></a>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                                <td>{interviewer.email}</td>
                                                                                <td>{interviewer.mobile_number}</td>
                                                                                <td>{interviewer.primary_skill.join(", ")}</td>
                                                                                <td>{interviewer.secondary_skill.join(", ")}</td>
                                                                                <td>{interviewer.designation}</td>
                                                                                <td>{commonDateFormat(interviewer.createdAt)}</td>
                                                                                <td style={{display: 'flex'}}>
                                                                                    <div className='input_grp_buttons' style={{width:'380px'}}>
                                                                                        <Container>
                                                                                            <Row>
                                                                                                <Col className='btn btn-outline-primary' onClick={() => downloadInterviewReport(interviewer.id, 'view')}  >
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={<Tooltip>See</Tooltip>}
                                                                                                    >
                                                                                                        <div ><FaEye className="cursor-pointer" /></div>
                                                                                                    </OverlayTrigger>
                                                                                                </Col>
                                                                                                <Col className='btn btn-outline-primary' onClick={() => downloadInterviewReport(interviewer.id, 'download')}  >
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={<Tooltip>Download</Tooltip>}
                                                                                                    >
                                                                                                        <div ><FaDownload className="cursor-pointer"/></div>
                                                                                                    </OverlayTrigger>
                                                                                                </Col>
                                                                                                <Col className='btn btn-outline-primary' onClick={() => sendInterviewReport(interviewer.id)}  >
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={<Tooltip>Send</Tooltip>}
                                                                                                    >
                                                                                                        <div ><BsFillSendFill className="cursor-pointer"/></div>
                                                                                                    </OverlayTrigger>
                                                                                                </Col>
                                                                                                <Col className='btn btn-outline-primary' >
                                                                                                    <div style={{textWrap:'nowrap'}} >Payment Report</div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Container>
                                                                                    </div>
                                                                                    &nbsp;&nbsp;
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip>Filters</Tooltip>}
                                                                                    >
                                                                                        <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >

                                                                                            <div className="px-2 pb-2 pt-3" style={{width: '250px'}}>
                                                                                                <Form.Select value={interviewer.year} aria-label="Default select example" onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    setInterviewReportFilter("year", value, interviewer.id)
                                                                                                }}>
                                                                                                    {yearsList.map((item, index) => (
                                                                                                        <option value={item} className="filter-item">{item}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </div>

                                                                                            <div className="px-2 pb-2 pt-3" style={{width: '250px'}}>
                                                                                                <Form.Select value={interviewer.month} aria-label="Default select example" onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    setInterviewReportFilter("month", value, interviewer.id)
                                                                                                }}>
                                                                                                    {months.map((item, index) => (
                                                                                                        <option value={item.id} className="filter-item">{item.name}</option>
                                                                                                    ))}
                                                                                                </Form.Select>
                                                                                            </div>

                                                                                            <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                                                                                                <Button onClick={() => clearInterviewerPaymentFilter(interviewer.id)} style={{
                                                                                                    marginTop: '0px',
                                                                                                    background: '#FFFBFE',
                                                                                                    color: 'black',
                                                                                                    border: 'none'
                                                                                                }} className="employer-dashboard-interview-join">
                                                                                                    Reset
                                                                                                </Button>
                                                                                            </div>
                                                                                            
                                                                                        </DropdownButton>
                                                                                    </OverlayTrigger>

                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                    {
                                                        !_.isUndefined(interviewerList) && !_.isNull(interviewerList) && interviewerList.length === 0 && (
                                                            <NoRecordFound></NoRecordFound>    
                                                        )
                                                    }
                                                    {
                                                        interviewerList && interviewerList.length > 0 && (
                                                            <CustomPaginationComponent total={totalInterviewerCount} updatePagination={updateInterviewPagination} />
                                                        )
                                                    }
                                                </Card.Body>
                                            </Card>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="company">
                                            <div className='card'>
                                                <div className='card-body payment_rep'>
                                                <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                    <thead className='sticky-top'>
                                                        <tr>
                                                            <th className='text-start' onClick={() => companyOnSort('id')}>Registration ID {getCompanySortIcon('id')}</th>
                                                            <th className='text-start' onClick={() => companyOnSort('company_name')}>Company Name {getCompanySortIcon('company_name')}</th>
                                                            <th className='text-start' onClick={() => companyOnSort('email')}>Email ID {getCompanySortIcon('email')}</th>
                                                            <th className='text-start' onClick={() => companyOnSort('mobile_number')}>Mobile Number {getCompanySortIcon('mobile_number')}</th>
                                                            <th className='text-start' onClick={() => companyOnSort('createdAt')}>Registered Date {getCompanySortIcon('createdAt')}</th>
                                                            <th className='text-start'>Payment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !_.isUndefined(companyList) && !_.isNull(companyList) &&
                                                            _.map(companyList, (company, index) => {
                                                                return <tr key={"feednback-completed-company-" + index}>
                                                                    <td>{company.id}</td>
                                                                    <td className='fw-bold'>{company.company_name}</td>
                                                                    <td>{company.email}</td>
                                                                    <td>{company.mobile_number}</td>
                                                                    <td>{commonDateFormat(company.createdAt)}</td>
                                                                    <td style={{display: 'flex'}}>
                                                                        <div className='input_grp_buttons' style={{  width: '380px', }}>
                                                                            <Container>
                                                                                <Row>
                                                                                    <Col className=' btn btn-outline-primary' onClick={() => downloadCompanyReport(company, 'view')}  >
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip>See</Tooltip>}
                                                                                        >
                                                                                            <div ><FaEye className="cursor-pointer"/></div>
                                                                                        </OverlayTrigger>
                                                                                    </Col>
                                                                                    <Col className=' btn btn-outline-primary' onClick={() => downloadCompanyReport(company, 'download')}  >
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip>Download</Tooltip>}
                                                                                        >
                                                                                            <div ><FaDownload className="cursor-pointer"/></div>
                                                                                        </OverlayTrigger>
                                                                                    </Col>
                                                                                    <Col className=' btn btn-outline-primary' onClick={() => sendCompanyReport(company.id)}  >
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip>Send</Tooltip>}
                                                                                        >
                                                                                            <div ><BsFillSendFill className="cursor-pointer"/></div>
                                                                                        </OverlayTrigger>
                                                                                    </Col>
                                                                                    <Col className=' btn btn-outline-primary' >
                                                                                        <div style={{textWrap:'nowrap'}}>Payment Report</div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Container>
                                                                        </div>
                                                                        &nbsp;&nbsp;
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Filters</Tooltip>}
                                                                        >
                                                                            <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >
                                                                                <div className="px-2 pb-2 pt-3" style={{width: '250px'}}>
                                                                                    <Form.Select value={company.year} aria-label="Default select example" onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        setCompanyReportFilter("year", value, company.id)
                                                                                    }}>
                                                                                        {yearsList.map((item, index) => (
                                                                                            <option value={item} className="filter-item">{item}</option>
                                                                                        ))}
                                                                                    </Form.Select>
                                                                                </div>
                                                                                <div className="px-2 pb-2 pt-3" style={{width: '250px'}}>
                                                                                    <Form.Select value={company.month} aria-label="Default select example" onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        setCompanyReportFilter("month", value, company.id)
                                                                                    }}>
                                                                                        {months.map((item, index) => (
                                                                                            <option value={item.id} className="filter-item">{item.name}</option>
                                                                                        ))}
                                                                                    </Form.Select>
                                                                                </div>

                                                                                <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                                                                                    <Button onClick={() => clearCompanyPaymentFilter(company.id)} style={{
                                                                                        marginTop: '0px',
                                                                                        background: '#FFFBFE',
                                                                                        color: 'black',
                                                                                        border: 'none'
                                                                                    }} className="employer-dashboard-interview-join">
                                                                                        Reset
                                                                                    </Button>
                                                                                </div>

                                                                            </DropdownButton>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                                {
                                                    !_.isUndefined(companyList) && !_.isNull(companyList) && companyList.length === 0 && (
                                                        <NoRecordFound></NoRecordFound>    
                                                    )
                                                }
                                                {
                                                    companyList && companyList.length > 0 && (
                                                        <CustomPaginationComponent total={totalCompanyCount} updatePagination={updateCompanyPagination} />
                                                    )
                                                }
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Modal show={showInterviewReportModal} onHide={() => setShowInterviewReportModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                            <thead className='sticky-top'>
                                <tr>
                                    <th>Candidate Name</th>
                                    <th>Candidate E-mail</th>
                                    <th>Title</th>
                                    <th>Company Name</th>
                                    <th>Client Coordinator</th>
                                    <th>Schedule Date</th>
                                    <th>Scheduled By</th>
                                    <th>Rating</th>
                                    <th>Verdit</th>
                                    <th>Duration</th>
                                    <th>QC Status</th>
                                    <th>QC Feedback</th>
                                    <th>Location</th>
                                    <th>Amount</th>
                                    <th>QC Deduction</th>
                                    <th>Final Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    csvContent && csvContent.length > 0 && (
                                        csvContent.map((item) => {
                                            return (<tr>
                                                <td className='fw-bold'>{item.candidate_name}</td>
                                                <td>{item.candidate_email}</td>
                                                <td>{item.job_title}</td>
                                                <td>{item.company_name}</td>
                                                <td>{item.coordinator ? `${item.coordinator.first_name} ${item.coordinator.last_name}` : ''}</td>
                                                <td>{commonDateFormat(item.scheduled_on)}</td>
                                                <td>{item.coordinator ? item.coordinator.first_name : ""}</td>
                                                <td>{item.overall_rating}</td>
                                                <td>{getInterviewFinalStatus(item.final_status)}</td>
                                                <td>{item.interview_duration}</td>
                                                <td>{getQCStatuslabel(item.qc_status)}</td>
                                                <td>{item.qc_remark}</td>
                                                <td>{item.job_location}</td>
                                                <td>{item.interview_charge}</td>
                                                <td>{item.qc_status === QC_STATUS_FAIL ? item.interview_charge : 0}</td>
                                                <td>{item.qc_status === QC_STATUS_FAIL ? 0 : item.interview_charge}</td>
                                            </tr>);
                                        })
                                    )
                                }
                            </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowInterviewReportModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCompanyReportModal} onHide={() => setShowCompanyReportModal(false)} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered  striped  hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                            <thead className='sticky-top'>
                                <tr>
                                    <th>Candidate Name</th>
                                    <th>Candidate Number</th>
                                    <th>Candidate E-mail</th>
                                    <th>Title</th>
                                    <th>Company Name</th>
                                    <th>Client Coordinator</th>
                                    <th>Schedule Date</th>
                                    <th>Scheduled By</th>
                                    <th>Rating</th>
                                    <th>Verdit</th>
                                    <th>Location</th>
                                    <th>Amount</th>
                                    <th>Final Amount</th>
                                    <th>Interviewer Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    csvContent && csvContent.length > 0 && (
                                        csvContent.map((item) => {
                                            return (<tr>
                                                <td className='fw-bold'>{item.candidate_name}</td>
                                                <td>{item.candidate_mobile_number}</td>
                                                <td>{item.candidate_email}</td>
                                                <td>{item.job_title}</td>
                                                <td>{item.company_name}</td>
                                                <td>{item.coordinator ? `${item.coordinator.first_name} ${item.coordinator.last_name}` : ''}</td>
                                                <td>{commonDateFormat(item.scheduled_on)}</td>
                                                <td>{item.coordinator ? item.coordinator.first_name : ""}</td>
                                                <td>{item.overall_rating}</td>
                                                <td>{getInterviewFinalStatus(item.final_status)}</td>
                                                <td>{item.job_location}</td>
                                                <td>{item.companyInfo.charge}</td>
                                                <td>{item.qc_status === QC_STATUS_FAIL ? 0 : item.companyInfo.charge}</td>
                                                <td>Rala Infotech</td>
                                            </tr>);
                                        })
                                    )
                                }
                            </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCompanyReportModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Dialog header="Candidate Resume" visible={isShowResumeDialog} style={{ width: '50vw', height: '80vh' }} onHide={() => setIsShowResumeDialog(false)} dismissableMask="true">
                {
                    selectedItem && selectedItem.resume &&
                    (
                        selectedItem.resume.includes('.doc') ? 
                        <iframe key="iframe-doc" style={{
                            width: '100%',
                            height: '100%'
                        }} src={`https://docs.google.com/gview?url=${selectedItem.resume}&embedded=true`}></iframe> : 
                        <object key="object-other" data={selectedItem.resume} style={{
                            width: '100%',
                            height: '100%'
                        }}></object>
                    )
                }
            </Dialog>
        </Fragment>
    )
}
export default PaymentScreen;